import { any } from 'prop-types';
import { ReactNode } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type DropdownContentProps = {
  children: ReactNode;
  classes?: string | ((current: DropdownContentStyles) => Partial<DropdownContentStyles>);
};

export type DropdownContentStyles = {
  root: string;
};

const DropdownContent: AdjustableComponent<DropdownContentProps, DropdownContentStyles> = (
  props,
) => {
  const classes = useClasses(
    {
      root: 'Dropdown__content',
    },
    typeof props.classes === 'function' ? props.classes : undefined,
  );

  return <div className={classes.root}>{props.children}</div>;
};

DropdownContent.propTypes = {
  children: any.isRequired,
  classes: any,
};

export default DropdownContent;
