import { any, bool, string } from 'prop-types';
import { ReactNode, useContext } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import { CrossIcon } from '../Icons';
import { ModalPropsContext } from './Modal';

export type ModalHeaderProps = {
  onRequestClose?: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  closeIcon?: ReactNode;
  disableClose?: boolean;
  children?: ReactNode;
};

type ModalHeaderStyles = {
  root: string;
  closeButton: string;
  closeIcon: string;
  title: string;
  textWrapper: string;
};

const ModalHeader: AdjustableComponent<Partial<ModalHeaderProps>, ModalHeaderStyles> = ({
  disableClose = false,
  ...props
}) => {
  const { classes, title } = props;
  const context = useContext(ModalPropsContext);
  const handleClose = context?.onRequestClose || (() => {});
  const closeIcon = props.closeIcon || <CrossIcon />;

  const styles = useClasses<ModalHeaderStyles>(
    {
      root: 'Modal__header',
      closeIcon: 'Modal__close-icon',
      closeButton: 'Modal__close-button',
      title: 'Modal__title',
      textWrapper: 'Modal__text-wrapper',
    },
    classes,
  );

  return (
    <div className={styles.root} data-testid="Modal__header">
      <div className={styles.textWrapper} data-testid="Modal__text-wrapper">
        {title && (
          <div className={styles.title} data-testid="Modal__title" role="heading" aria-level={2}>
            {title}
          </div>
        )}
      </div>
      {props.children}
      {closeIcon && !disableClose && (
        <button
          data-testid="Modal__close-button"
          className={styles.closeButton}
          onClick={handleClose}
          aria-label="Close"
        >
          <div data-testid="Modal__close-icon" className={styles.closeIcon}>
            {closeIcon}
          </div>
        </button>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  children: any,
  classes: any,
  closeIcon: any,
  disableClose: bool,
  title: string,
};

export default ModalHeader;
