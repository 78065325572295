import { Stack, Table, TableRow } from '@mui/material';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import SlackButton from '../../../components/Button/SlackButton';
import TableColumn from '../../../components/Table/TableColumn';
import { availablePlayerRoles } from '../../../config/config';
import { useFetchData } from '../../common/hooks/useFetchData';
import { WordpressContent, WordpressLevel } from '../../content/types';
import { contentService } from '../../scenarios/containers/ScenarioJoinContainer';
import { Report } from '../types';

type ReportOverviewCardProps = {
  report: Report;
};

const ReportOverviewCard: FunctionComponent<ReportOverviewCardProps> = ({ report }) => {
  const { id } = useParams();
  const level = report.level.id;
  const [, levelContent] = useFetchData<WordpressContent<WordpressLevel>, Error>(
    () => contentService.getLevelById(level),
    undefined,
    [level],
    () => !level,
  );

  const getReportDate = () => report?.session_start?.toLocaleDateString() ?? '';

  const getPlayerRoleLabel = (): string =>
    availablePlayerRoles.find((r) => r.name === report?.role)?.label ?? 'n/a';

  const getDrillName = () => levelContent?.title?.rendered ?? 'Loading...';

  const handleBusinessIncident = () =>
    window.open(
      `${window.envs.REACT_APP_SLACK_URL_CHANNELS}business-comms-${id}`,
      '_blank',
      'noopener,noreferrer',
    );

  const handleClickIncident = () =>
    window.open(
      `${window.envs.REACT_APP_SLACK_URL_CHANNELS}incident-${id}`,
      '_blank',
      'noopener,noreferrer',
    );

  const summaryTableProperties = [
    {
      label: 'Role',
      value: getPlayerRoleLabel(),
    },
    {
      label: 'Date',
      value: getReportDate(),
    },
    {
      label: 'Scenario',
      value: report.scenario.displayName,
    },
    {
      label: 'Drill',
      value: getDrillName(),
    },
    {
      label: 'Slack Channels',
      value: (
        <Stack direction="row" className="ReportContainer__slack-button">
          <SlackButton
            title={'View Business Channel'}
            onClick={handleBusinessIncident}
          ></SlackButton>
          <SlackButton title={'View Incident Channel'} onClick={handleClickIncident}></SlackButton>
        </Stack>
      ),
    },
  ];

  return (
    <div className="ReportContainer__details">
      <Table>
        {summaryTableProperties.map((row) => (
          <TableRow key={row.label} data-testid="TableRow">
            <TableColumn>
              <span className="text-gray">{row.label}:</span>
            </TableColumn>
            <TableColumn>{row.value}</TableColumn>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default ReportOverviewCard;
