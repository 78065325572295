import { FC } from 'react';

export const ManageCriticalIncidents: FC = () => {
  return (
    <div className="skills-radarchart__role-card">
      <div className="role-content">
        <div className="role-title">Grade 2 - Professional</div>
        <div className="role-description">Manages critical incidents.</div>
      </div>
    </div>
  );
};
