import { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';

import Button, { ButtonStyles } from '../../../../components/Button/Button';
import { isVideoModalOpenedAtom } from '../../../../state';

const addStylesToButton = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} AccountContainer__button`,
});

const Support: FunctionComponent = () => {
  const setIsVideoModalOpened = useSetRecoilState(isVideoModalOpenedAtom);
  return (
    <>
      <p>First, we encourage to view the video about the platform</p>
      <Button
        onClick={() => setIsVideoModalOpened(true)}
        size="small"
        variant="link"
        classes={addStylesToButton}
      >
        See how it works
      </Button>
    </>
  );
};

export default Support;
