import { AxiosError } from 'axios';
import { FunctionComponent } from 'react';

import PendingContent from '../../../components/PendingContent/PendingContent';
import SpinnerLoader, { SpinnerLoaderSizes } from '../../../components/SpinnerLoader/SpinerLoader';

type ScenarioIntroductionInfraMessageProps = {
  ReadyToPlayLoading?: boolean;
  ReadyToPlay?: boolean;
  ReadyToPlayError?: AxiosError;
};

const ScenarioIntroductionInfraMessage: FunctionComponent<
  ScenarioIntroductionInfraMessageProps
> = ({ ReadyToPlayLoading, ReadyToPlay, ReadyToPlayError }) => {
  const showContinueMessage = (): boolean => {
    return !ReadyToPlay;
  };

  return (
    <div>
      {!ReadyToPlay && (
        <div className="ScenariosContainer__Loading">
          <div className="ScenariosContainer__description__center">
            <PendingContent
              data-testid="PendingContent"
              ReadyToPlay
              loading={Boolean(ReadyToPlayLoading)}
              isError={Boolean(ReadyToPlayError)}
              hideContent
            >
              <div className="ScenariosContainer__description__center__bold">
                Please wait while we get everything ready for your session.{' '}
              </div>
              <SpinnerLoader visible={true} size={SpinnerLoaderSizes.Small} />
              <div>This can take between 2-10 mins. </div>
              <div>
                While you wait please familiarise yourself with the session description above.{' '}
              </div>
            </PendingContent>
          </div>
        </div>
      )}
      {ReadyToPlay && showContinueMessage() && (
        <div className="ScenariosContainer__description__center">
          <div className="ScenariosContainer__Loading">
            <div className="ScenariosContainer__description__center">
              <div className="ScenariosContainer__description__center__bold">
                Your Session is now ready, please click on the Start drill button.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScenarioIntroductionInfraMessage;
