import { useCallback } from 'react';

import { featureFlagsConfiguration, Features } from '../../../config/features';
import { useUser } from './useUser';

export const useFeatureFlag = (feature: Features) => {
  const user = useUser();
  return useCallback(() => {
    try {
      return (
        user.isAdmin ||
        Boolean(featureFlagsConfiguration[feature].includes(user.email))
      );
    } catch (error) {
      console.error('useFeatureFlag error:', error);
      return false;
    }
  }, [user, feature]);
};
