import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

import Button from '../Button/Button';

interface CarouselButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
}

const CarouselButton: FC<CarouselButtonProps> = ({ direction, onClick }) => {
  return (
    <Button onClick={onClick} className={`carousel-button ${direction}`}>
      {direction === 'left' ? (
        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
      ) : (
        <FontAwesomeIcon icon={faArrowRight} size="2x" />
      )}
    </Button>
  );
};

export default CarouselButton;
