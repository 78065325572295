import './DemoPracticeButtons.scss';

import { useState } from 'react';

import Button from '../../../components/Button/Button';
import AllDrillsAccess from '../../../components/Drills/AllDrillsAccess';
import { AppRoutes } from '../../../config/routes';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';

const DemoPracticeButtons = () => {
  const goToRoute = useRedirectToRoute();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openDrillsPage = () => goToRoute(AppRoutes.ScenarioLevels, { id: 'online-boutique' });

  return (
    <>
      <div className="practice-buttons">
        <Button variant="link" onClick={openDrillsPage}>
          See All Drills
        </Button>
        <Button variant="primary" onClick={handleOpen}>
          Continue Practicing
        </Button>
      </div>
      <AllDrillsAccess open={open} onClose={handleClose} />
    </>
  );
};

export default DemoPracticeButtons;
