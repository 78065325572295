import './analytics.scss';

import { curveLinearClosed, lineRadial, max, scaleLinear, select } from 'd3';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import PlayerRadarChartTooltip from './components/PlayerRadarChartTooltip';
import { RadarChartData } from './types';

const PlayerRadarChart: FC<{
  radarChartData: RadarChartData[] | null;
}> = ({ radarChartData }) => {
  const chartRef = useRef<SVGSVGElement | null>(null);
  const [chartSize, setChartSize] = useState({
    width: 600,
    height: 400,
    radius: 150,
  });

  const drawRadarChart = useCallback(() => {
    const competencyDefinitions: Record<string, string> = {
      'Internal Coms': 'Skills related to understanding the incident',
      'External Coms': 'Skills related to communicating the incident',
      'Commanding the incident': 'Skills related to leading the incident',
      'Incident mechanics':
        'Skills related to understanding the technical mechanics of the incident',
      'Identify Scope': 'Skills related to identifying the scope of the incident',
    };
    const getCompetencyDefinition = (name: string) =>
      competencyDefinitions[name] || 'No definition available';

    const svg = select(chartRef.current);
    svg.selectAll('*').remove(); // Clear previous content

    const placeholderData: RadarChartData[] = [
      { name: 'Internal Coms', playerLevel: 0, licenseToOperate: 3 },
      { name: 'External Coms', playerLevel: 0, licenseToOperate: 3 },
      { name: 'Commanding the incident', playerLevel: 0, licenseToOperate: 3 },
      { name: 'Incident mechanics', playerLevel: 0, licenseToOperate: 3 },
      { name: 'Identify Scope', playerLevel: 0, licenseToOperate: 3 },
    ];

    const chartDataToDraw =
      radarChartData && radarChartData.length > 0 ? radarChartData : placeholderData;
    const isPlaceholderData = radarChartData === null || radarChartData.length === 0;

    const { width, height, radius } = chartSize;
    const centerX = width / 2;
    const centerY = height / 2;
    const levels = max(chartDataToDraw, (d) => d.licenseToOperate) ?? 3;
    const maxValue = max(chartDataToDraw, (d) => Math.max(d.playerLevel, d.licenseToOperate)) ?? 5;
    const angleSlice = (2 * Math.PI) / chartDataToDraw.length;

    // Scale for radius
    const radiusScale = scaleLinear().range([0, radius]).domain([0, maxValue]);

    // Create axis grid and pentagon shapes
    const axisGrid = svg
      .append('g')
      .attr('class', 'axisWrapper')
      .attr('transform', `translate(${centerX},${centerY})`);

    // Draw pentagon background
    for (let i = 1; i <= levels; i++) {
      const levelFactor = (radius / levels) * i;

      const points = chartDataToDraw
        .map((_, j) => {
          const x = levelFactor * Math.cos(angleSlice * j - Math.PI / 2);
          const y = levelFactor * Math.sin(angleSlice * j - Math.PI / 2);
          return [x, y];
        })
        .map((point) => point.join(','))
        .join(' ');

      axisGrid
        .append('polygon')
        .attr('points', points)
        .style('stroke', '#EFEFEF')
        .style('fill', '#fff')
        .style('stroke-width', '3px')
        .style('fill-opacity', 0.1);
    }
    // Draw level labels
    for (let i = 0; i <= levels; i++) {
      const levelFactor = (radius / levels) * i;
      axisGrid
        .append('text')
        .attr('x', 25)
        .attr('y', -levelFactor)
        .attr('dy', '1em')
        .style('font-size', '14px')
        .style('font-weight', '500')
        .style('fill', '#737373')
        .text(i.toString());
    }

    // Draw axis lines and labels
    const axis = axisGrid
      .selectAll('.axis')
      .data(chartDataToDraw)
      .enter()
      .append('g')
      .attr('class', 'axis');

    axis
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', (d, i) => radiusScale(maxValue) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y2', (d, i) => radiusScale(maxValue) * Math.sin(angleSlice * i - Math.PI / 2))
      .style('stroke', '#EFEFEF')
      .style('stroke-width', '1px');

    axis
      .append('foreignObject')
      .attr(
        'x',
        (d, i) => (radiusScale(maxValue) + 40) * Math.cos(angleSlice * i - Math.PI / 2) - 36,
      )
      .attr(
        'y',
        (d, i) => (radiusScale(maxValue) + 20) * Math.sin(angleSlice * i - Math.PI / 2) - 15,
      )
      .attr('width', 72)
      .attr('height', 60)
      .append('xhtml:div')
      .style('font-weight', 'bold')
      .style('font-size', '10px')
      .style('color', '#50525f')
      .style('text-align', 'center')
      .style('text-anchor', 'middle')
      .text((d) => d.name);

    // Radar area function for Player Level
    const radarLine = lineRadial<RadarChartData>()
      .radius((d) => radiusScale(d.playerLevel))
      .angle((_, i) => i * angleSlice)
      .curve(curveLinearClosed);

    // Draw Player Level radar area
    svg
      .append('path')
      .datum(chartDataToDraw)
      .attr('d', radarLine)
      .attr('transform', `translate(${centerX},${centerY})`)
      .style('fill', '#0073EE')
      .style('fill-opacity', 0.2)
      .style('stroke', '#0073EE')
      .style('stroke-width', 2);

    // Radar area function for License to Operate
    const radarLineLicense = lineRadial<RadarChartData>()
      .radius((d) => radiusScale(d.licenseToOperate))
      .angle((_, i) => i * angleSlice)
      .curve(curveLinearClosed);

    svg
      .append('path')
      .datum(chartDataToDraw)
      .attr('d', radarLineLicense)
      .attr('transform', `translate(${centerX},${centerY})`)
      .style('fill', `${isPlaceholderData ? '#fff' : '#26A69A'}`)
      .style('fill-opacity', 0.2)
      .style('stroke', `${isPlaceholderData ? '#fff' : '#26A69A'}`)
      .style('stroke-width', 2);

    // Round Data points for LTO
    svg
      .selectAll('.dataPointForLicenseToOperate')
      .data(chartDataToDraw)
      .enter()
      .append('circle')
      .attr('class', 'dataPointForLicenseToOperate')
      .attr('cursor', 'pointer')
      .attr('r', 5)
      .attr(
        'cx',
        (d, i) =>
          centerX + radiusScale(d.licenseToOperate) * Math.cos(angleSlice * i - Math.PI / 2),
      )
      .attr(
        'cy',
        (d, i) =>
          centerY + radiusScale(d.licenseToOperate) * Math.sin(angleSlice * i - Math.PI / 2),
      )
      .style('fill', `${isPlaceholderData ? '#EFEFEF' : '#26A69A'}`)
      .style('fill-opacity', 0.8)
      .attr('data-tooltip-id', 'radar-chart-tooltip-lto')
      .attr(
        'data-tooltip-html',
        (d) => `
          <div class="radar-chart-tooltip__header">
            <span class="radar-chart-tooltip__skill-name">${d.name}</span>
            <span class="radar-chart-tooltip__grades-value">${d.licenseToOperate}</span>
          </div>
          <div class="radar-chart-tooltip__description">
            ${getCompetencyDefinition(d.name)}
          </div>
      `,
      )
      .attr('data-for', 'radar-chart-tooltip-lto');

    // Round Data points for Player Level
    svg
      .selectAll('.dataPointForPlayerLevel')
      .data(chartDataToDraw)
      .enter()
      .append('circle')
      .attr('class', 'dataPointForPlayerLevel')
      .attr('cursor', 'pointer')
      .attr('r', 4)
      .attr(
        'cx',
        (d, i) => centerX + radiusScale(d.playerLevel) * Math.cos(angleSlice * i - Math.PI / 2),
      )
      .attr(
        'cy',
        (d, i) => centerY + radiusScale(d.playerLevel) * Math.sin(angleSlice * i - Math.PI / 2),
      )
      .style('fill', '#0073EE')
      .style('fill-opacity', 0.8)
      .attr('data-tooltip-id', 'radar-chart-tooltip-player-level')
      .attr(
        'data-tooltip-html',
        (d) => `
          <div class="radar-chart-tooltip__header">
            <span class="radar-chart-tooltip__skill-name">${d.name}</span>
          </div>
          <div class="radar-chart-tooltip__description">
            ${getCompetencyDefinition(d.name)}
          </div>
          <div class="radar-chart-tooltip__grades">
            <span class="radar-chart-tooltip__grades-label">Target Grade:</span>
            <span class="radar-chart-tooltip__grades-value">${d.licenseToOperate}</span>
          </div>
          <div class="radar-chart-tooltip__grades">
            <span class="radar-chart-tooltip__grades-label">Current Grade:</span>
            <span class="radar-chart-tooltip__grades-value ${isPlaceholderData && 'faded'}">${isPlaceholderData ? 'No data' : d.playerLevel}</span>
          </div>
      `,
      )
      .attr('data-for', 'radar-chart-tooltip-player-level');
  }, [chartSize, radarChartData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setChartSize({ width: 250, height: 150, radius: 40 });
      } else if (window.innerWidth <= 1024) {
        setChartSize({ width: 300, height: 200, radius: 120 });
      } else {
        setChartSize({ width: 450, height: 600, radius: 150 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    drawRadarChart();
  }, [drawRadarChart]);
  return (
    <div
      className="radar-chart-container"
      data-testid="radar-chart-container"
      style={{ position: 'relative' }}
    >
      <svg ref={chartRef} width={chartSize.width} height={chartSize.height}></svg>
      <PlayerRadarChartTooltip id="radar-chart-tooltip-lto" />
      <PlayerRadarChartTooltip id="radar-chart-tooltip-player-level" />
    </div>
  );
};

export default PlayerRadarChart;
