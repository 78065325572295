import { GamePlay, GameStatus } from '../../../state';
import { Scenario } from '../../scenarios/types';

export const getScenarioCompleteness = (scenario: Scenario) => {
  const totalLevels = scenario.levels.length;
  const completedLevels = scenario.levels.filter((l) => l.isCompleted !== false);
  const percentage = (completedLevels.length / totalLevels) * 100;
  return Math.round((percentage + Number.EPSILON) * 100) / 100;
};

export const getDefaultGamePlay = (): GamePlay => {
  return {
    scenario: '',
    level: '',
    gameRemainingSeconds: 0,
    status: GameStatus.Ready,
  };
};
