import { FunctionComponent } from 'react';

import { randomNaturalNumber, randomString } from '../features/common/helpers/tests.helper';
import { Fixture } from '../features/common/types';
import {
  Scenario,
  ScenarioDifficulties,
  ScenarioIntroductionStepDefinition,
  ScenarioLevel,
  ScenarioLevels,
  ScenarioStatuses,
} from '../features/scenarios/types';
import { GamePlay, GameStatus } from '../state';

export const createScenarioLevel: Fixture<ScenarioLevel> = (override) => {
  return {
    level: ScenarioLevels.Level1,
    timeInSeconds: 5 * 60,
    isCompleted: false,
    isAvailable: false,
    name: randomString(),
    description: {
      sectionTitle: randomString(),
      listedElements: [randomString(), randomString()],
    },
    ...override,
  };
};

export const createScenario: Fixture<Scenario> = (override?) => ({
  id: randomString() || 'scenario-id',
  title: randomString(),
  status: ScenarioStatuses.Unlocked,
  difficulty: ScenarioDifficulties.Low,
  media: {
    imageUrl: randomString(),
    videoUrl: randomString(),
  },
  levels: [
    createScenarioLevel(),
    createScenarioLevel({
      level: ScenarioLevels.Level2,
    }),
    createScenarioLevel({
      level: ScenarioLevels.Level3,
    }),
  ],
  ...override,
});

export const createScenarioIntroductionStepDefinition: Fixture<
  ScenarioIntroductionStepDefinition
> = (override) => {
  const Component: FunctionComponent = () => {
    return <div className="step">{randomString()}</div>;
  };

  return {
    step: override?.step ?? randomNaturalNumber(),
    component: override?.component || Component,
  };
};

export const createGamePlay: Fixture<GamePlay> = (
  override = {},
  scenario: Scenario,
  levelId?: number,
) => {
  return {
    scenario: scenario.id,
    level: levelId?.toString() ?? scenario.levels[0].level.toString(),
    gameRemainingSeconds: randomNaturalNumber(20, 200),
    status: GameStatus.Ready,
    ...override,
  };
};
