import { Fragment, ReactNode } from 'react';

export const repeatElement = (
  element: ReactNode,
  times: number,
  keyGenerator = (_: ReactNode, index: number) => index.toString(),
) =>
  [...Array(times)].map((it, index) => (
    <Fragment key={keyGenerator(it, index)}>{element}</Fragment>
  ));
