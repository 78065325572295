import { FC } from 'react';

export const CompetencyGrade: FC = () => {
  return (
    <div className="skills-radarchart__role-card">
      <div className="role-content">
        <div className="role-description">Your current competency grade.</div>
        <div className="role-description">
          <a href="/learning/473" target="_blank" rel="noopener noreferrer">
            Learn how to improve your grade
          </a>
        </div>
      </div>
    </div>
  );
};
