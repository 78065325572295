import 'react-circular-progressbar/dist/styles.css';

import { useKeycloak } from '@react-keycloak/web';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { FunctionComponent, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import Avatar from '../../../../components/Avatar/Avatar';
import { AchievementBadge } from '../../../../components/Icons';
import { reportsSelectedUserEmailSelector } from '../../../../state';
import Analytics from '../../../analytics/Analytics';
import { getDisplayNameFromToken } from '../../../common/helpers/text.helper';
import { useUser } from '../../../common/hooks/useUser';

const Profile: FunctionComponent = () => {
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const user = useUser();
  const { analyticsOn } = useFlags();
  const ldClient = useLDClient();
  const reportsSelectedUserEmail = useRecoilValue(
    reportsSelectedUserEmailSelector,
  );

  useEffect(() => {
    if (ldClient && user && displayName) {
      ldClient.identify({
        kind: user.isAdmin ? 'admin' : 'user',
        key: user.id,
        name: displayName,
        email: user.email,
      });
    }
  }, [user, displayName, ldClient, reportsSelectedUserEmail]);

  return (
    <>
      {analyticsOn ? (
        <Analytics key={reportsSelectedUserEmail} />
      ) : (
        <div className="AccountContainer__profile">
          <div className="AccountContainer__profile-sidebar">
            <div className="AccountContainer__profile-sidebar-section AccountContainer__profile-sidebar-section--avatar">
              <Avatar fullName={displayName} variant="large" />
              <p className="AccountContainer__account-name">{displayName}</p>
              <p className="AccountContainer__account-level"></p>
            </div>
            <div className="AccountContainer__profile-sidebar-section">
              <h3>Skills</h3>
              <p>coming soon</p>
            </div>
          </div>
          <div className="AccountContainer__profile-content">
            <div className="AccountContainer__profile-content-section AccountContainer__profile-badges">
              <h3>Received badges</h3>
              <AchievementBadge width={48} />
            </div>
            <div className="AccountContainer__profile-content-section AccountContainer__profile-next-objective">
              <h3>Next objective</h3>
              <p>
                Raising an incident record helps clarify how many problems you
                are facing
              </p>
            </div>
            <div className="AccountContainer__profile-content-section">
              <h3>Insights</h3>
              {/* eslint-disable-next-line sonarjs/table-header */}
              <table className="AccountContainer__profile-content-insights">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <span>Fastest Solve:</span> n/a
                      </p>
                      <p>
                        <span>Time:</span> n/a
                      </p>
                    </td>
                    <td>
                      <p>
                        <span>Technical Skills Rating:</span> n/a
                      </p>
                      <p>
                        <span>Expertise: </span> n/a
                      </p>
                    </td>
                    <td>
                      <p>
                        <span>Communication Rating: </span> n/a
                      </p>
                      <p>
                        <span>Average Time to Solve:</span> n/a
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
