import { DependencyContainer } from '../../../DependencyContainer';
import { Award, AwardList } from '../types';

export default class AwardService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAwards(token: string): Promise<AwardList> {
    const certificats = await this.factory.awardClient.fetchAwards(token);

    return certificats.data;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async generateCertificate(token: string, certificate: Award): Promise<any> {
    return await this.factory.awardClient.fetchCertificate(token, certificate);
  }
}
