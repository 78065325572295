import { ApiUrls } from '../../../config/urls';
import { DependencyContainer } from '../../../DependencyContainer';
import { HttpClient } from '../../../HttpClient';
import { MqttStaticTopics } from '../mqtt-topics';
import { PlayerRoles } from '../types';

const configOverride = {
  baseURL: window.envs.REACT_APP_API_URL,
};

export default class PlayerService extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  savePlayerRole(email: string, role: PlayerRoles) {
    this.factory.mqttService.publish({
      topic: MqttStaticTopics.CONFIGURE_PLAYER,
      payload: {
        player: email,
        persona: role,
      },
    });
  }

  savePlayerRoleApi = async (token: string, role: PlayerRoles) => {
    this.setToken(token);
    const response = await this.post(
      ApiUrls.getUrlForProfilePersona(),
      { persona: role },
      configOverride,
    );
    return response;
  };
}
