import { FunctionComponent } from 'react';

import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { StaffCardPerson } from '../StaffCard/StaffCard';

type StaffDetailsModalProps = {
  person: StaffCardPerson;
  isOpen: boolean;
  onRequestClose: () => void;
};
const StaffDetailsModal: FunctionComponent<StaffDetailsModalProps> = ({
  person,
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader />
      <ModalBody>
        <div className="OnlineBoutiqueIntroduction__modal">
          <div className="OnlineBoutiqueIntroduction__modal-left">
            {typeof person.image === 'string' ? (
              <img
                className="OnlineBoutiqueIntroduction__modal-image"
                src={person.image}
                alt={person.name}
              />
            ) : (
              person.image
            )}
          </div>
          <div className="OnlineBoutiqueIntroduction__modal-right">
            <h2>{person.name}</h2>
            {person.description && <p>{person.description}</p>}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StaffDetailsModal;
