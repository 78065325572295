import architectureDiagram from '../../../../../assets/images/introduction/architecture-diagram.png';
import AudioPlayer, { AudioPlayerStyles } from '../../../../../components/AudioPlayer/AudioPlayer';

const OnlineBoutiqueIntroductionStep4 = () => {
  return (
    <div className="OnlineBoutiqueIntroduction">
      <AudioPlayer
        source="/audio/k8_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      <img src={architectureDiagram} alt="Architecture Diagram" />
    </div>
  );
};

export default OnlineBoutiqueIntroductionStep4;
