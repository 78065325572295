import { FC } from 'react';

import useScroll from '../../features/common/hooks/useScroll';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import Button from '../Button/Button';
import { LevelProps } from '../Carousel/Carousel';
import CarouselButton from '../Carousel/CarouselButton';

const FeaturedDrills: FC<LevelProps> = ({ levels, startLevel }) => {
  const itemWidth = 1135; // in pixels
  const pixelsPerInterval = 15; // decrease for smoother movement
  const intervalDuration = 1; // decrease for faster scrolling
  const { scrollContainerRef, scroll } = useScroll(itemWidth, pixelsPerInterval, intervalDuration);

  return (
    <div className="carousel-container" data-testid="Carousel">
      <div className="carousel-content">
        <div className="carousel-background" ref={scrollContainerRef}>
          <CarouselButton direction="left" onClick={() => scroll('left')} />
          <CarouselButton direction="right" onClick={() => scroll('right')} />

          <p>FEATURED DRILLS</p>
          {levels.map((level: WordpressContent<WordpressLevel>) => (
            <div className="carousel-buttons" key={level.slug} data-testid="Carousel">
              <Button className="carousel-buttons" onClick={() => startLevel(level.slug)}>
                <div className="carousel-content">
                  <span className="vertical-line"></span>
                  <div className="carousel-text-content">
                    <span className="carousel-title">{level.title.rendered}</span>
                    <span className={`carousel-difficulty`} data-difficulty={level.acf.difficulty}>
                      {level.acf.difficulty}
                    </span>
                  </div>
                </div>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedDrills;
