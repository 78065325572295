import { useKeycloak } from '@react-keycloak/web';

type UseTenantRolesValues = string[];

export const useTenantRoles = (): UseTenantRolesValues => {
  const { keycloak } = useKeycloak();
  return (
    keycloak.tokenParsed?.realm_access?.roles.filter((role: string) => /^tenant-(.*)/.exec(role)) ||
    []
  );
};
