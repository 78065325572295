import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Joyride from 'react-joyride';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import AudioPlayer, { AudioPlayerStyles } from '../../../components/AudioPlayer/AudioPlayer';
import Carousel from '../../../components/Carousel/Carousel';
import AllDrills from '../../../components/Drills/AllDrills';
import AllDrillsDemo from '../../../components/Drills/AllDrillsDemo';
import PendingContent, {
  PendingContentStyles,
} from '../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import { joyrideStyles } from '../../../config/config';
import { Features } from '../../../config/features';
import { AppRoutes } from '../../../config/routes';
import { DependencyContainer } from '../../../DependencyContainer';
import { usePersistentState } from '../../../persistent-state/persistent-state';
import { levelsAtom } from '../../../state';
import { useFeatureFlag } from '../../common/hooks/useFeatureFlag';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { useUser } from '../../common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../content/types';
import LockedScenarioMessage from '../components/LockedScenarioMessage';
import { Scenario } from '../types';

const { scenariosService, contentService } = DependencyContainer.getInstance();

const ScenarioLevelChooserContainer: FunctionComponent = () => {
  const user = useUser();
  const goToRoute = useRedirectToRoute();
  const { id } = useParams();
  const [levels, setLevels] = useRecoilState(levelsAtom);
  const [loading, scenario, error] = useFetchData<Scenario, Error>(() => {
    if (!id) {
      return Promise.reject(new Error('No ID provided'));
    }
    return scenariosService.getScenario(id);
  });
  const [levelsLoading, , levelsError] = useFetchData<
    Array<WordpressContent<WordpressLevel>>,
    Error
  >(
    async () => {
      if (!id) {
        return Promise.reject(new Error('No ID provided'));
      }
      return await contentService.getLevels(id);
    },
    (response) => {
      let sortedDrills;

      if (user.isAdmin) {
        sortedDrills = response.toSorted((level, next) => level.acf.order - next.acf.order);
      } else {
        sortedDrills = response
          .filter((level) => !level.acf.hidden)
          .sort((level, next) => level.acf.order - next.acf.order);
      }
      setLevels(sortedDrills);
    },
  );

  const startLevel = (levelSlug: string) => {
    if (!scenario) {
      return;
    }
    goToRoute(AppRoutes.ScenarioLevel, {
      id: scenario.id,
      level: levelSlug,
    });
  };

  const hasAtLeastOneLevelUnlocked = scenario?.levels.some((level) => level.isAvailable);

  const isScenarioLocked = !hasAtLeastOneLevelUnlocked && !loading && scenario;
  const steps = [
    {
      target: '.carousel-container',
      content:
        'Here is a place where you can select your level for the scenario. We recommend to keep our order.',
    },
  ];
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);

  return (
    <div className="ScenariosContainer">
      <Helmet>
        <title>Play Drill - UptimeLabs</title>
      </Helmet>
      {!loading && scenario && (
        <Helmet>
          <title> Drills - UptimeLabs</title>
        </Helmet>
      )}
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
      >
        <AudioPlayer
          source="/audio/levels_intro.mp3"
          classes={(current: AudioPlayerStyles) => ({
            ...current,
            root: `${current.root} ScenariosContainer__audio-player`,
          })}
        />
        {hasAtLeastOneLevelUnlocked && (
          <>
            {isOnBoardingAvailable() && (
              <Joyride
                steps={steps}
                stepIndex={0}
                run={!tourRan}
                scrollToFirstStep
                styles={joyrideStyles}
              />
            )}

            <PendingContent
              classes={(current: PendingContentStyles) => ({
                ...current,
                root: `${current.root} `,
              })}
              loading={levelsLoading}
              isError={Boolean(levelsError)}
              hideContent
              loader={SpinnerLoader}
              data-testid="PendingContent"
            >
              {hasAtLeastOneLevelUnlocked && (
                <Carousel
                  data-testid="Carousel"
                  levels={levels.filter((level) => level.acf.is_available || user.isAdmin)}
                  startLevel={startLevel}
                />
              )}
              {hasAtLeastOneLevelUnlocked && (
                <>
                  {user?.isDemo ? (
                    <AllDrillsDemo
                      data-testid="AllDemoDrills"
                      levels={levels}
                      startLevel={startLevel}
                    />
                  ) : (
                    <AllDrills data-testid="AllDrills" levels={levels} startLevel={startLevel} />
                  )}
                </>
              )}
            </PendingContent>
          </>
        )}
        {isScenarioLocked && <LockedScenarioMessage />}
      </PendingContent>
    </div>
  );
};

export default ScenarioLevelChooserContainer;
