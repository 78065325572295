import { FunctionComponent } from 'react';

import useIntroductionPlayer from '../../hooks/useIntroductionPlayer';
import { Scenario, ScenarioIntroductionStepDefinition } from '../../types';

export type IntroductionPlayerProps = {
  steps: ScenarioIntroductionStepDefinition[];
  scenario: Scenario;
  step: number;
  onFinishIntroduction: () => void;
  hideControls: boolean;
};

export type IntroductionPlayerStep = {
  scenario: Scenario;
};

const IntroductionPlayer: FunctionComponent<IntroductionPlayerProps> = ({
  steps,
  scenario,
  hideControls,
  step,
}) => {
  const { actions, renderedStep } = useIntroductionPlayer(steps, scenario, step);

  return (
    <div data-testid="IntroductionPlayer" className="IntroductionPlayer">
      <div data-testid="IntroductionPlayer__header" className="IntroductionPlayer__header">
        <h2>Introduction to {scenario.title}</h2>
      </div>
      <div className="IntroductionPlayer__content">{renderedStep}</div>
      {!hideControls && actions && (
        <div data-testid="IntroductionPlayer__actions" className="IntroductionPlayer__actions">
          {actions}
        </div>
      )}
    </div>
  );
};

export default IntroductionPlayer;
