import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import Joyride from 'react-joyride';

import Button, { ButtonStyles } from '../../../../components/Button/Button';
import Separator from '../../../../components/Separator/Separator';
import { joyrideStyles } from '../../../../config/config';
import { Features } from '../../../../config/features';
import { UrlsKeys } from '../../../../config/urls';
import { usePersistentState } from '../../../../persistent-state/persistent-state';
import { useFeatureFlag } from '../../../common/hooks/useFeatureFlag';
import { EnvironmentUrlGetter } from '../../types';
import { GamePlayLinksText } from './components/GamePlayLinksText';

type GamePlayLinksProps = {
  links?: EnvironmentUrlGetter[];
  isLinkOpened: (key: UrlsKeys) => boolean;
  openLink: (getterObject: EnvironmentUrlGetter) => void;
  resolveLink: (getterObject: EnvironmentUrlGetter) => string;
  setIntroductionActiveStep: Dispatch<SetStateAction<number | undefined>>;
};

const addStylesForBoxButtons = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} ScenariosContainer__play-scenario-box-button`,
});

const GamePlayLinks: FunctionComponent<GamePlayLinksProps> = ({
  links,
  isLinkOpened,
  openLink,
  resolveLink,
  setIntroductionActiveStep,
}) => {
  const [, setTick] = useState<number>(0);
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const steps = [
    {
      target: '.ScenariosContainer',
      content: `
        Here you will see current game status. Initially your game is on ready state.
        Once the normal game play is started, you will see the counter ticking down.
        Regardless you pass or fail the scenario, you will see proper information in this place.
        We recommend to open all needed links by clicking on each button. If you feel yourself confused you can always go back to the information from the introduction, by clicking on buttons from Useful information section.
        `,
    },
  ];
  const [tourRan] = usePersistentState('tourRan');

  return (
    <div className="ScenariosContainer__play-scenario-box-links">
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          run={!tourRan}
          scrollToFirstStep
          styles={joyrideStyles}
        />
      )}
      <GamePlayLinksText />
      {links?.map((getterObject) => {
        if (getterObject) {
          return (
            <Button
              disabled={isLinkOpened(getterObject.key)}
              classes={addStylesForBoxButtons}
              key={getterObject.key}
              data-link={resolveLink(getterObject)}
              pulsed={getterObject.highlighted}
              onClick={() => {
                openLink(getterObject);
              }}
              variant={getterObject.highlighted ? 'superprimary' : 'primary'}
              size="small"
            >
              {getterObject.label}
              {isLinkOpened(getterObject.key) && ' (opened)'}
            </Button>
          );
        }
      })}
      <Separator title="Useful information" />
      <Button
        size="small"
        onClick={() => setIntroductionActiveStep(2)}
        classes={addStylesForBoxButtons}
      >
        Organisation Structure
      </Button>
      <Button
        size="small"
        onClick={() => setIntroductionActiveStep(3)}
        classes={addStylesForBoxButtons}
      >
        Kubernetes Services
      </Button>
    </div>
  );
};

export default GamePlayLinks;
