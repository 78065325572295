import { randomBytes } from 'crypto';

export const randomNumber = () => {
  const buffer = randomBytes(8);
  const hex = buffer.toString('hex');
  return parseInt(hex, 16) / Number.MAX_SAFE_INTEGER;
};
export const randomNaturalNumber = (min = 1, max = 100) =>
  Math.floor(
    (parseInt(randomBytes(4).toString('hex'), 16) / 0xffffffff) *
      (max - min + 1),
  ) + min;
export const randomString = () => randomNumber().toString(36).substring(7);
