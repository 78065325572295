import { useKeycloak } from '@react-keycloak/web';
import cx from 'classnames';
import { FunctionComponent } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useRecoilValue } from 'recoil';

import Avatar from '../../../components/Avatar/Avatar';
import Badge from '../../../components/Badge/Badge';
import Card, { CardStyles } from '../../../components/Card/Card';
import PendingContent from '../../../components/PendingContent/PendingContent';
import { reportsSelectedUserEmailSelector } from '../../../state';
import { getDisplayNameFromToken } from '../../common/helpers/text.helper';
import { ClassesModifier } from '../../common/hooks/useClasses';
import { useUser } from '../../common/hooks/useUser';
import { Maybe } from '../../common/types';
import { getQuestionByType } from '../config';
import { Feedback, ReportPlayerAssessment } from '../types';
import logo from './../../../assets/images/logo-min.png';

type ReportFeedbackCardProps = {
  assessment: Maybe<ReportPlayerAssessment>;
  feedback: Maybe<Feedback[]>;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  playerEmail: string;
  stylesProvider: ClassesModifier<CardStyles>;
};

type AvailableAssessmentIndicator = {
  name: keyof ReportPlayerAssessment;
  caption: string;
};

//old report information
const availableIndicators: AvailableAssessmentIndicator[] = [
  {
    name: 'how_challenging',
    caption: 'How Challenging',
  },
  {
    name: 'how_enjoyable',
    caption: 'How Satisfying',
  },
  {
    name: 'how_stressful',
    caption: 'How Stressful',
  },
];
const scale = 3;

const ReportFeedbackCard: FunctionComponent<ReportFeedbackCardProps> = ({
  assessment,
  feedback,
  loading,
  error,
  playerEmail,
  stylesProvider,
}) => {
  const { keycloak } = useKeycloak();
  const user = useUser();
  const reportsSelectedUserEmail = useRecoilValue(reportsSelectedUserEmailSelector);
  const getAvatarDisplayName = (fallback: string) => {
    return user.isAdmin && reportsSelectedUserEmail
      ? fallback
      : getDisplayNameFromToken(keycloak.tokenParsed);
  };
  const hasError = error && error?.response?.status !== 404;

  /**
   * Generates the card title based on feedback and assessment.
   * @param {Feedback[]} feedback - The feedback array.
   * @param {ReportPlayerAssessment} assessment - The assessment object.
   * @returns {Element} The card title element.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function cardTitle(feedback: any, assessment: any) {
    if (assessment) {
      return (
        <>
          Assessment <small className="text-gray">({assessment?.length || 0})</small>
        </>
      );
    } else {
      return (
        <>
          Feedback <small className="text-gray">({feedback?.length - 1 || 0})</small>
        </>
      );
    }
  }
  return (
    <Card title={cardTitle(feedback, assessment)} collapsible classes={stylesProvider}>
      <div className="ReportContainer__feedback">
        {feedback && !assessment ? (
          <div className="ReportContainer__feedback-V2" />
        ) : (
          <>
            {assessment ? (
              <>
                <div className="ReportContainer__feedback-section">Assessment</div>
                <PendingContent loading={loading} isError={hasError} data-testid="PendingContent">
                  <div className="ReportContainer__assessment">
                    {availableIndicators.map((indicator) => (
                      <div key={indicator.name} className="ReportContainer__assessment-item">
                        <CircularProgressbar
                          value={(assessment[indicator.name] / scale) * 100}
                          text={`${assessment[indicator.name]}/${scale}`}
                          strokeWidth={16}
                          styles={{
                            path: {
                              stroke: 'rgb(0 140 3)',
                            },
                            text: {
                              fill: 'rgb(0 140 3)',
                            },
                          }}
                        />
                        <div className="ReportContainer__assessment-item-caption">
                          {indicator.caption}
                        </div>
                      </div>
                    ))}
                  </div>
                </PendingContent>
              </>
            ) : (
              <p className="ReportContainer_empty-feedback-display">
                Feedback not submitted, please fill out feedback form
              </p>
            )}
          </>
        )}
        <div className="ReportContainer__feedback-section">Feedback</div>
        <div className="ReportContainer__feedback-questions">
          {feedback?.map((item: Feedback) => {
            const question = getQuestionByType(item.type, item.from_uptimelabs);
            if (!question || !item.body) {
              return null;
            }
            return (
              <Card
                key={item.type}
                collapsible={false}
                title={
                  <div
                    className={cx('ReportContainer__feedback-question-header', {
                      'ReportContainer__feedback-question-header--from-uptimelabs': Boolean(
                        item.from_uptimelabs,
                      ),
                    })}
                  >
                    {item.from_uptimelabs ? (
                      <>
                        <Avatar imageSrc={logo} fullName="Uptime Labs" />
                        <Badge type="info">from Uptime Labs</Badge>
                      </>
                    ) : (
                      <Avatar fullName={getAvatarDisplayName(playerEmail)} />
                    )}
                  </div>
                }
              >
                <h4>{question}</h4>
                <p>{item.body}</p>
              </Card>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default ReportFeedbackCard;
