import { useKeycloak } from '@react-keycloak/web';

import { Maybe, PlayerRoles } from '../types';

export type User = {
  id: string;
  email: string;
  isAdmin: boolean;
  isDemo: boolean;
  name?: string;
  persona?: Maybe<PlayerRoles>;
  roles: string[];
  surname?: string;
  tenant: string;
};

export const useUser = (): User => {
  const { keycloak } = useKeycloak();
  const id = keycloak.tokenParsed?.sub ?? '';
  const email = keycloak.tokenParsed?.email;
  const persona = keycloak.tokenParsed?.persona || undefined;
  const roles =
    keycloak.tokenParsed?.realm_access?.roles.filter((role: string) => /^tenant-(.*)/.exec(role)) ||
    [];
  const isAdmin = Boolean(
    (keycloak.tokenParsed?.realm_access?.roles || []).filter((r) =>
      ['admins', 'app-admin'].includes(r),
    ).length,
  );
  const isDemo = Boolean(
    (keycloak.tokenParsed?.realm_access?.roles || []).filter((r) => ['demo'].includes(r)).length,
  );

  return {
    id,
    email,
    isAdmin,
    isDemo,
    persona,
    roles,
    tenant: roles[0]?.replace('tenant-', ''),
  };
};
