import { useKeycloak } from '@react-keycloak/web';
import { FunctionComponent, ReactNode, useEffect } from 'react';

import { AppRoutes } from '../../../config/routes';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import useGetTenant from '../hooks/useGetTenant';
import { useRedirectToRoute } from '../hooks/useRedirectToRoute';

interface ForActivatedAccountProps {
  children: ReactNode;
}

const ForActivatedAccount: FunctionComponent<ForActivatedAccountProps> = ({ children }) => {
  const { initialized } = useKeycloak();
  const tenant = useGetTenant();
  const currentRoute = useCurrentRoute();
  const goToRoute = useRedirectToRoute();

  useEffect(() => {
    if (tenant == undefined && currentRoute() !== AppRoutes.InactiveAccount) {
      goToRoute(AppRoutes.InactiveAccount);
    }
  }, [currentRoute, goToRoute, tenant]);

  return initialized ? <>{children}</> : null;
};

export default ForActivatedAccount;
