import { FC } from 'react';

export const LicenseToOperateTargetGrades: FC = () => {
  return (
    <div className="skills-radarchart__legend-details">
      <div className="skills-radarchart__legend-details-header">
        <div className="key-color-2"></div>
        <div className="skills-radarchart__heading">
          License to Operate Target Grades
        </div>
      </div>
    </div>
  );
};
