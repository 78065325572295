import * as Sentry from '@sentry/react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    envs: {
      REACT_APP_API_URL: string;
      REACT_APP_CMS_URL: string;
      REACT_APP_ENV: string;
      REACT_APP_KEYCLOAK_CLIENT_ID: string;
      REACT_APP_KEYCLOAK_REALM: string;
      REACT_APP_KEYCLOAK_URL: string;
      REACT_APP_LAUNCH_DARKLY_CLIENT_ID: string;
      REACT_APP_MQTT_HOST: string;
      REACT_APP_MQTT_PASSWORD: string;
      REACT_APP_MQTT_PATH: string;
      REACT_APP_MQTT_PORT: string;
      REACT_APP_MQTT_PROTOCOL: string;
      REACT_APP_MQTT_USERNAME: string;
      REACT_APP_SENTRY_DSN: string;
      REACT_APP_SLACK_URL_CHANNELS: string;
      REACT_APP_SLACK_URL_TEMPLATE: string;
    };
  }
}

Sentry.init({
  dsn: window.envs.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: window.envs.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration: ServiceWorkerRegistration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}

const clientSideID = window.envs.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
createRoot(document.getElementById('root')!).render(
  <LDProvider clientSideID={clientSideID}>
    <App />
  </LDProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
