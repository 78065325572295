import { FC } from 'react';

export const MinimumCompetency: FC = () => {
  return (
    <div className="skills-radarchart__lto-description">
      {' '}
      The minimum competency grade required to achieve readiness and effectively
      manage incidents.{' '}
    </div>
  );
};
