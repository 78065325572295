import cx from 'classnames';
import { any, oneOf } from 'prop-types';
import { JSX, ReactElement, useState } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import { OR } from '../../features/common/types';
import { ChevronUpIcon } from '../Icons';

type BaseCardProps = {
  title?: string | ReactElement;
  variant?: 'standard' | 'danger';
} & Omit<JSX.IntrinsicElements['div'], 'title'>;

type CollapsibleCardProps = {
  collapsible: true;
  expanded?: string;
};

type NonCollapsibleCardProps = {
  collapsible: false;
};

type CardProps = BaseCardProps & OR<CollapsibleCardProps, NonCollapsibleCardProps>;

export type CardStyles = {
  root: string;
  title: string;
  titleIcon: string;
  content: string;
};

const Card: AdjustableComponent<CardProps, CardStyles> = ({
  children,
  classes,
  title,
  collapsible = false,
  variant = 'standard',
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    //@ts-expect-error - expanded is only available when collapsible is true
    rest.expanded || false,
  );
  const styles = useClasses(
    {
      root: cx('Card', {
        [`Card--${variant}`]: true,
      }),
      title: cx('Card__title', {
        'Card__title--expanded': isExpanded,
      }),
      content: cx('Card__content', {
        'Card__content--expanded fade-in': collapsible && isExpanded,
        'Card__content--expanded fade-out': collapsible && !isExpanded,
      }),
      titleIcon: cx({
        'Card__title-icon--expanded': isExpanded,
        'Card__title-icon': !isExpanded,
      }),
    },
    classes,
  );

  const onTitleClick = () => {
    if (!collapsible) {
      return;
    }
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={styles.root} {...rest}>
      {title && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.title} onClick={() => onTitleClick()}>
          {title}
          {collapsible && <ChevronUpIcon className={styles.titleIcon} />}
        </div>
      )}
      {collapsible ? <div className={styles.content}>{children}</div> : <>{children}</>}
    </div>
  );
};

Card.propTypes = {
  children: any,
  classes: any,
  collapsible: any,
  title: any,
  variant: oneOf(['standard', 'danger']),
};

export default Card;
