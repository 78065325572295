import { FunctionComponent } from 'react';

import Button from '../../../../components/Button/Button';
import { AppRoutes } from '../../../../config/routes';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';

const WrongScenario: FunctionComponent = () => {
  const goToRoute = useRedirectToRoute();
  return (
    <>
      <p>Wrong scenario</p>
      <Button size="small" onClick={() => goToRoute(AppRoutes.Scenarios)}>
        go back
      </Button>
    </>
  );
};

export default WrongScenario;
