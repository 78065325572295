import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../config/routes';
import { getMatchedRoute } from '../helpers/routes.helper';

type UseCurrentRouteReturnType = () => AppRoutes;

export const useCurrentRoute = (): UseCurrentRouteReturnType => {
  const location = useLocation();

  return (): AppRoutes => {
    try {
      const route = getMatchedRoute(location.pathname);
      return route;
    } catch (error) {
      console.error('useCurrentRoute error:', error);
      return AppRoutes.Account;
    }
  };
};
