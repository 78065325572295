import { WordpressContent } from '../content/types';

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
export type OR<T, U> = T | U;

export type Arraize<T> = T | T[];

export type UserInTests = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  password: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Fixture<T> = (override?: Partial<T>, ...args: any[]) => T;
export type ContentFixture<T> = (
  override?: Partial<WordpressContent<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => WordpressContent<T>;
export type Maybe<T> = T | undefined;

export enum PlayerRoles {
  IncidentManager = 'IM',
  SiteReliabilityEngineer = 'SRE',
}
