import './Carousel.scss';
import '../../components/Drills/AllDrills.scss';

import { FC } from 'react';

import { useUser } from '../../features/common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import FeaturedDrills from '../Drills/FeaturedDrills';
import FeaturedDrillsDemo from '../Drills/FeaturedDrillsDemo';

export interface LevelProps {
  levels: Array<WordpressContent<WordpressLevel>>;
  startLevel: (levelSlug: string) => void;
}

const Carousel: FC<LevelProps> = ({ levels, startLevel }) => {
  const user = useUser();
  return user?.isDemo ? (
    <FeaturedDrillsDemo levels={levels} startLevel={startLevel} />
  ) : (
    <FeaturedDrills levels={levels} startLevel={startLevel} />
  );
};

export default Carousel;
