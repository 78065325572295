import { FunctionComponent, ReactNode } from 'react';

const TableBody: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <tbody className="Table__body" data-testid="Table__body">
      {children}
    </tbody>
  );
};

export default TableBody;
