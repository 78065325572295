import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '../../../components/Button/Button';
import { CardStyles } from '../../../components/Card/Card';
import PendingContent from '../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import { AppRoutes } from '../../../config/routes';
import { DependencyContainer } from '../../../DependencyContainer';
import { reportsSelectedUserEmailSelector } from '../../../state';
import { useBearerToken } from '../../common/hooks/useBearerToken';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { useUser } from '../../common/hooks/useUser';
import { Maybe } from '../../common/types';
import ReportFeedbackCard from '../../reports/components/ReportFeedbackCard';
import ReportSummaryCard from '../../reports/components/ReportSummaryCard';
import ReportTimeLineCard from '../../reports/components/ReportTimeLineCard';
import {
  Feedback,
  LevelStates,
  Report,
  ReportPlayerAssessment,
  ReportTimeLineEvent,
} from '../../reports/types';

const addStylesToCard = (current: CardStyles) => ({
  ...current,
  root: `${current.root} ReportContainer__card`,
  content: `${current.content} ReportContainer__card-content`,
});

const { reportsService } = DependencyContainer.getInstance();

const ReportContainer = () => {
  const { id } = useParams();
  const user = useUser();
  const reportsSelectedUserEmail = useRecoilValue(reportsSelectedUserEmailSelector);
  const token = useBearerToken();
  const redirectToRoute = useRedirectToRoute();

  const getEmail = (): Maybe<string> =>
    user.isAdmin && reportsSelectedUserEmail ? reportsSelectedUserEmail : undefined;

  const getThrottleConditions = (): boolean => !token || !id || !user;

  const [loading, report, error] = useFetchData<Report, AxiosError>(
    () => {
      return reportsService.getReport(parseInt(id!), token!, getEmail());
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [loadingTimeLineEvents, timeLineEvents] = useFetchData<ReportTimeLineEvent[], Error>(
    () => {
      return reportsService.getTimeLineEvents(parseInt(id!), token!, getEmail());
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [loadingAssessment, assessment, assessmentError] = useFetchData<
    ReportPlayerAssessment,
    Error
  >(
    () => {
      return reportsService.getPlayerAssessment(parseInt(id!), token!, getEmail());
    },
    undefined,
    [token, id],
    () => getThrottleConditions(),
  );

  const [, feedback] = useFetchData<Feedback[], Error>(
    () => {
      return reportsService.getPlayerFeedback(parseInt(id!), token!, getEmail());
    },
    undefined,

    [token, id],
    () => getThrottleConditions(),
  );

  const goBack = () => {
    redirectToRoute(AppRoutes.Account);
  };

  // eslint-disable-next-line sonarjs/function-return-type
  const renderError = () => {
    if (!error) {
      return null;
    }
    const code = error?.response?.status;
    if (code === 404) {
      return (
        <>
          <h4>Report not found</h4>
          <Button size="small" onClick={() => goBack()}>
            go back
          </Button>
        </>
      );
    }
    return 'Something went wrong';
  };
  const isIncomplete =
    Boolean(timeLineEvents?.find((e) => e.state === LevelStates.OutOfTime)) ||
    !timeLineEvents?.find((e) => e.state === LevelStates.Completed);

  return (
    <div className="ReportContainer">
      {report && (
        <div className="ReportContainer__action-buttons">
          <Button size="small" onClick={() => goBack()}>
            back
          </Button>
        </div>
      )}

      <PendingContent
        loading={loading || loadingTimeLineEvents}
        isError={Boolean(error)}
        hideContent
        loader={SpinnerLoader}
        message={renderError()}
      >
        {report && timeLineEvents && (
          <>
            <ReportSummaryCard
              report={report}
              isIncomplete={isIncomplete}
              stylesProvider={addStylesToCard}
            />
            <ReportTimeLineCard stylesProvider={addStylesToCard} timeLineEvents={timeLineEvents} />
            <ReportFeedbackCard
              loading={loadingAssessment}
              error={assessmentError}
              assessment={assessment}
              playerEmail={report.player_email}
              feedback={feedback}
              stylesProvider={addStylesToCard}
            />
          </>
        )}
      </PendingContent>
    </div>
  );
};

export default ReportContainer;
