import 'react-tooltip/dist/react-tooltip.css';

import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

interface PlayerRadarChartTooltipProps {
  id: string;
}

const PlayerRadarChartTooltip: FC<PlayerRadarChartTooltipProps> = ({ id }) => {
  return (
    <Tooltip
      id={id}
      data-testid={id}
      place="top"
      render={({ content }) =>
        content ? <div dangerouslySetInnerHTML={{ __html: content! }} /> : null
      }
      className="radar-chart-tooltip"
      style={{ cursor: 'pointer' }}
    />
  );
};

export default PlayerRadarChartTooltip;
