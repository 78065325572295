import { any, string } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type SeparatorStyles = {
  root: string;
  title: string;
};

type SeparatorProps = {
  title: string;
} & JSX.IntrinsicElements['div'];

const Separator: AdjustableComponent<SeparatorProps, SeparatorStyles> = ({ title, ...props }) => {
  const classes = useClasses(
    {
      root: 'Separator',
      title: 'Separator__title',
    },
    props.classes,
  );

  return (
    <div {...props} className={classes.root} data-testid="Separator">
      <span className={classes.title} data-testid="Separator__title">
        {title}
      </span>
    </div>
  );
};

Separator.propTypes = {
  classes: any,
  title: string.isRequired,
};

export default Separator;
