import { PlayerRoles } from '../features/common/types';

export type ExplanatoryVideoConfig = {
  width: number;
  height: number;
  url: string;
  title?: string;
};
type MetaConfig = {
  defaultTitle: string;
  gameReadyTitle: string;
  gamePlayingTitle: string;
  gamePausedTitle: string;
  gameAbortedTitle: string;
  gameSuccessTitle: string;
  gameFailedTitle: string;
};

export const explanatoryVideoConfiguration: ExplanatoryVideoConfig = {
  width: 640,
  height: 480,
  url: 'https://www.youtube.com/embed/S_Z2TPYfk1U',
  title: 'How it works?',
};

export const metaConfiguration: MetaConfig = {
  defaultTitle: 'Uptime Labs',
  gameReadyTitle: '⌛ Drill will start soon',
  gamePlayingTitle: '▶️️️️ {timeLeft} remaining',
  gamePausedTitle: '⏸️ {timeLeft} paused',
  gameAbortedTitle: '⚠️ Drill aborted',
  gameSuccessTitle: '✅ Drill completed',
  gameFailedTitle: '❌ Not this time...',
};

export const GLOBAL_MEDIA_QUERIES = {
  small: '(max-width: 769px)',
  medium: '(min-width: 770px) and (max-width: 1199px)',
  large: '(min-width: 1200px)',
};

export const joyrideStyles = {
  options: {
    primaryColor: 'rgb(94, 179, 233)',
  },
};

export const audioVolume = 0.75;

export const availablePlayerRoles: Array<{ name: PlayerRoles; label: string }> = [
  {
    name: PlayerRoles.IncidentManager,
    label: 'Incident Manager',
  },
  /**{
    name: PlayerRoles.SiteReliabilityEngineer,
    label: 'Site Reliability Engineer',
  },*/
];

export const contentConfiguration = {
  OnlineBoutique: {
    introductionStep2ContentSlug: 'introduction-to-online-boutique-e-commerce',
    introductionStep4ContentSlug: 'kubernetes-diagram-for-online-boutique',
  },
};

export const reportTimeLineTheme = {
  marker: {
    borderColor: 'rgb(0, 40, 86)',
    backgroundColor: 'rgb(0, 40, 86)',
  },
  date: {
    backgroundColor: 'rgb(0, 40, 86)',
  },
  timelineTrack: {
    backgroundColor: 'rgb(0, 40, 86)',
  },
};
