import { DependencyList, useCallback, useEffect, useState } from 'react';

import { Maybe } from '../types';

type UseFetchDataValues<T, K> = [boolean, Maybe<T>, Maybe<K>, () => Promise<void>, boolean];

export const useFetchData = <T = unknown, K = unknown>(
  fetcherFn: () => Promise<T | void>,
  setterFn?: (data: T) => void,
  deps?: DependencyList,
  throttleUntil?: () => boolean,
): UseFetchDataValues<T, K> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<K>();
  const [data, setData] = useState<T>();
  const [complete, setComplete] = useState<boolean>(false);

  const fetchAsyncData = useCallback(async () => {
    const postFetchAction = (response: T | void) => {
      if (!response) {
        return;
      }
      if (setterFn) {
        setterFn(response);
      } else {
        setData(response);
      }
    };
    const isThrottled = throttleUntil?.() || false;
    setComplete(false);
    if (isThrottled) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetcherFn();
      postFetchAction(response);
      setError(undefined);
    } catch (error) {
      setError(error as K);
    } finally {
      setComplete(true);
      setLoading(false);
    }
  }, [fetcherFn, setterFn, throttleUntil]);

  useEffect(() => {
    fetchAsyncData();
  }, deps || []);

  return [loading, data, error, fetchAsyncData, complete];
};
