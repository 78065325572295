import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { DependencyContainer } from '../../../DependencyContainer';
import {
  awardsAtom,
  awardsLoadingAtom,
  reportsAtom,
  reportsLoadingAtom,
  reportsSelectedUserEmailSelector,
} from '../../../state';
import { AwardList } from '../../awards/types';
import { useBearerToken } from '../../common/hooks/useBearerToken';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useUser } from '../../common/hooks/useUser';
import { Report } from '../../reports/types';
import AdvancedDashboard from '../components/Dashboard/AdvancedDashboard';

const { reportsService } = DependencyContainer.getInstance();
const { awardService } = DependencyContainer.getInstance();

const AccountContainer = () => {
  const user = useUser();
  const [reports, setReports] = useRecoilState(reportsAtom);
  const setReportsLoading = useSetRecoilState(reportsLoadingAtom);
  const reportsSelectedUserEmail = useRecoilValue(reportsSelectedUserEmailSelector);
  const token = useBearerToken() ?? '';

  useFetchData<Report[], Error>(
    () => {
      if (!token || !user) Promise.reject(new Error('Token or user is missing'));
      setReportsLoading(true);
      const email = user.isAdmin && reportsSelectedUserEmail ? reportsSelectedUserEmail : undefined;
      return reportsService.getReports(token, email);
    },
    (reports) => setReports(reports),
    [token, reportsSelectedUserEmail],
    () => !token || !user,
  );

  useEffect(() => {
    if (reports) {
      setReportsLoading(false);
    }
  }, [reports, setReportsLoading]);

  const [awards, setAwards] = useRecoilState(awardsAtom);
  const setAwardsLoading = useSetRecoilState(awardsLoadingAtom);

  useFetchData<AwardList, Error>(
    async () => {
      if (!token) Promise.reject(new Error('Token is missing'));

      setAwardsLoading(true);
      return await awardService.getAwards(token);
    },
    (awards) => setAwards(awards),
    [token],
    () => !token,
  );

  useEffect(() => {
    if (awards) {
      setAwardsLoading(false);
    }
  }, [awards, setAwardsLoading]);

  return (
    <div className="AccountContainer">
      <AdvancedDashboard />
    </div>
  );
};

export default AccountContainer;
