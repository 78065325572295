import { any } from 'prop-types';
import { JSX } from 'react';

import { omit } from '../../features/common/helpers/objects.helper';
import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

export type TableStyles = {
  root: string;
};

type TableProps = JSX.IntrinsicElements['table'];

const Table: AdjustableComponent<TableProps, TableStyles> = (props) => {
  const propsToPass = omit(props, 'className', 'children', 'classes');

  const classes = useClasses(
    {
      root: 'Table',
    },
    props.classes,
  );

  return (
    <table {...propsToPass} className={classes.root} data-testid="Table">
      {props.children}
    </table>
  );
};

Table.propTypes = {
  children: any,
  classes: any,
};

export default Table;
