import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { metaConfiguration } from '../../../config/config';
import { Urls, UrlsKeys } from '../../../config/urls';
import { DependencyContainer } from '../../../DependencyContainer';
import { createGamePlay } from '../../../fixtures/scenarios.fixture';
import {
  GamePlay,
  gamePlayAtom,
  gamePlayTimeLeftSelector,
  GamePlayTopicResponse,
  GameStatus,
  levelsAtom,
} from '../../../state';
import { omit } from '../../common/helpers/objects.helper';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useUser } from '../../common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../content/types';
import GamePlayAborted from '../components/GamePlay/GamePlayAborted';
import GamePlayFailure from '../components/GamePlay/GamePlayFailure';
import GamePlayLinks from '../components/GamePlay/GamePlayLinks';
import GamePlayPlaying from '../components/GamePlay/GamePlayPlaying';
import GamePlayReady from '../components/GamePlay/GamePlayReady';
import GamePlaySuccess from '../components/GamePlay/GamePlaySuccess';
import WrongScenario from '../components/GamePlay/WrongScenario';
import ScenarioIntroductionModal from '../components/ScenarioIntroductionModal/ScenarioIntroductionModal';
import { EnvironmentUrlGetter, Scenario, ScenarioLevel, ScenarioLevels } from '../types';

type OpenedTab = {
  window: Window;
  key: UrlsKeys;
  label: string;
};

const { scenariosService, contentService } = DependencyContainer.getInstance();

const mapGameStatusToComponent: Record<GameStatus, ReactNode> = {
  [GameStatus.Ready]: <GamePlayReady />,
  [GameStatus.Playing]: <GamePlayPlaying />,
  [GameStatus.Paused]: <GamePlayPlaying />,
  [GameStatus.Aborted]: <GamePlayAborted />,
  [GameStatus.Success]: <GamePlaySuccess />,
  [GameStatus.Failure]: <GamePlayFailure />,
  // @deprecated - remove when backend is ready
  [GameStatus.Complete]: <GamePlaySuccess />,
};

const PlayScenarioContainer: FunctionComponent = () => {
  const [openedTabs, setOpenedTabs] = useState<OpenedTab[]>([]);
  const [introductionActiveStep, setIntroductionActiveStep] = useState<number | undefined>(
    undefined,
  );
  const user = useUser();
  const [gamePlay, setGamePlay] = useRecoilState<GamePlay>(gamePlayAtom);
  const timeLeft = useRecoilValue(gamePlayTimeLeftSelector);
  const { scenarioId, levelSlug } = useParams();
  const [scenario, setScenario] = useState<Scenario>();
  const [scenarioIsLoading, setScenarioIsLoading] = useState<boolean>(true);
  const [level, setLevel] = useState<ScenarioLevel>();
  const [scenarioLevels, setScenarioLevels] = useRecoilState(levelsAtom);
  const [levelId, setLevelId] = useState<string>();

  useEffect(() => {
    document.title = metaConfiguration.gameReadyTitle;

    return () => {
      document.title = metaConfiguration.defaultTitle;
    };
  }, []);

  useEffect(() => {
    if (timeLeft != undefined) {
      document.title = metaConfiguration.gamePlayingTitle.replace('{timeLeft}', timeLeft);
    }
  }, [timeLeft]);

  const [, ,] = useFetchData<Array<WordpressContent<WordpressLevel>>, Error>(
    () => {
      if (!scenarioId) {
        return Promise.reject(new Error('Scenario ID is missing'));
      }
      return contentService.getLevels(scenarioId);
    },
    (response) => {
      setScenarioLevels(response);
    },
    [],
    () => scenarioLevels.length > 0,
  );

  useEffect(() => {
    if (!scenarioLevels.length) {
      return;
    }
    const currentLevel = scenarioLevels.find((r) => r.slug === levelSlug);
    if (currentLevel) {
      setLevelId(currentLevel.acf.level_id.toString());
    }
  }, [levelSlug, scenarioLevels]);

  useEffect(() => {
    if (gamePlay.status === GameStatus.Playing) {
      return;
    }
    switch (gamePlay.status) {
      case GameStatus.Ready:
        document.title = metaConfiguration.gameReadyTitle;
        break;
      case GameStatus.Aborted:
        document.title = metaConfiguration.gameAbortedTitle;
        break;
      case GameStatus.Paused:
        document.title = metaConfiguration.gamePausedTitle.replace('{timeLeft}', timeLeft);
        break;
      case GameStatus.Failure:
        document.title = metaConfiguration.gameFailedTitle.replace('{timeLeft}', timeLeft);
        break;
      case GameStatus.Success:
        document.title = metaConfiguration.gameSuccessTitle;
        break;
    }
  }, [gamePlay.status, timeLeft]);

  useEffect(() => {
    if (!scenarioId || !levelId || scenario) {
      return;
    }
    scenariosService
      .getScenario(scenarioId)
      .then((s) => {
        setScenario(s);
        setScenarioIsLoading(false);
        scenariosService
          .getLevelOfScenario(s, levelId)
          .then((l) => setLevel(l))
          .catch(() => {});
      })
      .catch(() => {
        setScenarioIsLoading(false);
      });
  }, [levelId, scenario, scenarioId]);

  const getLinks = (): EnvironmentUrlGetter[] | undefined => {
    if (level?.level === ScenarioLevels.Level1) {
      return level?.environmentUrlGettersKeys;
    }
    return user.persona == 'IM'
      ? level?.environmentUrlGettersKeysIM
      : level?.environmentUrlGettersKeysSRE;
  };

  const resolveLink = (getterObject: EnvironmentUrlGetter) => {
    return Urls[getterObject.key](window.envs.REACT_APP_ENV);
  };

  const openLink = (getterObject: EnvironmentUrlGetter) => {
    if (!user.tenant) {
      alert('Error: Missing role for current tenant');
      return;
    }
    if ([GameStatus.Success, GameStatus.Failure, GameStatus.Aborted].includes(gamePlay.status)) {
      return;
    }
    const url = resolveLink(getterObject);
    if (isLinkOpened(getterObject.key)) {
      return;
    }

    const openedWindow = window.open(url, getterObject.label);
    if (!openedWindow) {
      return;
    }
    setOpenedTabs((prev) => [
      ...prev,
      {
        window: openedWindow,
        key: getterObject.key,
        label: getterObject.label,
      },
    ]);
  };

  const isLinkOpened = (key: UrlsKeys) => {
    return Boolean(openedTabs.find((t) => t.key === key && !t.window.closed));
  };

  useEffect(() => {
    const unsubscribe = scenariosService.getGameStatus(user, (message: GamePlayTopicResponse) => {
      if (message.scenario !== scenarioId || message.level.toString() !== levelId?.toString()) {
        return;
      }
      const response = omit(message, 'game_remaining_seconds');
      const parsedMessage: GamePlay = {
        ...response,
        gameRemainingSeconds: parseInt(message.game_remaining_seconds),
      };
      setGamePlay(parsedMessage);
    });

    return () => {
      unsubscribe();
      if ([GameStatus.Success, GameStatus.Failure, GameStatus.Complete].includes(gamePlay.status)) {
        // setting initial game play
        setGamePlay(createGamePlay({}, scenario));
      }
    };
  }, [gamePlay.status, levelId, scenario, scenarioId, setGamePlay, user]);

  useEffect(() => {
    return () => {
      setOpenedTabs([]);
    };
  }, []);

  useEffect(() => {
    if ([GameStatus.Success, GameStatus.Failure, GameStatus.Aborted].includes(gamePlay.status)) {
      setIntroductionActiveStep(undefined);
    }
  }, [gamePlay.status]);

  return (
    <div className="ScenariosContainer" data-testid="ScenariosContainer">
      <div
        className="ScenariosContainer__play-scenario-box"
        data-testid="ScenariosContainer__play-scenario-box"
      >
        {!scenarioIsLoading && !scenario ? (
          <WrongScenario data-testid="WrongScenario" />
        ) : (
          mapGameStatusToComponent[gamePlay.status]
        )}
        {getLinks() &&
          [GameStatus.Playing, GameStatus.Paused, GameStatus.Ready].includes(gamePlay.status) && (
            <GamePlayLinks
              links={getLinks()}
              isLinkOpened={isLinkOpened}
              openLink={openLink}
              resolveLink={resolveLink}
              setIntroductionActiveStep={setIntroductionActiveStep}
            />
          )}
      </div>
      {introductionActiveStep !== undefined && scenario && (
        <ScenarioIntroductionModal
          scenario={scenario}
          step={introductionActiveStep}
          isOpen={introductionActiveStep != undefined}
          onRequestClose={() => setIntroductionActiveStep(undefined)}
        />
      )}
    </div>
  );
};

export default PlayScenarioContainer;
