import { any } from 'prop-types';
import { JSX } from 'react';

import { omit } from '../../features/common/helpers/objects.helper';
import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type TableColumnStyles = {
  root: string;
  inner: string;
};

type TableColumnProps = JSX.IntrinsicElements['td'];

const TableColumn: AdjustableComponent<TableColumnProps, TableColumnStyles> = (props) => {
  const propsToPass = omit(props, 'className', 'children', 'classes');

  const styles = useClasses(
    {
      root: 'Table__column',
      inner: 'Table__column-inner',
    },
    props.classes,
  );

  return (
    <td {...propsToPass} className={styles.root} data-testid="Table__column">
      <div className={styles.inner} data-testid="Table__column-inner">
        {props.children}
      </div>
    </td>
  );
};

TableColumn.propTypes = {
  children: any,
  classes: any,
};

export default TableColumn;
