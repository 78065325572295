import { FC } from 'react';

export const GamePlayLinksText: FC = () => {
  return (
    <>
      <p>Below are links to the tools you&apos;ll need for this drill</p>
      <p>
        Press the <strong>slack</strong> button to start your drill:
      </p>
    </>
  );
};
