import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';

interface FeatureNotificationProps {
  message: string;
}

/**
 * FeatureNotification component that displays a notification about a new feature.
 * @param {string} message - The message to be displayed in the notification.
 * @returns {Element} The rendered component.
 */
const FeatureNotification: FC<FeatureNotificationProps> = ({ message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event: Event | SyntheticEvent<Element, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'timeout') {
      setOpen(false);
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      message={message}
      autoHideDuration={30000}
      ContentProps={{
        sx: {
          backgroundColor: 'green',
          color: 'white',
        },
      }}
    />
  );
};

export default FeatureNotification;
