import { useKeycloak } from '@react-keycloak/web';
import { FunctionComponent } from 'react';

import roleIcon from '../../assets/icons/Icons_multiplayer_16.svg';
import Avatar from '../../components/Avatar/Avatar';
import { usePersistentState } from '../../persistent-state/persistent-state';
import { getDisplayNameFromToken } from '../common/helpers/text.helper';
import { useUser } from '../common/hooks/useUser';

const AnalyticsHeader: FunctionComponent = () => {
  const user = useUser();
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const [reportsSelectedUserEmail] = usePersistentState('reportsSelectedUserEmail');

  return reportsSelectedUserEmail === '' || reportsSelectedUserEmail === user.email ? (
    <div className="profile-info__container">
      <div className="profile-info__header-container">
        <div>
          <Avatar fullName={displayName} variant="medium" />
        </div>
        <div>
          <div className="profile-info__player-name">{displayName}</div>
        </div>
        <div>
          <div className="profile-info__player-email">{user.email}</div>
        </div>
      </div>
      <div className="profile-info__player-company-role role-tag">
        <img src={roleIcon} alt="role icon" className="role-icon" />
        <span> Incident Manager </span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AnalyticsHeader;
