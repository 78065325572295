import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import PendingContent from '../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import { AppRoutes } from '../../../config/routes';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import ScenarioIntroductionRenderer, {
  mapScenarioToIntroduction,
} from '../components/ScenarioIntroductionRenderer';
import { useSelectedScenario } from '../hooks/useSelectedScenario';

const ScenarioIntroductionContainer: FunctionComponent = () => {
  const [loading, scenario, error] = useSelectedScenario();
  const goToRoute = useRedirectToRoute();
  const hasIntroduction = Boolean(
    scenario && mapScenarioToIntroduction[scenario.id],
  );

  if (scenario && !hasIntroduction) {
    goToRoute(AppRoutes.ScenarioLevels, {
      id: scenario.id,
    });
  }

  return (
    <div
      className="ScenariosContainer"
      data-testid="ScenarioIntroductionContainer"
    >
      <Helmet>
        <title> UptimeLabs</title>
      </Helmet>
      {!loading && scenario && (
        <Helmet>
          <title>{scenario.title} - UptimeLabs</title>
        </Helmet>
      )}
      <PendingContent
        data-testid="PendingContent"
        loading={loading}
        isError={Boolean(error)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
      >
        {scenario && (
          <div
            className="ScenariosContainer__introduction"
            data-testid="ScenarioIntroductionRenderer"
          >
            <ScenarioIntroductionRenderer scenario={scenario} step={0} />
          </div>
        )}
      </PendingContent>
    </div>
  );
};

export default ScenarioIntroductionContainer;
