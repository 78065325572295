const getUrlForIncidentManagement = (env: string) => {
  return `https://grafana.${env}.uptimelabs.io/d/aOf5yan4z/incident-management?orgId=1&refresh=10s`;
};

const getBaseUrl = (env: string) => {
  const baseUrl =
    env === 'prod' ? `https://learn.uptimelabs.io` : `https://learn.${env}.uptimelabs.io`;
  return baseUrl;
};

const getUrlForIncidentManagementOBMonitoring = (env: string) => {
  return `https://grafana.${env}.uptimelabs.io/d/pt36kbdnk1/hipster?orgId=1&refresh=10s`;
};

const getUrlForDashboard = (env: string) => {
  return `https://dashboard.${env}.uptimelabs.io`;
};

const getUrlForCloudShell = (env: string) => {
  return `https://cloud-shell.${env}.uptimelabs.io`;
};

const getUrlForOnlineBoutique = (env: string) => {
  return `https://hipster.${env}.uptimelabs.io`;
};

const getUrlForObDemo = (env: string) =>
  env === 'local'
    ? `https://hipster-shop.dev.uptimelabs.io`
    : `https://hipster-shop.${env}.uptimelabs.io`;

const getUrlForSlack = () => {
  return (
    window.envs.REACT_APP_SLACK_URL_TEMPLATE ||
    'https://id.dev.uptimelabs.io/realms/tenants/protocol/saml/clients/slack'
  );
};

export const getContentApiUrl = () => {
  const slackUrl =
    window.envs.REACT_APP_CMS_URL || 'https://content.dev.uptimelabs.io/wp-json/wp/v2';
  return slackUrl;
};

export const getBlogUrl = () => {
  const blogUrl =
    (window.envs.REACT_APP_CMS_URL ? `${window.envs.REACT_APP_CMS_URL}/posts` : null) ??
    'https://content.dev.uptimelabs.io/wp-json/wp/v2/posts';
  return blogUrl;
};

export type UrlsKeys =
  | 'getUrlForIncidentManagement'
  | 'getUrlForIncidentManagementOBMonitoring'
  | 'getUrlForDashboard'
  | 'getUrlForCloudShell'
  | 'getUrlForOnlineBoutique'
  | 'getUrlForSlack'
  | 'getUrlForObDemo'
  | 'getBaseUrl';

export const Urls: Record<UrlsKeys, (env: string) => string> = {
  getUrlForIncidentManagement,
  getUrlForIncidentManagementOBMonitoring,
  getUrlForDashboard,
  getUrlForCloudShell,
  getUrlForOnlineBoutique,
  getUrlForSlack,
  getUrlForObDemo,
  getBaseUrl,
};

export const ContentUrls = {
  getUrlForScenarios: (slug?: string) => (slug ? `/scenarios?slug=${slug}` : '/scenarios'),
  getUrlForMedia: (mediaId?: number) => (mediaId ? `/media/${mediaId}` : '/media'),
  getUrlForStaff: (id?: number) => (id ? `/people/${id}` : '/people'),
  getUrlForPage: (slug: string) => `/pages?slug=${slug}`,
  getUrlForLevel: (slug?: string) => (slug ? `/levels?slug=${slug}` : '/levels?per_page=30'),
  getUrlForPost: (slug?: string) => (slug ? `/slug=${slug}` : '/'),
};

export const ApiUrls = {
  getUrlForReports: (id?: number) => {
    const base = '/player/sessions';
    if (id) {
      return base + `/${id}`;
    }
    return base;
  },
  getUrlForUnapprovedReports: (email: string, id?: number) => {
    const base = `/admin/player/${email}/sessions`;
    if (id) {
      return base + `/${id}`;
    }
    return base;
  },
  getUrlForReportEvents: (reportId: number) => `/player/sessions/${reportId}/events`,
  getUrlForUnapprovedReportEvents: (email: string, reportId: number) =>
    `/admin/player/${email}/sessions/${reportId}/events`,
  getUrlForReportPlayerAssessment: (reportId: number) => `/player/sessions/${reportId}/assessment`,
  getUrlForUnapprovedReportPlayerAssessment: (email: string, reportId: number) =>
    `/admin/player/${email}/sessions/${reportId}/assessment`,
  getUrlForReportPlayerFeedback: (reportId: number) => `/player/sessions/${reportId}/feedback`,
  getUrlForUnapprovedReportPlayerFeedback: (email: string, reportId: number) =>
    `/admin/player/${email}/sessions/${reportId}/feedback`,
  getUrlForTogglingReports: (email: string, reportId: number) =>
    `/admin/player/${email}/sessions/${reportId}/toggle`,
  getUrlForReadyToPlay: () => `/drill-ready-to-play`,
  getUrlForSessionByEmail: (email: string) => `/player/session/${email}`,
  getUrlForAwards: () => `/award/certificates`,
  getUrlForGenerateCertificate: () => `/certificate-generator`,
  getUrlForLobbyCreation: () => `/lobby/create`,
  getUrlToFindLobbyByEmail: (player: string) => `/lobby/by-email/${player}`,
  getUrlForJoinLobby: () => `/lobby/join`,
  getUrlForCloseLobby: () => `/lobby/close`,
  getUrlForLeaveLobby: () => `/lobby/leave`,
  getUrlForTeamSessionCreation: () => `/session/team`,
  getUrlForSoloSessionCreation: () => `/session/solo`,
  getUrlForAnalytics: (email: string) => `/user_reports/player/${email}`,
  getUrlForProfilePersona: () => `/player/profile/persona/`,
};
