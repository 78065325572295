import { FunctionComponent } from 'react';

import { ScenarioMedia } from '../types';

type ScenarioMediaProps = {
  title: string;
  media: Partial<ScenarioMedia>;
};
const ScenarioMediaSection: FunctionComponent<ScenarioMediaProps> = ({ title, media }) => {
  return (
    <div className="ScenariosContainer__media">
      {media.imageUrl && (
        <img className="ScenariosContainer__image" src={media.imageUrl} alt={title} />
      )}
      {media.videoUrl && (
        <iframe
          width="500"
          height="360"
          src={media.videoUrl}
          style={{ border: 0 }}
          title={`${title} video`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default ScenarioMediaSection;
