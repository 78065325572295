import { Maybe, PlayerRoles } from '../features/common/types';
import { createPersistentState } from './core';

type InitialState = {
  audiosPlayed: string[];
  tourRan: boolean;
  selectedPlayerRole: Maybe<PlayerRoles>;
  reportsSelectedUserEmail: Maybe<string>;
};

const initialState: InitialState = {
  audiosPlayed: [],
  tourRan: false,
  selectedPlayerRole: undefined,
  reportsSelectedUserEmail: undefined,
};
const persistentStateWrapper = createPersistentState(initialState);

export const { usePersistentState, getAtom, initializePersistentState } = persistentStateWrapper;
export default persistentStateWrapper;
