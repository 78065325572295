import './analytics.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { MenuItem, Select } from '@mui/material';
import { axisBottom, axisLeft, format, max, scaleBand, scaleLinear, select } from 'd3';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AppRoutes } from '../../config/routes';
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import PlayerBarChartTooltip from './components/PlayerBarChartTooltip';
import { playerBarChartXAxis } from './components/PlayerBarChartXAxis';
import { StarsAndHints, StarsAndHintsDetails } from './types';

interface ChartData {
  date: string;
  sessionId: string;
  stars: StarsAndHintsDetails[];
  hints: StarsAndHintsDetails[];
  drillName: string;
  completionStatus?: string;
}

const transformData = (starsAndHints: StarsAndHints[], selectedCompetency: string) => {
  return starsAndHints.map((event) => {
    const filteredDetails =
      selectedCompetency === 'all'
        ? event.starsAndHintsDetails
        : event.starsAndHintsDetails.filter((detail) =>
            detail.competency.includes(selectedCompetency),
          );
    const stars = filteredDetails.filter((detail) => detail.type === 'star');
    const hints = filteredDetails.filter((detail) => detail.type === 'hint');

    return {
      date: event.date,
      sessionId: event.sessionId.toString(),
      stars,
      hints,
      drillName: event.drillName,
      completionStatus: event.completionStatus,
    };
  });
};

const PlayerBarChart: FC<{ starsAndHints: StarsAndHints[] }> = ({ starsAndHints }) => {
  const goToRoute = useRedirectToRoute();
  const competencies = useMemo(
    () =>
      Array.from(
        new Set(
          starsAndHints.flatMap((event) =>
            event.starsAndHintsDetails?.flatMap((detail) => detail.competency),
          ),
        ),
      ),
    [starsAndHints],
  );
  const goToReportPage = useCallback(
    (id: number) => {
      if (!window.envs.REACT_APP_ENV) {
        return;
      }
      goToRoute(AppRoutes.Report, {
        id: id,
      });
    },
    [goToRoute],
  );

  const [selectedCompetency, setSelectedCompetency] = useState('all');
  const chartData = useMemo(
    () => transformData(starsAndHints, selectedCompetency).reverse(),
    [starsAndHints, selectedCompetency],
  );

  const chartRef = useRef<SVGSVGElement | null>(null);

  const drawChart = useCallback(
    (data: ChartData[]) => {
      const svg = select(chartRef.current);

      const containerWidth = 1000;
      const barWidth = 140;
      const width = Math.max(barWidth * data.length, containerWidth);
      const height = 400;
      const margin = { top: 40, right: 20, bottom: 90, left: 60 };

      const xScale = scaleBand()
        .domain(data.map((d) => d.sessionId))
        .range([margin.left, width - margin.right])
        .padding(0.2);

      const yScale = scaleLinear()
        .domain([0, max(data, (d) => Math.max(d.stars.length, d.hints.length)) ?? 1])
        .nice()
        .range([height - margin.bottom, margin.top]);

      const yAxis = axisLeft(yScale)
        .ticks(yScale.domain()[1] / 2)
        .tickFormat(format('d'))
        .tickSizeOuter(0);

      const xAxis = axisBottom(xScale)
        .tickFormat(() => '')
        .tickSizeOuter(0);

      svg.selectAll('*').remove(); // Clear existing chart

      svg.attr('width', width).attr('height', height);

      svg
        .append('g')
        .attr('transform', `translate(${margin.left},0)`)
        .call(yAxis)
        .selectAll('text')
        .style('font-size', '14px')
        .style('fill', '#50525f');
      svg
        .append('text')
        .attr('transform', `rotate(-90)`)
        .attr('y', margin.left - 50)
        .attr('x', height / -1.5)
        .attr('dy', '1em')
        .style('font-family', 'Poppins')
        .style('font-size', '16px')
        .style('fill', '#262527')
        .style('font-weight', '600')
        .text('Number of Stars and Hints');

      svg
        .append('g')
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(xAxis)
        .selectAll('.tick line');

      playerBarChartXAxis(data, svg, goToReportPage);
      const renderBars = (selector: string, color: string, field: 'stars' | 'hints') => {
        svg
          .selectAll(`.bar-${selector}`)
          .data(data)
          .join('rect')
          .attr('class', `bar-${selector}`)
          .attr('x', (d) =>
            field === 'stars'
              ? xScale(d.sessionId)!
              : xScale(d.sessionId)! + xScale.bandwidth() / 2,
          )
          .attr('y', (d) => yScale(d[field].length))
          .attr('width', xScale.bandwidth() / 2.25)
          .attr('height', (d) => height - margin.bottom - yScale(d[field].length))
          .attr('fill', color)
          .attr('data-tooltip-id', (d) => `tooltip-${d.sessionId}-${selector}`)
          .attr('cursor', 'pointer');
      };

      renderBars('stars', '#26A69A', 'stars');
      renderBars('hints', '#0073EE', 'hints');
    },
    [goToReportPage],
  );

  useEffect(() => {
    if (chartData.length > 0) {
      drawChart(chartData);
    }
  }, [chartData, drawChart]);

  return (
    <div className="performance-barchart__container">
      <div className="performance-barchart__competency-dropdown">
        <Select value={selectedCompetency} onChange={(e) => setSelectedCompetency(e.target.value)}>
          <MenuItem value="all">All</MenuItem>
          {competencies.map((comp) => (
            <MenuItem key={comp} value={comp}>
              {comp}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="scrollable-container performance-barchart-and-tooltip">
        <svg ref={chartRef} className="performance-barchart"></svg>
        {chartData.map((d) => (
          <Fragment key={d.sessionId}>
            <PlayerBarChartTooltip
              sessionId={d.sessionId}
              date={d.date}
              type="stars"
              details={d.stars}
              completionStatus={d.completionStatus}
            />
            <PlayerBarChartTooltip
              sessionId={d.sessionId}
              date={d.date}
              type="hints"
              details={d.hints}
              completionStatus={d.completionStatus}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default PlayerBarChart;
