import cx from 'classnames';
import { any, oneOf } from 'prop-types';
import { JSX, ReactNode } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type BadgeProps = {
  type?: 'warning' | 'success' | 'danger' | 'info' | 'link';
  children?: ReactNode;
} & JSX.IntrinsicElements['span'];

type BadgeStyles = {
  root: string;
};

const Badge: AdjustableComponent<BadgeProps, BadgeStyles> = ({
  children,
  classes,
  type = 'warning',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('Badge', {
        [`Badge--${type}`]: true,
      }),
    },
    classes,
  );
  return (
    <span className={styles.root} {...rest}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  children: any,
  classes: any,
  type: oneOf(['warning', 'success', 'danger', 'info', 'link']),
};

export default Badge;
