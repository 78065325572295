import { any } from 'prop-types';
import { ReactNode, useContext } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import DropdownContext from './DropdownContext';

type DropdownToggleProps = {
  children?: ReactNode;
};
export type DropdownToggleStyles = {
  root: string;
};

const DropdownToggle: AdjustableComponent<DropdownToggleProps, DropdownToggleStyles> = (props) => {
  const context = useContext(DropdownContext);
  const classes = useClasses(
    {
      root: 'Dropdown__toggle',
    },
    props.classes,
  );

  return (
    <button type="button" className={classes.root} onClick={() => context.toggle()}>
      {props.children}
    </button>
  );
};

DropdownToggle.propTypes = {
  children: any,
  classes: any,
};

export default DropdownToggle;
