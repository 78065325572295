import { DependencyContainer } from '../../../../../DependencyContainer';
import {
  MqttTopics,
  PlayerSessionUpdatePayload,
} from '../../../../common/mqtt-topics';
import { CreateLobbyPayload, ExistingLobbyPayload } from '../../Lobby/types';
import { Session } from '../types';

export default class SessionService {
  constructor(protected readonly factory: DependencyContainer) {}

  private hydrateSessionWithInfo(session: Session): Session {
    return {
      ...session,
    };
  }

  async createSession(
    token: string,
    payload: ExistingLobbyPayload,
  ): Promise<Session> {
    try {
      const response = await this.factory.sessionClient.createSession(
        token,
        payload,
      );
      const data = response.data;
      console.debug('createSession data received');
      return this.hydrateSessionWithInfo(data);
    } catch (error) {
      console.error('createSession error: ', error);
      throw error;
    }
  }

  setSession(userId: string, payload: PlayerSessionUpdatePayload): () => void {
    const topic = MqttTopics.getGameStatusTopic(userId);
    this.factory.mqttService.publish({
      topic: topic,
      payload: {
        ...payload,
      },
    });
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  getSessionStatus(
    user_id: string,
    onMessage: (message: PlayerSessionUpdatePayload) => void,
  ): () => void {
    const topic = MqttTopics.getGameStatusTopic(user_id);
    this.factory.mqttService.subscribe(topic, onMessage);
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  async createSoloSession(
    token: string,
    payload: CreateLobbyPayload,
  ): Promise<Session> {
    try {
      const response = await this.factory.sessionClient.createSoloSession(
        token,
        payload,
      );
      const data = response.data;
      console.debug('createSoloSession data received');
      return this.hydrateSessionWithInfo(data);
    } catch (error) {
      console.error('createSoloSession error: ', error);
      throw error;
    }
  }
}
