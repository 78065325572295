import { detectIncognito } from 'detect-incognito';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Alert from '../components/Alert/Alert';
import Button, { ButtonStyles } from '../components/Button/Button';
import Topbar from '../components/Topbar/Topbar';
import { explanatoryVideoConfiguration } from '../config/config';
import VideoModal from '../features/common/components/VideoModal/VideoModal';
import { useContainerTitle } from '../features/common/hooks/useContainerTitle';
import { useUser } from '../features/common/hooks/useUser';
import { usePersistentState } from '../persistent-state/persistent-state';
import { isVideoModalOpenedAtom, userAtom } from '../state';

const addStylesToFooterButton = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} AppLayout__footer-link`,
});

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FunctionComponent<AppLayoutProps> = ({ children }) => {
  const getContainerTitle = useContainerTitle();
  const [isVideoModalOpened, setIsVideoModalOpened] = useRecoilState(isVideoModalOpenedAtom);
  const [, setSelectedPlayerRole] = usePersistentState('selectedPlayerRole');
  const [isIncognito, setIsIncognito] = useState(false);
  const user = useUser();
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    setUser(user);
    if (user.persona) {
      setSelectedPlayerRole(user.persona);
    }
  }, [setSelectedPlayerRole, setUser, user]);

  useEffect(() => {
    detectIncognito().then((result) => {
      setIsIncognito(result.isPrivate);
    });
  }, []);

  /**useEffect(() => {
    if (!user.persona && user.email && user.tenant != null) {
      setShowRoleSelectorModal(true);
    } else {
      setShowRoleSelectorModal(false);
    }
  }, [user.email, user.persona, user.tenant]);
*/
  return (
    <div className="AppLayout" data-testid="AppLayout">
      <Helmet>
        <title>UptimeLabs</title>
        <meta name="description" content="UptimeLabs Web App" />
      </Helmet>
      <Topbar pagetitle={getContainerTitle()} data-page-title={getContainerTitle()} />
      {isIncognito && (
        <Alert>
          <strong>Warning:</strong> You are running this app on incognito mode. Please switch to
          standard mode to experience full feature of our web application.
        </Alert>
      )}
      <div className="AppLayout__content" data-testid="AppLayout__content">
        {children}
      </div>
      <div className="AppLayout__footer" data-testid="AppLayout__footer">
        <p>&copy; UptimeLabs</p>
        <Button
          onClick={() => setIsVideoModalOpened(true)}
          size="small"
          variant="link"
          classes={addStylesToFooterButton}
          data-testid="how-it-works-button"
        >
          See how it works
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            // eslint-disable-next-line sonarjs/link-with-target-blank
            window.open('https://uptimelabs.canny.io/uptimelabs-feedback', '_blank');
          }}
          size="small"
          variant="link"
          classes={addStylesToFooterButton}
          data-testid="share-feedback-button"
        >
          Share Feedback
        </Button>
      </div>
      <VideoModal
        data-testid="VideoModal"
        video={explanatoryVideoConfiguration}
        isOpen={isVideoModalOpened}
        onRequestClose={() => setIsVideoModalOpened(false)}
      />
      {/**  
      <RoleSelectorModal
        data-testid="RoleSelectorModal"
        isOpen={showRoleSelectorModal}
        onRequestClose={() => setShowRoleSelectorModal(false)}
      />
        */}
    </div>
  );
};

export default AppLayout;
