import { FunctionComponent, useRef } from 'react';
import Confetti from 'react-confetti';
import { useRecoilValue } from 'recoil';

import Button from '../../../../components/Button/Button';
import { AppRoutes } from '../../../../config/routes';
import { gamePlayAtom } from '../../../../state';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';

const GamePlaySuccess: FunctionComponent = () => {
  const gamePlay = useRecoilValue(gamePlayAtom);
  const goToRoute = useRedirectToRoute();
  const gamePlayRef = useRef<HTMLDivElement>(null);

  const goToAllDrills = () => {
    goToRoute(AppRoutes.ScenarioLevels, { id: gamePlay.scenario });
  };

  const getConfettiWidth = () => {
    return gamePlayRef.current?.offsetWidth || window.innerWidth;
  };

  return (
    <div className="GamePlay" ref={gamePlayRef} data-testid="GamePlaySuccess">
      <Confetti
        recycle={false}
        run
        width={getConfettiWidth()}
        tweenDuration={4000}
      />
      <h3 className="ScenariosContainer__play-scenario-box-title">
        Congratulations, you completed the drill successfully.
      </h3>
      <div className="GamePlay__action-buttons">
        <Button onClick={() => goToAllDrills()}> Back to All Drills</Button>
        <Button
          className="Button Button--pulse-orange"
          size="small"
          onClick={() => goToRoute(AppRoutes.Reports)}
        >
          View Your Reports
        </Button>
      </div>
    </div>
  );
};

export default GamePlaySuccess;
