import { FC } from 'react';

export const HandlesDayToDayIssues: FC = () => {
  return (
    <div className="skills-radarchart__role-card">
      <div className="role-content">
        <div className="role-title">Grade 0 - Practitioner</div>
        <div className="role-description">Handles day-to-day issues.</div>
      </div>
    </div>
  );
};
