import axios from 'axios';

import { getContentApiUrl } from './config/urls';

export default axios.create({
  baseURL: getContentApiUrl(),
  timeout: 5000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});
