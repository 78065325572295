import { FunctionComponent } from 'react';
import Joyride from 'react-joyride';
import { useRecoilValue } from 'recoil';

import AudioPlayer, { AudioPlayerStyles } from '../../../../../components/AudioPlayer/AudioPlayer';
import HeroStat from '../../../../../components/HeroStat/HeroStat';
import Table, { TableStyles } from '../../../../../components/Table/Table';
import TableBody from '../../../../../components/Table/TableBody';
import TableColumn from '../../../../../components/Table/TableColumn';
import TableRow from '../../../../../components/Table/TableRow';
import { joyrideStyles } from '../../../../../config/config';
import { Features } from '../../../../../config/features';
import { usePersistentState } from '../../../../../persistent-state/persistent-state';
import { scenarioAtom } from '../../../../../state';
import { useFeatureFlag } from '../../../../common/hooks/useFeatureFlag';

const steps = [
  {
    target: '.OnlineBoutiqueIntroduction__audio-player',
    content: 'Here you can control the audio being played in the background.',
  },
  {
    target: '.OnlineBoutiqueIntroduction__stats',
    content: 'The most valuable information',
  },
  {
    target: '.OnlineBoutiqueIntroduction__row--details',
    content: 'The rest of information about the project',
  },
  {
    target: '.IntroductionPlayer__actions',
    content:
      'You can control the scenario introduction using these buttons. You can also skip totally the introduction and start playing.',
  },
];

const OnlineBoutiqueIntroductionStep1: FunctionComponent = () => {
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const scenario = useRecoilValue(scenarioAtom);

  const tableDescriptor = [
    {
      name: 'Founded',
      value: scenario?.content?.foundation_date,
    },
    {
      name: 'Based',
      value: scenario?.content?.headquarters,
    },
    {
      name: 'Founder',
      value: scenario?.content?.founderData?.name || '',
    },
  ];

  return (
    <div className="OnlineBoutiqueIntroduction__wrapper">
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          continuous
          run={!tourRan}
          scrollToFirstStep
          showSkipButton
          styles={joyrideStyles}
        />
      )}
      <AudioPlayer
        source="/audio/bez_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      {scenario && (
        <>
          <div className="OnlineBoutiqueIntroduction__stats">
            <div className="OnlineBoutiqueIntroduction__stat">
              <HeroStat value={scenario.content?.annual_revenue} label="Annual revenue" />
            </div>
            <div className="OnlineBoutiqueIntroduction__stat">
              <HeroStat
                value={`£${scenario.outageCostPerMinute}`}
                label="Outage cost per minute"
                variant="danger"
              />
            </div>
            <div className="OnlineBoutiqueIntroduction__stat">
              <HeroStat value={scenario.content?.employees} label="Employees" />
            </div>
          </div>
          <div className="OnlineBoutiqueIntroduction__content">
            <div className="OnlineBoutiqueIntroduction__row OnlineBoutiqueIntroduction__row--details">
              <img
                className="OnlineBoutiqueIntroduction__image--small"
                src={scenario.content?.founderData.imageUrl}
                alt="Bez Jeffos"
              />
              <div className="OnlineBoutiqueIntroduction__right">
                <Table
                  classes={(current: TableStyles) => ({
                    ...current,
                    root: `${current.root} OnlineBoutiqueIntroduction__table`,
                  })}
                >
                  <TableBody>
                    {tableDescriptor.map((item) => (
                      <TableRow key={item.name} data-testid="TableRow">
                        <TableColumn>{item.name}</TableColumn>
                        <TableColumn>{item.value}</TableColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div
              className="OnlineBoutiqueIntroduction__description"
              dangerouslySetInnerHTML={{
                __html: scenario.content?.description,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OnlineBoutiqueIntroductionStep1;
