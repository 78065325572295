import cx from 'classnames';
import { JSX } from 'react';
import Joyride from 'react-joyride';

import { joyrideStyles } from '../../config/config';
import { Features } from '../../config/features';
import { AdjustableComponentType, useClasses } from '../../features/common/hooks/useClasses';
import { useFeatureFlag } from '../../features/common/hooks/useFeatureFlag';
import { useMedia } from '../../features/common/hooks/useMedia';
import { usePersistentState } from '../../persistent-state/persistent-state';
import Table from './Table';
import TableBody from './TableBody';
import TableColumn from './TableColumn';
import TableHead from './TableHead';
import TableHeadColumn from './TableHeadColumn';
import TableRow from './TableRow';

type TableCell = JSX.Element | string | number | JSX.Element[];

export type HeadingRow<D> = {
  name?: string;
  fieldName?: string;
  render?: (row: D) => TableCell;
};

type DataRow<D> = D;

type TableResponsive<D> = {
  headingRows: Array<HeadingRow<D>>;
  dataRows: Array<DataRow<D>>;
};

type TableStyles = {
  root: string;
};

/**
 * ResponsiveTable component renders a table that adjusts based on screen size.
 * @template T
 * @param {AdjustableComponentType<TableResponsive<T>, TableStyles>} props - The properties for the table component.
 * @returns {JSX.Element} The rendered table component.
 */
export default function ResponsiveTable<T>(
  props: AdjustableComponentType<TableResponsive<T>, TableStyles>,
): JSX.Element {
  const { sizes } = useMedia();
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const styles = useClasses(
    {
      root: cx({
        'Table--small-screen': sizes.small,
        'Table--medium-screen': sizes.medium,
        'Table--large-screen': sizes.large,
      }),
    },
    props.classes,
  );

  const renderCell = (row: T, heading?: HeadingRow<T>) => {
    if (heading === undefined) {
      return '';
    }
    if (heading.render) {
      return heading.render(row);
    }
    // @ts-expect-error heading is not undefined??
    return row[heading.fieldName];
  };

  const steps = [
    {
      target: '.Button--secondary',
      content: 'Click here to begin your journey with our scenario.',
      placement: 'top' as const,
    },
  ];

  if (sizes.small) {
    return (
      <>
        {isOnBoardingAvailable() && (
          <Joyride
            steps={steps}
            stepIndex={0}
            continuous
            run={!tourRan}
            scrollToFirstStep
            styles={joyrideStyles}
          />
        )}
        {props.dataRows.map((data, dataIndex) => (
          <Table
            key={`table-${dataIndex}`}
            data-testid="Table"
            classes={(current: TableStyles) => ({
              ...current,
              root: `${current.root} ${styles.root}`,
            })}
          >
            <TableBody data-testid="Table__body">
              {props.headingRows.map((heading, headingIndex) => (
                <TableRow key={`row-${dataIndex}-${headingIndex}`} data-testid="TableRow">
                  <TableColumn key={`column-name-${dataIndex}-${headingIndex}`}>
                    {heading.name}
                  </TableColumn>
                  <TableColumn key={`column-cell-${dataIndex}-${headingIndex}`}>
                    {renderCell(data, heading)}
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </>
    );
  }

  return (
    <>
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          continuous
          run={!tourRan}
          scrollToFirstStep
          styles={joyrideStyles}
        />
      )}
      <Table
        classes={(current: TableStyles) => ({
          ...current,
          root: `${current.root} ${styles.root}`,
        })}
      >
        <TableHead>
          {props.headingRows.map((headingRow) => (
            <TableHeadColumn key={headingRow.name}>{headingRow.name}</TableHeadColumn>
          ))}
        </TableHead>
        <TableBody>
          {props.dataRows.map((data: DataRow<T>, index: number) => (
            <TableRow key={index} data-testid="TableRow">
              {props.headingRows.map((heading: HeadingRow<T>) => (
                <TableColumn key={`${heading.fieldName}-${index}`}>
                  {renderCell(data, heading)}
                </TableColumn>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
