import { FC } from 'react';

import IncidentManager from './incident-manager';

const OnlineBoutiqueStep5: FC = () => {
  return (
    <div className="__role-intro">
      <IncidentManager />
    </div>
  );
};

export default OnlineBoutiqueStep5;
