/* eslint-disable @typescript-eslint/no-explicit-any */
import { DependencyContainer } from '../../../DependencyContainer';

export default class AnalyticsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getPlayerStarsData(token: string, playerEmail: string): Promise<any[]> {
    const response = await this.factory.analyticsClient.getPlayerStarsData(token, playerEmail);
    return response.data;
  }
}
