import './analytics.scss';

import { format } from 'date-fns';
import { FC } from 'react';

import hintIcon from '../../assets/icons/icons_hints_24.svg';
import starIcon from '../../assets/icons/icons_stars_24.svg';
import Button from '../../components/Button/Button';
import { AppRoutes } from '../../config/routes';
import { DependencyContainer } from '../../DependencyContainer';
import { useFetchData } from '../common/hooks/useFetchData';
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import { useUser } from '../common/hooks/useUser';
import { Feedback } from '../reports/types';
import { StarsAndHints } from './types';

const { reportsService } = DependencyContainer.getInstance();
interface PlayerFeedbackContainerProps {
  allSessions: StarsAndHints[];
  token: string;
}

const PlayerFeedbackContainer: FC<PlayerFeedbackContainerProps> = ({
  allSessions,
  token,
}) => {
  const userEmail = useUser().email;
  const redirectToRoute = useRedirectToRoute();
  const sessionId = allSessions[allSessions.length - 1]?.sessionId;
  const sessionDate =
    allSessions.length > 0
      ? format(
          new Date(allSessions[allSessions.length - 1].date),
          'MMMM dd, yyyy',
        )
      : 'No session date available';
  const [loading, feedback, error] = useFetchData<Feedback[], Error>(
    async () => {
      if (sessionId) {
        return await reportsService.getPlayerFeedback(
          sessionId,
          token,
          userEmail,
        );
      }
      return [];
    },
    undefined,
    [sessionId, token, userEmail],
  );

  const fallbackMessage = 'No feedback available yet, come back later.';
  const goToReportPage = (id: number) => {
    if (!window.envs.REACT_APP_ENV) {
      return;
    }
    redirectToRoute(AppRoutes.Report, {
      id: id,
    });
  };

  if (error) {
    return (
      <div>
        <p>
          We&apos;re having trouble fetching your latest feedback, please check
          again later.
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <p>Loading feedback...</p>
      </div>
    );
  }

  const strengths =
    feedback?.filter(
      (feedbackItem) =>
        feedbackItem.type === 'Positive' && feedbackItem.from_uptimelabs,
    ) || [];
  const improvements =
    feedback?.filter(
      (feedbackItem) =>
        feedbackItem.type === 'Negative' && feedbackItem.from_uptimelabs,
    ) || [];

  if (!allSessions.length) {
    return <div>No session data available.</div>;
  }

  return (
    <>
      <span className="charts__subtitle"> {sessionDate} </span>
      <div className="feedback-container">
        <div className="feedback-body">
          <div className="feedback-card strengths">
            <div className="feedback-card-header">
              <img src={starIcon} alt="star Icon" className="tooltip-icon" />
              <div className="card-title">Strengths</div>
            </div>
            <div className="card-content">
              {strengths?.length ? (
                strengths.map((item, index) => <p key={index}>{item.body}</p>)
              ) : (
                <p>{fallbackMessage}</p>
              )}
            </div>
          </div>
          <div className="feedback-card improvements">
            <div className="feedback-card-header">
              <img src={hintIcon} alt="hint Icon" className="tooltip-icon" />
              <div className="card-title">Where to Improve</div>
            </div>
            <div className="card-content">
              {improvements?.length ? (
                improvements.map((item, index) => (
                  <p key={index}>{item.body}</p>
                ))
              ) : (
                <p>{fallbackMessage}</p>
              )}
            </div>
          </div>
        </div>
        <div className="feedback-actions">
          <Button
            size="small"
            variant="secondary"
            className="reports-action-button"
            onClick={() => goToReportPage(sessionId)}
          >
            Learn More
          </Button>
        </div>
        {/* TO DO: */}
        {/* Previous button */}
      </div>
    </>
  );
};

export default PlayerFeedbackContainer;
