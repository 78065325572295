import { FC } from 'react';

export const CoordinatesResponsesToIncidents: FC = () => {
  return (
    <div className="skills-radarchart__role-card">
      <div className="role-content">
        <div className="role-title">Grade 1 - Associate </div>
        <div className="role-description">
          Coordinates responses to incidents.
        </div>
      </div>
    </div>
  );
};
