import { ReactElement } from 'react';

import Certificates from '../Achievements/Certificates';
import Profile from '../Profile/Profile';
import PlayerEmail from '../Reports/PlayerEmail';
import Reports from '../Reports/Reports';
import Support from '../Support/Support';

export type DashboardTab = {
  content: ReactElement;
  isVisible: boolean;
  labelMessage: string;
  slug:
    | 'profile'
    | 'reports'
    | 'achievements'
    | 'support'
    | 'playerEmail'
    | 'logout';
};

interface User {
  isAdmin: boolean;
}

interface DashboardTabsProps {
  user: User;
  reportsSelectedUserEmail: string | null;
}

const DashboardTabs = ({
  user,
  reportsSelectedUserEmail,
}: DashboardTabsProps): Array<DashboardTab> => {
  const tabs: Array<DashboardTab> = [
    {
      content: <Profile />,
      isVisible: true,
      labelMessage: 'Profile',
      slug: 'profile',
    },
    {
      content: <Reports />,
      isVisible: true,
      labelMessage: 'Reports',
      slug: 'reports',
    },
    {
      content: <Certificates />,
      isVisible: true,
      labelMessage: 'Achievements',
      slug: 'achievements',
    },
    {
      content: <Support />,
      isVisible: true,
      labelMessage: 'Support',
      slug: 'support',
    },
    {
      content: <PlayerEmail />,
      isVisible: user?.isAdmin,
      labelMessage: reportsSelectedUserEmail
        ? 'Reports for ' + reportsSelectedUserEmail
        : 'Player email',
      slug: 'playerEmail',
    },
    {
      content: <></>,
      isVisible: true,
      labelMessage: 'Logout',
      slug: 'logout',
    },
  ];

  return tabs;
};

export default DashboardTabs;
