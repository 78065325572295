import { useMediaQuery } from 'react-responsive';

import { GLOBAL_MEDIA_QUERIES } from '../../../config/config';

export const useMedia = () => {
  const small = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.small });
  const medium = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.medium });
  const large = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.medium });

  const sizes = {
    small,
    medium,
    large,
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );

  return { sizes, vw };
};
