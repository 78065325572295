import { useEffect, useState } from 'react';

import { DependencyContainer } from '../../../DependencyContainer';
import { Player, RadarChartData, SessionEvents, StarsAndHints } from '../../analytics/types';
import { useBearerToken } from './useBearerToken';
import { useUser } from './useUser';

const useFetchPlayerData = (playerEmail: string) => {
  const token = useBearerToken() ?? '';
  const [player, setPlayer] = useState<Player | null>(null);
  const user = useUser();
  const [radarChartData, setRadarChartData] = useState<RadarChartData[]>([]);
  const [starsAndHints, setStarsAndHints] = useState<SessionEvents['starsAndHints']>([]);
  const [hintCount, setHintCount] = useState(0);
  const [starCount, setStarCount] = useState(0);
  const [previousMonthStats, setPreviousMonthStats] = useState({
    stars: 0,
    hints: 0,
    drills: 0,
  });
  const [currentMonthStats, setCurrentMonthStats] = useState({
    stars: 0,
    hints: 0,
    drills: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const filterStars = (
      acc: { [key: string]: { stars: number; hints: number; drills: number } },
      month: string,
      session: StarsAndHints,
    ) => {
      acc[month].stars += session.starsAndHintsDetails.filter((d) => d.type === 'star').length;
    };

    const filterHints = (
      acc: { [key: string]: { stars: number; hints: number; drills: number } },
      month: string,
      session: StarsAndHints,
    ) => {
      acc[month].hints += session.starsAndHintsDetails.filter((d) => d.type === 'hint').length;
    };

    const { analyticsService } = DependencyContainer.getInstance();
    const fetchData = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await analyticsService.getPlayerStarsData(token, playerEmail);
        if (response?.data?.analytics) {
          const {
            player,
            radarChart,
            sessionEvents,
          }: {
            player: Player;
            radarChart: RadarChartData[];
            sessionEvents: SessionEvents;
          } = response.data.analytics || {};
          const allDetails = sessionEvents.starsAndHints.flatMap(
            (event) => event.starsAndHintsDetails,
          );
          const totalStars = allDetails.filter(
            (detail: { type: string }) => detail.type === 'star',
          ).length;
          const totalHints = allDetails.filter(
            (detail: { type: string }) => detail.type === 'hint',
          ).length;

          setPlayer(player);
          setRadarChartData(radarChart);
          setStarsAndHints(sessionEvents.starsAndHints);
          setHintCount(totalHints);
          setStarCount(totalStars);

          let sessionDataByMonth: {
            [key: string]: { stars: number; hints: number; drills: number };
          } = {};
          try {
            sessionDataByMonth = sessionEvents.starsAndHints.reduce(
              (
                acc: {
                  [key: string]: {
                    stars: number;
                    hints: number;
                    drills: number;
                  };
                },
                session: StarsAndHints,
              ) => {
                const month = session.date.slice(0, 7); // Extract YYYY-MM
                if (!acc[month]) acc[month] = { stars: 0, hints: 0, drills: 0 };

                filterStars(acc, month, session);
                filterHints(acc, month, session);
                acc[month].drills += 1;
                return acc;
              },
              {},
            );
          } catch (error) {
            console.error('Error processing session data by month:', error);
          }

          const currentDate = new Date();
          const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
          const previousMonthDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1,
            1,
          );
          const previousMonth = `${previousMonthDate.getFullYear()}-${String(previousMonthDate.getMonth() + 1).padStart(2, '0')}`;

          if (sessionDataByMonth[previousMonth] && sessionDataByMonth[currentMonth]) {
            setPreviousMonthStats(sessionDataByMonth[previousMonth]);
            setCurrentMonthStats(sessionDataByMonth[currentMonth]);
          } else {
            setPreviousMonthStats({ stars: 0, hints: 0, drills: 0 });
            setCurrentMonthStats({ stars: 0, hints: 0, drills: 0 });
          }
        } else {
          setPlayer({
            email: user.email,
            numberOfDrills: 0,
            firstName: user.name!,
            lastName: user.surname!,
          });
          setHintCount(0);
          setStarCount(0);
        }
      } catch (error) {
        console.error('Error fetching player data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (playerEmail) {
      fetchData();
    }
  }, [playerEmail, token, user.email, user.name, user.surname]);

  return {
    player,
    radarChartData,
    starsAndHints,
    hintCount,
    starCount,
    previousMonthStats,
    currentMonthStats,
    loading,
  };
};

export default useFetchPlayerData;
