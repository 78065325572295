import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';

import Button from '../../../../components/Button/Button';
import { AppRoutes } from '../../../../config/routes';
import { gamePlayAtom } from '../../../../state';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';

const GamePlayFailure: FunctionComponent = () => {
  const gamePlay = useRecoilValue(gamePlayAtom);
  const goToRoute = useRedirectToRoute();

  const goToLevelSelection = () => {
    if (!gamePlay.scenario) {
      return;
    }
    goToRoute(AppRoutes.ScenarioLevels, {
      id: gamePlay.scenario,
    });
  };

  return (
    <div className="GamePlay" data-testid="GamePlayFailure">
      <h3 className="ScenariosContainer__play-scenario-box-title">
        You haven&apos;t completed this drill successfully, but don&apos;t
        worry, practice makes perfect!
      </h3>

      <div className="GamePlay__action-buttons">
        <Button size="small" onClick={() => goToLevelSelection()}>
          Go To Drill Selection
        </Button>
        <Button
          className="Button Button--pulse-orange"
          size="small"
          onClick={() => goToRoute(AppRoutes.Reports)}
        >
          View Your Reports
        </Button>
      </div>
    </div>
  );
};

export default GamePlayFailure;
