import { any } from 'prop-types';
import { ReactNode } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type ModalBodyStyles = {
  root: string;
};

const ModalBody: AdjustableComponent<{ children?: ReactNode }, ModalBodyStyles> = (props) => {
  const styles = useClasses<ModalBodyStyles>(
    {
      root: 'Modal__body',
    },
    props.classes,
  );

  return (
    <div className={styles.root} data-testid="Modal__body">
      {props.children}
    </div>
  );
};

ModalBody.propTypes = {
  children: any,
  classes: any,
};

export default ModalBody;
