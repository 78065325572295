import { useFlags } from 'launchdarkly-react-client-sdk';
import { FunctionComponent, useEffect, useState } from 'react';

import { DependencyContainer } from '../../DependencyContainer';
import { Post } from '../content/types';
import PostList from './PostList';

const LearningCenter: FunctionComponent = () => {
  const { postsService } = DependencyContainer.getInstance();
  const [posts, setPosts] = useState<Post[]>([]);
  const { testingConnection2 } = useFlags();
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await postsService.getPosts();
        if (typeof fetchedPosts !== 'undefined') {
          setPosts(fetchedPosts);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [postsService]);

  if (testingConnection2) {
    return <div>Testing Connection 2</div>;
  } else {
    return (
      <div className="learning-center">
        <h1>Learning Centre</h1>
        <p className="learning-center-intro-container">
          In between drills? check out the posts below to boost your learning
        </p>
        <PostList posts={posts} />
      </div>
    );
  }
};

export default LearningCenter;
