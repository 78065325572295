import { any, element, oneOfType, shape, string } from 'prop-types';
import { JSX, ReactElement } from 'react';

import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { useUser } from '../../../common/hooks/useUser';

export type StaffCardPerson = {
  name: string;
  position: string;
  image: string | ReactElement;
  description: string;
  deutschebankposition?: string | null;
  onPersonSelected?: (person: StaffCardPerson) => void;
};

type StaffCardProps = {
  person: StaffCardPerson;
  onPersonSelected?: (person: StaffCardPerson) => void;
  classes?: Partial<StaffCardStyles>;
} & JSX.IntrinsicElements['div'];

type StaffCardStyles = {
  root: string;
  people: string;
  person: string;
  fullName: string;
  position: string;
  image: string;
  deutschebankposition?: string;
};

const StaffCard: AdjustableComponent<StaffCardProps, StaffCardStyles> = ({
  classes,
  person,
  onPersonSelected,
  ...rest
}) => {
  const styles = useClasses(
    {
      root: 'StaffCard',
      fullName: 'StaffCard__full-name',
      position: 'StaffCard__position',
      people: 'StaffCard__people',
      person: 'StaffCard__person',
      image: 'StaffCard__image',
      deutschebankposition: 'StaffCard__position',
    },
    classes,
  );

  const deutscheBankPlayer = useUser().roles.includes('tenant-deutschebank');

  const onPersonClick = (person: StaffCardPerson) => {
    onPersonSelected?.(person);
  };

  return (
    <div className={styles.root} {...rest}>
      <button
        onClick={() => onPersonClick(person)}
        className={styles.person}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') onPersonClick(person);
        }}
      >
        <div className={styles.image}>
          {typeof person.image === 'string' ? (
            <img src={person.image} alt={person.name} />
          ) : (
            person.image
          )}
        </div>
        <h2 className={styles.fullName}>{person.name}</h2>
        <p className={styles.position}>
          {deutscheBankPlayer ? (person.deutschebankposition ?? person.position) : person.position}
        </p>
      </button>
    </div>
  );
};

StaffCard.propTypes = {
  classes: any,
  onPersonSelected: any,
  person: shape({
    name: string.isRequired,
    position: string.isRequired,
    image: oneOfType([string, element]).isRequired,
    description: string.isRequired,
    deutschebankposition: string,
    onPersonSelected: any.isRequired,
  }).isRequired,
};

export default StaffCard;
