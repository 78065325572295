import { AxiosResponse } from 'axios';

import { ApiUrls } from '../../../config/urls';
import { DependencyContainer } from '../../../DependencyContainer';
import { HttpClient } from '../../../HttpClient';

const configOverride = {
  baseURL: window.envs.REACT_APP_API_URL,
};

export default class AnalyticsClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  async getPlayerStarsData(
    token: string,
    playerEmail: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<any[]>> {
    this.setToken(token);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.get<any[]>(ApiUrls.getUrlForAnalytics(playerEmail), configOverride);
  }
}
