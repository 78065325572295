import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';

import {
  gamePlayStatusSelector,
  gamePlayTimeLeftSelector,
  GameStatus,
} from '../../../../state';

const mapCopyToStatus: Record<string, string> = {
  [GameStatus.Playing]: 'Your scenario has started',
  [GameStatus.Paused]: 'Your scenario is paused',
};

const GamePlayPlaying: FunctionComponent = () => {
  const timeLeft = useRecoilValue(gamePlayTimeLeftSelector);
  const status = useRecoilValue(gamePlayStatusSelector);

  return (
    <div className="GamePlay" data-testid="GamePlayPlaying">
      <h3 className="ScenariosContainer__play-scenario-box-title">
        {mapCopyToStatus[status] || mapCopyToStatus[0]}
      </h3>
      <h2 className="ScenariosContainer__play-scenario-box-counter">
        {timeLeft}
      </h2>
    </div>
  );
};

export default GamePlayPlaying;
