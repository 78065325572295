import { FC } from 'react';

export const PlayerLevel: FC = () => {
  return (
    <div className="skills-radarchart__legend-details">
      <div className="key-color-1"></div>
      <div className="skills-radarchart__heading">Player Level</div>
    </div>
  );
};
