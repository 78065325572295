import { FunctionComponent, ReactNode } from 'react';

interface TableHeadProps {
  children?: ReactNode;
}

const TableHead: FunctionComponent<TableHeadProps> = ({ children }) => {
  return (
    <thead className="Table__head" data-testid="TableHead">
      <tr>{children}</tr>
    </thead>
  );
};

export default TableHead;
