import { Buffer } from 'buffer';
// eslint-disable-next-line import/named
import mqtt, { MqttClient, MqttProtocol } from 'mqtt';

import { DependencyContainer } from '../../../DependencyContainer';
import { MqttPublishParams } from '../mqtt-topics';
import { Maybe } from '../types';
interface MqttServiceProtocol {
  publish(params: MqttPublishParams, onSuccess?: () => void): void;
}

export default class MqttService implements MqttServiceProtocol {
  private client: Maybe<MqttClient>;

  constructor(private readonly factory: DependencyContainer) {
    this.connect();
  }

  connect() {
    if (this.client) {
      return;
    }
    this.client = mqtt.connect({
      username: window.envs.REACT_APP_MQTT_USERNAME,
      password: window.envs.REACT_APP_MQTT_PASSWORD,
      port: Number(window.envs.REACT_APP_MQTT_PORT),
      protocol: window.envs.REACT_APP_MQTT_PROTOCOL as MqttProtocol,
      hostname: window.envs.REACT_APP_MQTT_HOST,
      path: window.envs.REACT_APP_MQTT_PATH,
    });
    this.client.on('error', (error: Error) => {
      console.error('Mqtt Error', error);
    });
  }

  subscribe<M>(topicName: string, onUpdate: (message: M) => void) {
    if (!this.client) {
      console.log('Cannot subscribe, MQTT client is not connected');
      return;
    }

    this.client.subscribe(topicName, (error: Error | null) => {
      if (error) {
        console.error('Subscription error:', error);
      }
    });

    this.client.on('message', (topic: string, message: Buffer) => {
      if (topic === topicName) {
        const parsedMessage = JSON.parse(message.toString());
        onUpdate(parsedMessage);
      }
    });
  }

  unsubscribe(topicName: string) {
    if (!this.client) {
      return;
    }
    this.client.unsubscribe(topicName, () => {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish(params: MqttPublishParams | any, onSuccess?: () => void) {
    if (!this.client) {
      return;
    }
    const { topic, payload } = params;
    this.client.publish(topic, JSON.stringify(payload), () => {
      onSuccess?.();
    });
  }

  end() {
    if (!this.client) {
      return;
    }
    this.client.end();
  }
}
