import { useKeycloak } from '@react-keycloak/web';
import { FunctionComponent, ReactNode } from 'react';

interface ForAuthenticatedProps {
  children: ReactNode;
}

const ForAuthenticated: FunctionComponent<ForAuthenticatedProps> = ({
  children,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return null;
  }

  if (initialized && !isLoggedIn) {
    keycloak.login();
  }

  return <>{isLoggedIn ? children : null}</>;
};

export default ForAuthenticated;
