import { useKeycloak } from '@react-keycloak/web';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FunctionComponent, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import Avatar, { AvatarStyles } from '../../../../components/Avatar/Avatar';
import Badge from '../../../../components/Badge/Badge';
import Dropdown, { DropdownStyles } from '../../../../components/Dropdown/Dropdown';
import DropdownContent, {
  DropdownContentStyles,
} from '../../../../components/Dropdown/DropdownContent';
import DropdownToggle, {
  DropdownToggleStyles,
} from '../../../../components/Dropdown/DropdownToggle';
import DropdownWindow, {
  DropdownWindowStyles,
} from '../../../../components/Dropdown/DropdownWindow';
import { ChevronDownIcon, EmptyCircleIcon, TickCircleIcon } from '../../../../components/Icons';
import { availablePlayerRoles } from '../../../../config/config';
import { AppRoutes } from '../../../../config/routes';
import { DependencyContainer } from '../../../../DependencyContainer';
import { usePersistentState } from '../../../../persistent-state/persistent-state';
import { isVideoModalOpenedAtom } from '../../../../state';
import { getDisplayNameFromToken, makeTextExcerpt } from '../../helpers/text.helper';
import { useBearerToken } from '../../hooks/useBearerToken';
import { useRedirectToRoute } from '../../hooks/useRedirectToRoute';
import { useUser } from '../../hooks/useUser';
import { PlayerRoles } from '../../types';

const addStylesToAvatar = (current: AvatarStyles) => ({
  ...current,
  root: `${current.root} AccountDropdown__avatar`,
});

const { playerService } = DependencyContainer.getInstance();

const AccountDropdown: FunctionComponent = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const redirectToRoute = useRedirectToRoute();
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const user = useUser();
  const [selectedPlayerRole, setSelectedPlayerRole] = usePersistentState('selectedPlayerRole');
  const setIsVideoModalOpened = useSetRecoilState(isVideoModalOpenedAtom);
  const { enable_mqtt = true } = useFlags();
  const token = useBearerToken();
  const toggleDropdown = () => {
    setShowDropdown((d) => !d);
  };

  const goToAccountPage = () => {
    redirectToRoute(AppRoutes.Account);
    setShowDropdown(false);
  };
  const goToReports = () => {
    redirectToRoute(AppRoutes.Reports);
    setShowDropdown(false);
  };

  const getSelectedPlayerRoleLabel = () => {
    return availablePlayerRoles.find((r) => r.name === selectedPlayerRole)?.label ?? '';
  };

  const onSelectRole = async (role: PlayerRoles) => {
    if (user.email) {
      if (enable_mqtt) {
        playerService.savePlayerRole(user.email, role);
      } else {
        playerService.savePlayerRoleApi(token ?? '', role);
      }
    }
    setSelectedPlayerRole(role);
    setShowDropdown(false);
  };

  const onSelectHowItWorks = () => {
    setShowDropdown(false);
    setIsVideoModalOpened(true);
  };

  const isRoleSelected = (role: PlayerRoles) => {
    return selectedPlayerRole === role;
  };

  const onLogout = async () => {
    setShowDropdown(false);
    await keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <Dropdown
      isOpen={showDropdown}
      toggle={toggleDropdown}
      classes={(current: DropdownStyles) => ({
        ...current,
        root: `${current.root} AccountDropdown ${showDropdown ? 'AccountDropdown--open' : ''}`,
      })}
    >
      <DropdownToggle
        classes={(current: DropdownToggleStyles) => ({
          ...current,
          root: `${current.root} AccountDropdown__toggle`,
        })}
      >
        <Avatar onClick={goToAccountPage} fullName={displayName} classes={addStylesToAvatar} />
        <div className="AccountDropdown__toggle-text">
          Playing as <span> {getSelectedPlayerRoleLabel()} </span>
        </div>
        <ChevronDownIcon className="AccountDropdown__chevron" />
      </DropdownToggle>
      <DropdownWindow
        classes={(current: DropdownWindowStyles) => ({
          ...current,
          root: `${current.root} AccountDropdown__window`,
        })}
      >
        <DropdownContent
          classes={(current: DropdownContentStyles) => ({
            ...current,
            root: `${current.root} AccountDropdown__content`,
          })}
        >
          <ul className="AccountDropdown__list">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li
              className="AccountDropdown__list-item AccountDropdown__list-item--account"
              onClick={() => goToAccountPage()}
            >
              <Avatar
                onClick={() => redirectToRoute(AppRoutes.Account)}
                fullName={displayName}
                classes={addStylesToAvatar}
              />
              <div className="AccountDropdown__list-item-right">
                {makeTextExcerpt(displayName, 18)}
                <small>Playing as {getSelectedPlayerRoleLabel()}</small>
              </div>
            </li>
          </ul>
          <small className="AccountDropdown__list-label">ACCOUNT</small>
          <ul className="AccountDropdown__list">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li className="AccountDropdown__list-item" onClick={() => goToAccountPage()}>
              My profile
            </li>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li
              className="AccountDropdown__list-item AccountDropdown__list-item--reports"
              onClick={() => goToReports()}
            >
              Reports
            </li>
          </ul>
          <small className="AccountDropdown__list-label">SELECT ROLE</small>
          <ul className="AccountDropdown__list">
            {availablePlayerRoles.map((role) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <li
                key={role.name}
                className="AccountDropdown__list-item"
                onClick={() => onSelectRole(role.name)}
              >
                {role.label}
                {isRoleSelected(role.name) ? (
                  <TickCircleIcon className="AccountDropdown__list-item-icon" />
                ) : (
                  <EmptyCircleIcon className="AccountDropdown__list-item-icon" />
                )}
              </li>
            ))}
          </ul>

          <small className="AccountDropdown__list-label">ACTIONS</small>
          <ul className="AccountDropdown__list">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li className="AccountDropdown__list-item" onClick={() => onSelectHowItWorks()}>
              See how it works
            </li>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li
              className="AccountDropdown__list-item-feedback"
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line sonarjs/link-with-target-blank
                window.open('https://uptimelabs.canny.io/uptimelabs-feedback', '_blank');
              }}
            >
              <Badge type="link">Share Feedback</Badge>
            </li>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <li
              className="AccountDropdown__list-item AccountDropdown__list-item--logout"
              onClick={() => onLogout()}
            >
              Logout
            </li>
          </ul>
        </DropdownContent>
      </DropdownWindow>
    </Dropdown>
  );
};

export default AccountDropdown;
