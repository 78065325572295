import classNames from 'classnames';
import { any, bool } from 'prop-types';
import { ReactNode, useContext } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import PendingContent from '../PendingContent/PendingContent';
import SpinnerLoader from '../SpinnerLoader/SpinerLoader';
import DropdownContext from './DropdownContext';

type DropdownWindowProps = {
  isContentLoading?: boolean;
  children?: ReactNode;
};
export type DropdownWindowStyles = {
  root: string;
};

const DropdownWindow: AdjustableComponent<DropdownWindowProps, DropdownWindowStyles> = (props) => {
  const context = useContext(DropdownContext);
  const loading = Boolean(props.isContentLoading);

  const classes = useClasses(
    {
      root: classNames('Dropdown__window', {
        'Dropdown__window--open': context.isOpen,
      }),
    },
    props.classes,
  );

  return (
    <div className={classes.root}>
      <PendingContent loader={SpinnerLoader} loading={loading} data-testid="PendingContent">
        {props.children}
      </PendingContent>
    </div>
  );
};

DropdownWindow.propTypes = {
  children: any,
  classes: any,
  isContentLoading: bool,
};

export default DropdownWindow;
