export enum AppRoutes {
  Index = '/',
  Account = '/account',
  AccountWithTabs = '/account/:tab?',
  InactiveAccount = '/inactive-account',
  LearnHowToImproveYourGrade = '/learning/473',
  Scenarios = '/scenarios',
  Scenario = '/scenario/:id', // introduction of scenario
  ScenarioLevels = '/scenario/:id/levels', // level chooser
  ScenarioLevel = '/scenario/:id/levels/:level',
  ScenarioJoin = '/join/:id/levels/:level/session/:session',
  LobbyJoin = '/join/:lobbyOwner/scenario/:lobbyScenario/level/:lobbyLevel/lobby/:lobbyUuid/',
  PlayScenario = '/scenario/:scenarioId/levels/:levelSlug/play', // counter,
  Version = '/version',
  Reports = '/account/reports',
  Report = '/report/:id',
  Achievements = '/account/achievements',
  ProxyError = '/proxy-error',
  LearningCenter = '/learning',
  LearningCenterContent = '/learning/:id', // learning center content aka posts
  Analytics = '/analytics/player/:playerEmail',
}
