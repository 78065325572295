import { FunctionComponent, useRef, useState } from 'react';

import slack1 from '../../../../assets/images/slack1.jpg';
import slack2 from '../../../../assets/images/slack2.jpg';
import slack3 from '../../../../assets/images/slack3.jpg';
import Button from '../../../../components/Button/Button';
import Modal, { ModalStyles } from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalFooter from '../../../../components/Modal/ModalFooter';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';

type SlackNotificationsInstructionsModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};
const SlackNotificationsInstructionsModal: FunctionComponent<
  SlackNotificationsInstructionsModalProps
> = ({ isOpen, onRequestClose }) => {
  const [progress, setProgress] = useState<number>(0);
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    const element = modalBodyRef.current;
    const scrollTop = element!.scrollTop;
    const height = element!.scrollHeight - element!.clientHeight;
    const scrolled = (scrollTop / height) * 100;
    setProgress(scrolled);
  };

  const renderProgressBarLabel = (progress: number) => {
    const percentage = Math.ceil(progress);
    return `You've read ${percentage}% of content`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} SlackNotificationsInstructionsModal`,
      })}
    >
      <ModalHeader>
        <div className="SlackNotificationsInstructionsModal__progress">
          <ProgressBar progress={progress} renderLabel={renderProgressBarLabel} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          ref={modalBodyRef}
          onScroll={onScroll}
          className="SlackNotificationsInstructionsModal__body"
        >
          <small>This content is scrollable, please use your scrollbar to continue reading.</small>
          <h3>Step 1</h3>
          <div className="SlackNotificationsInstructionsModal__image-wrapper">
            <img
              className="SlackNotificationsInstructionsModal__image"
              src={slack1}
              alt="Step 1: User's avatar and Preferences"
            />
          </div>
          <p>
            Click on the user&apos;s avatar (top right corner) to expand the account dropdown, and
            select <strong>Preferences</strong>
          </p>
          <h3>Step 2</h3>
          <div className="SlackNotificationsInstructionsModal__image-wrapper">
            <img
              className="SlackNotificationsInstructionsModal__image"
              src={slack2}
              alt="Step 2: Enable Desktop Notifications"
            />
          </div>
          <p>
            On the Preferences modal, click on the <strong>Enable Desktop Notifications</strong> -
            the green button.
          </p>
          <h3>Step 3</h3>
          <div className="SlackNotificationsInstructionsModal__image-wrapper">
            <img
              className="SlackNotificationsInstructionsModal__image"
              src={slack3}
              alt="Step 3: Confirm browser notifications"
            />
          </div>
          <p>Confirm that you allow browser to send desktop notifications.</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={() => onRequestClose()}>
          I know what to do, close this modal.
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SlackNotificationsInstructionsModal;
