import { any } from 'prop-types';
import { PropsWithChildren } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type ModalFooterStyles = {
  root: string;
};

const ModalFooter: AdjustableComponent<PropsWithChildren<object>, ModalFooterStyles> = (props) => {
  const styles = useClasses<ModalFooterStyles>(
    {
      root: 'Modal__footer',
    },
    props.classes,
  );

  return <div className={styles.root}>{props.children}</div>;
};

ModalFooter.propTypes = {
  children: any,
  classes: any,
};

export default ModalFooter;
