import { FunctionComponent } from 'react';

import {
  ScenarioIntroduction,
  ScenarioIntroductionStepDefinition,
} from '../../../types';
import IntroductionPlayer from '../../IntroductionPlayer/IntroductionPlayer';
import OnlineBoutiqueIntroductionStep1 from './OnlineBoutiqueIntroductionStep1';
import OnlineBoutiqueIntroductionStep2 from './OnlineBoutiqueIntroductionStep2';
import OnlineBoutiqueIntroductionStep3 from './OnlineBoutiqueIntroductionStep3';
import OnlineBoutiqueIntroductionStep4 from './OnlineBoutiqueIntroductionStep4';
import OnlineBoutiqueIntroductionStep5 from './OnlineBoutiqueIntroductionStep5';

// eslint-disable-next-line react-refresh/only-export-components
export enum OnlineBoutiqueIntroductionSteps {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
  Step5 = 5,
}

const OnlineBoutiqueIntroduction: FunctionComponent<ScenarioIntroduction> = ({
  onFinishIntroduction,
  scenario,
  step = 1,
  hideControls = false,
}) => {
  const steps: ScenarioIntroductionStepDefinition[] = [
    {
      step: OnlineBoutiqueIntroductionSteps.Step1,
      component: OnlineBoutiqueIntroductionStep1,
    },
    {
      step: OnlineBoutiqueIntroductionSteps.Step2,
      component: OnlineBoutiqueIntroductionStep2,
    },
    {
      step: OnlineBoutiqueIntroductionSteps.Step3,
      component: OnlineBoutiqueIntroductionStep3,
    },
    {
      step: OnlineBoutiqueIntroductionSteps.Step4,
      component: OnlineBoutiqueIntroductionStep4,
    },
    {
      step: OnlineBoutiqueIntroductionSteps.Step5,
      component: OnlineBoutiqueIntroductionStep5,
    },
  ];
  return (
    <div
      className="OnlineBoutiqueIntroduction"
      data-testid="OnlineBoutiqueIntroduction"
    >
      <IntroductionPlayer
        steps={steps}
        step={step}
        onFinishIntroduction={onFinishIntroduction}
        hideControls={hideControls}
        scenario={scenario}
        data-testid="IntroductionPlayer"
        data-steps={steps.length}
      />
    </div>
  );
};

export default OnlineBoutiqueIntroduction;
