import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import Button, { ButtonStyles } from '../../../components/Button/Button';
import { CheckIcon } from '../../../components/Icons';
import { AppRoutes } from '../../../config/routes';
import { isVideoModalOpenedAtom } from '../../../state';
import useGetTenant from '../../common/hooks/useGetTenant';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';

const PendingAccountContainer = () => {
  const { keycloak } = useKeycloak();
  const tenant = useGetTenant();
  const goToRoute = useRedirectToRoute();
  const setIsVideoModalOpened = useSetRecoilState(isVideoModalOpenedAtom);

  const onLogout = async () => {
    await keycloak.logout();
  };

  useEffect(() => {
    if (tenant != undefined) {
      goToRoute(AppRoutes.Account);
    }
  }, [tenant, goToRoute]);

  return (
    <div className="PendingAccountContainer" data-testid="PendingAccountContainer">
      <h1>Thanks for signing up!</h1>
      <p>We are activating your account. Meanwhile, please review the preparation steps.</p>
      <div className="PendingAccountContainer__boxes" data-testid="PendingAccountContainer__boxes">
        <div className="PendingAccountContainer__box" data-testid="PendingAccountContainer__box">
          <p> First, watch the video to understand how our platform works.</p>
          <Button
            size="small"
            onClick={() => setIsVideoModalOpened(true)}
            data-testid="PlayVideoButton"
          >
            Play Video
          </Button>
        </div>
        <div className="PendingAccountContainer__box" data-testid="PendingAccountContainer__box">
          <p>
            You can read up on the{' '}
            <a
              target="_blank"
              href="https://uptimelabs.io/top-10-incident-management-problems/"
              rel="noreferrer"
            >
              Top 10 Incident Management Problems
            </a>{' '}
            to get a better understanding of the problems you&apos;ll encounter in upcoming drills.
          </p>
        </div>
        <div className="PendingAccountContainer__box" data-testid="PendingAccountContainer__box">
          <p>Please make sure Slack is able to run on your browser.</p>
          <a
            className="Button Button--pulse-orange Button--small"
            id="button--link"
            target="_blank"
            href="https://uptimelabs-metaverse.slack.com/"
            data-testid="TestSlackButton"
            rel="noreferrer"
          >
            Test Slack
          </a>
        </div>
      </div>

      <h3>Please be aware that:</h3>
      <div
        className="PendingAccountContainer__checks"
        data-testid="PendingAccountContainer__checks"
      >
        <div
          className="PendingAccountContainer__check"
          data-testid="PendingAccountContainer__check"
        >
          <CheckIcon /> We will ask you to share your screen for user experience research purposes.
        </div>

        <div
          className="PendingAccountContainer__check"
          data-testid="PendingAccountContainer__check"
        >
          <CheckIcon /> We will also record the session for product improvement purposes.
        </div>

        <div
          className="PendingAccountContainer__check"
          data-testid="PendingAccountContainer__check"
        >
          <CheckIcon /> Please let us know if you are not comfortable with recording the session or
          sharing your desktop.
        </div>
      </div>

      <Button
        classes={(current: ButtonStyles) => ({
          ...current,
          root: `${current.root} PendingAccountContainer__logout`,
        })}
        onClick={onLogout}
        variant="link"
        size="small"
        data-testid="LogoutButton"
      >
        Logout
      </Button>
    </div>
  );
};

export default PendingAccountContainer;
