import './AllDrillsAccess.scss';

import CheckIcon from '@mui/icons-material/Check';
import { Card, Modal } from '@mui/material';
import { FC } from 'react';

import mike from '../../assets/images/avatar_mike_oanda.svg';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import { CrossIcon } from '../Icons';

interface AllDrillsAccessProps {
  open: boolean;
  onClose: () => void;
}

const AllDrillsAccess: FC<AllDrillsAccessProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className="drills-modal">
      <Card className="modal-content">
        <div className="left-panel">
          <h2 className="title">
            Unlock Your Full Drill Library & Elevate Your Incident Response!
          </h2>
          <p className="subtitle">Submit your request, and our team will be in touch soon.</p>
          <Button variant="primary">Request Access</Button>
        </div>

        <div className="right-panel">
          <CrossIcon className="close-button" onClick={onClose} data-testid="close-button" />
          <h2 className="title">Ready to transform Your Incident Response with Uptime Labs?</h2>

          <div className="features">
            <div className="feature-item">
              <CheckIcon className="check-icon" />
              <p>Real-World Training for your team in realistic IT incident simulations.</p>
            </div>

            <div className="feature-item">
              <CheckIcon className="check-icon" />
              <p>Data-Driven Insights to optimize response strategies.</p>
            </div>

            <div className="feature-item">
              <CheckIcon className="check-icon" />
              <p>Personalized Coaching to improve your response capabilities.</p>
            </div>
          </div>

          <div className="testimonial">
            <blockquote>
              &quot;Uptime Labs offers an engaging platform to refine incident management skills,
              bridging the gap in critical communication when every second counts.&quot;
            </blockquote>

            <div className="author">
              <Avatar imageSrc={mike} fullName="Mike Bird" variant="small" />
              <div className="author-info">
                <p className="name">Mike Bird</p>
                <p className="name">Trading Platforms, OANDA</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default AllDrillsAccess;
