import { PlayerRoles } from './types';

export enum MqttStaticTopics {
  START_SEQUENCE = 'uptimelabs/events/start_sequence',
  UPDATE_GAME_STATUS = 'uptimelabs/events/game/update',
  CONFIGURE_PLAYER = 'uptimelabs/configure/player',
}

export type PlayerSessionUpdatePayload = {
  scenario: string;
  level: number;
  status: string;
  game_remaining_seconds: number;
};

type BasicGameStatusPayload = {
  player_uid: string;
  player: string;
  scenario: string;
  level: string;
  tenant: string;
};

export type MqttPublishParams =
  | {
      topic: MqttStaticTopics.START_SEQUENCE;
      payload: {
        player_uid: string;
        player: string;
        scenario: string;
        level: number;
        sequence: number;
      };
    }
  | {
      // At this moment we support new_player and JOIN_SESSION types
      topic: MqttStaticTopics.UPDATE_GAME_STATUS;
      payload: BasicGameStatusPayload & {
        type: 'new_player' | 'JOIN_SESSION';
        game_length?: string;
        session_id?: number;
      };
    }
  | {
      topic: MqttStaticTopics.CONFIGURE_PLAYER;
      payload: {
        player: string;
        persona?: PlayerRoles;
        tenant?: string;
      };
    };

export const MqttTopics = {
  getGameStatusTopic: (player_uid: string) => `uptimelabs/player/${player_uid}/status/current`,
  getSetStatusTopic: () => `uptimelabs/events/game/update`,
  getPlayersInLobbyTopic: (lobbyId: string) => `uptimelabs/lobby/${lobbyId}/players`,
};
