import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import persistentState, { getAtom } from './persistent-state';

interface PersistentStateRootProps {
  children: ReactNode;
}

const PersistentStateRoot: FunctionComponent<PersistentStateRootProps> = (props) => {
  const setAudiosPlayed = useSetRecoilState(getAtom('audiosPlayed'));
  const setTourRan = useSetRecoilState(getAtom('tourRan'));
  const setSelectedPlayerRole = useSetRecoilState(getAtom('selectedPlayerRole'));
  const setReportsSelectedUserEmail = useSetRecoilState(getAtom('reportsSelectedUserEmail'));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribe = persistentState.subscribe((key: any, value: any) => {
      switch (key) {
        case 'audiosPlayed':
          setAudiosPlayed(value);
          break;
        case 'tourRan':
          setTourRan(value);
          break;
        case 'selectedPlayerRole':
          setSelectedPlayerRole(value);
          break;
        case 'reportsSelectedUserEmail':
          setReportsSelectedUserEmail(value);
          break;
      }
    });
    return unsubscribe;
  }, [setAudiosPlayed, setReportsSelectedUserEmail, setSelectedPlayerRole, setTourRan]);

  return <>{props.children}</>;
};

export default PersistentStateRoot;
