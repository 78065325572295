import classNames from 'classnames';
import { FC } from 'react';

// eslint-disable-next-line react-refresh/only-export-components
export enum SpinnerLoaderSizes {
  Small = 'small',
  Normal = 'normal',
}

export interface SpinnerLoaderProps {
  visible: boolean;
  size: SpinnerLoaderSizes;
}

const SpinnerLoader: FC<SpinnerLoaderProps> = ({ visible, size }) => {
  const spinnerClass = classNames('Spinner', {
    'Spinner--visible': visible,
    [`Spinner--${size}`]: size,
  });

  return <div data-testid="SpinnerLoader" className={spinnerClass} />;
};

export default SpinnerLoader;
