import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import axiosInstance from './axiosSetup';
import { DependencyContainer } from './DependencyContainer';

interface HttpClientProtocol {
  get<T>(endpoint: string): Promise<AxiosResponse<T>>;
  getStream<T>(endpoint: string): Promise<AxiosResponse<T>>;
  post<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>>;
  put<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>>;
}

export class HttpClient implements HttpClientProtocol {
  token: string;
  constructor(protected readonly factory: DependencyContainer) {
    this.factory = factory;
    this.token = '';
  }

  setToken(token: string) {
    this.token = token;
  }

  async get<T>(endpoint: string, configOverwrite?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const config = await this.getAxiosRequestConfig('GET', endpoint, configOverwrite);
    return axiosInstance.get<T>(endpoint, config as AxiosRequestConfig);
  }

  async getStream<T>(
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      adapter: 'fetch',
      headers: { Authorization: `Bearer ${this.token}` },
      method: 'GET',
      responseType: 'stream',
      timeout: 60000,
      url: endpoint,
      ...configOverwrite,
    };
    return axiosInstance.get<T>(endpoint, config as AxiosRequestConfig);
  }

  async post<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const config = await this.getAxiosRequestConfig('POST', endpoint, configOverwrite);
    return await axiosInstance.post<T>(endpoint, data, config as AxiosRequestConfig);
  }

  async put<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const config = await this.getAxiosRequestConfig('PUT', endpoint, configOverwrite);
    return await axiosInstance.put<T>(endpoint, data, config as AxiosRequestConfig);
  }

  async patch<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const config = await this.getAxiosRequestConfig('GET', endpoint, configOverwrite);
    return await axiosInstance.patch<T>(endpoint, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async delete<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>> {
    const config = await this.getAxiosRequestConfig('DELETE', endpoint, {
      data,
    });
    return await axiosInstance.delete<T>(endpoint, config as AxiosRequestConfig);
  }

  protected async getAxiosRequestConfig(
    method: Method,
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> {
    const headers =
      this.token !== ''
        ? {
            Authorization: `Bearer ${this.token}`,
          }
        : undefined;

    const config: AxiosRequestConfig = {
      url: endpoint,
      method,
      timeout: 60000,
      headers,
      ...configOverwrite,
    };

    return Promise.resolve(config);
  }
}
