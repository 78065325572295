import cx from 'classnames';
import { any, bool, oneOf } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

export type ButtonVariant = 'superprimary' | 'primary' | 'secondary' | 'link' | 'tertiary';
type ButtonSize = 'small' | 'large';
export type ButtonProps = {
  pulsed?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
} & JSX.IntrinsicElements['button'];
export type ButtonStyles = {
  root: string;
};

const mapVariantToPulse: Record<string, string> = {
  superprimary: 'Button--pulse-green',
};

const Button: AdjustableComponent<ButtonProps, ButtonStyles> = ({
  children,
  classes,
  pulsed = false,
  size = 'large',
  variant = 'primary',
  ...restProps
}) => {
  const pulseClassName = mapVariantToPulse[variant] || '';
  const styles = useClasses(
    {
      root: cx('Button', {
        [`Button--${variant}`]: variant,
        [`Button--${size}`]: size,
        [pulseClassName]: pulsed,
      }),
    },
    classes,
  );
  return (
    <button className={styles.root} {...restProps}>
      {children}
    </button>
  );
};

Button.propTypes = {
  classes: any,
  children: any,
  pulsed: bool,
  size: oneOf(['small', 'large']),
  variant: oneOf(['superprimary', 'primary', 'secondary', 'link', 'tertiary']),
};

export default Button;
