import { FC, useState } from 'react';

import Button, { ButtonStyles } from '../../../../components/Button/Button';
import Input, { InputStyles } from '../../../../components/Input/Input';

const SearchReport: FC = () => {
  const [sorting, setSorting] = useState<'asc' | 'desc'>('desc');

  const addStylesToSearchInput = (current: InputStyles) => ({
    ...current,
    root: `${current.root} AccountContainer__reports-search-icon`,
  });

  const addStylesToSortButton = (current: ButtonStyles) => ({
    ...current,
    root: `${current.root} AccountContainer__reports-sort-button`,
  });

  return (
    <div className="AccountContainer__reports-top-bar">
      <div className="AccountContainer__reports-search">
        <Input classes={addStylesToSearchInput} isSearch type="text" placeholder="Search report" />
      </div>
      <div className="AccountContainer__reports-sort">
        Sort by
        <Button
          onClick={() => setSorting('asc')}
          classes={addStylesToSortButton}
          size="small"
          disabled={sorting === 'asc'}
          variant="link"
        >
          Date ASC
        </Button>
        <Button
          onClick={() => setSorting('desc')}
          classes={addStylesToSortButton}
          size="small"
          disabled={sorting === 'desc'}
          variant="link"
        >
          Date DESC
        </Button>
      </div>
    </div>
  );
};

export default SearchReport;
