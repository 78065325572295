import './OnlineBoutiqueIntroductionStep2.scss';

import { FunctionComponent } from 'react';
import Joyride from 'react-joyride';

import onlineBoutique from '../../../../../assets/images/introduction/onlineboutique.png';
import AudioPlayer, { AudioPlayerStyles } from '../../../../../components/AudioPlayer/AudioPlayer';
import Button from '../../../../../components/Button/Button';
import { joyrideStyles } from '../../../../../config/config';
import { Features } from '../../../../../config/features';
import { Urls } from '../../../../../config/urls';
import { usePersistentState } from '../../../../../persistent-state/persistent-state';
import { useFeatureFlag } from '../../../../common/hooks/useFeatureFlag';
import { IntroductionPlayerStep } from '../../IntroductionPlayer/IntroductionPlayer';

const OnlineBoutiqueIntroductionStep2: FunctionComponent<IntroductionPlayerStep> = () => {
  const [tourRan] = usePersistentState('tourRan');
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);

  const introduction = `Online Boutique’s site. The site is available on the internet.
Click on the visit website button to use and explore Online Boutique's platform.
Exploring the website will allow you to better understand the architecture empowering Online Boutique.
As an exercise, add a couple of products to your shopping cart and checkout.`;

  const goToOnlineBoutique = () => Urls['getUrlForObDemo'](window.envs.REACT_APP_ENV);

  const openOnlineBoutique = () =>
    window.open(Urls['getUrlForObDemo'](window.envs.REACT_APP_ENV), '_blank');

  const steps = [
    {
      target: '#OnlineBoutiqueIntroduction__hipster-image',
      content: 'You can click on this image to go to the Online Boutique e-commerce platform.',
    },
  ];

  return (
    <div className="OnlineBoutiqueIntroduction">
      <AudioPlayer
        source="/audio/website_intro.mp3"
        classes={(current: AudioPlayerStyles) => ({
          ...current,
          root: `${current.root} OnlineBoutiqueIntroduction__audio-player`,
        })}
      />
      {isOnBoardingAvailable() && (
        <Joyride
          steps={steps}
          stepIndex={0}
          run={!tourRan}
          scrollToFirstStep
          showSkipButton
          styles={joyrideStyles}
        />
      )}

      <div className="OnlineBoutiqueIntroduction__row">
        <div className="OnlineBoutiqueIntroduction__image-container">
          <a
            href={goToOnlineBoutique()}
            className="introduction-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="introduction-text">{introduction}</p>
          </a>
          <a
            href={goToOnlineBoutique()}
            className="introduction-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={onlineBoutique}
              alt="Online Boutique"
              className="OnlineBoutiqueIntroduction__image"
            />
          </a>
          <div className="OnlineBoutiqueIntroduction__cta">
            <Button size="small" variant="secondary" onClick={() => openOnlineBoutique()}>
              Visit website
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineBoutiqueIntroductionStep2;
