import { useRef } from 'react';

const useScroll = (itemWidth: number, pixelsPerInterval: number, intervalDuration: number) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (!scrollContainerRef.current) {
      return;
    }
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft +=
          direction === 'right' ? pixelsPerInterval : -pixelsPerInterval;
        scrollAmount += pixelsPerInterval;
        if (scrollAmount >= itemWidth) {
          window.clearInterval(slideTimer);
        }
      }
    }, intervalDuration);
  };

  return { scrollContainerRef, scroll };
};

export default useScroll;
