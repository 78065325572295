import { atom, MutableSnapshot, RecoilState, useRecoilValue } from 'recoil';

type StateTreeFromInitial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: any },
  K extends keyof T,
> = {
  [key in K]: { atom: RecoilState<T[K]>; default: T[K] };
};

export const createPersistentState = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  K extends keyof T,
>(
  initialState: T,
) => {
  const persistentStateTree = {} as StateTreeFromInitial<T, keyof T>;
  Object.keys(initialState).forEach((key) => {
    persistentStateTree[key as K] = {
      atom: atom({
        key,
        default: initialState[key],
      }),
      default: initialState[key],
    };
  });

  const $subscribers: Array<<K extends keyof T>(key: K, value?: T[K]) => void> = [];

  const subscribe = (subscriber: <K extends keyof T>(key: K, value?: T[K]) => void) => {
    $subscribers.push(subscriber);
    const unsubscribe = () => {
      // eslint-disable-next-line sonarjs/no-ignored-return
      $subscribers.filter((s) => s !== subscriber);
    };
    return unsubscribe;
  };

  const persistentState = {
    get: <K extends keyof T>(key: K) => {
      const valueFromStorage = localStorage.getItem(String(key));
      if (valueFromStorage === null) {
        return null;
      } else {
        try {
          return JSON.parse(valueFromStorage) as T[K];
        } catch (e) {
          console.error('persistentState error:', e);
          return null;
        }
      }
    },
    set: <K extends keyof T>(key: K, value: T[K]) => {
      localStorage.setItem(String(key), JSON.stringify(value));
      $subscribers.forEach((subscriber) => subscriber<K>(key, value));
    },
    remove: <K extends keyof T>(key: K) => {
      localStorage.removeItem(String(key));
      $subscribers.forEach((subscriber) => subscriber<K>(key));
    },
  };

  const usePersistentState = <K extends keyof T>(key: K) => {
    const value = useRecoilValue(persistentStateTree[key].atom);
    const typedValue: T[K] extends (typeof persistentStateTree)[K]['default'] ? T[K] : never =
      value;
    const setValue = (value: T[K]) => {
      localStorage.setItem(String(key), JSON.stringify(value));
      $subscribers.forEach((subscriber) => subscriber(key, value));
    };

    return [typedValue, setValue] as const;
  };

  const getAtom = <K extends keyof T>(key: K) => {
    const atom = persistentStateTree[key].atom as unknown as RecoilState<T[K]>;
    return atom;
  };

  const initializePersistentState = (snapshot: MutableSnapshot) => {
    (Object.keys(initialState) as Array<keyof T>).forEach((key) => {
      const storedValue = localStorage.getItem(String(key));
      if (storedValue !== null) {
        try {
          snapshot.set(getAtom(key), JSON.parse(storedValue));
        } catch (e) {
          console.error('initializePersistentState error:', e);
          persistentState.set(key, persistentStateTree[key].default);
          snapshot.set(getAtom(key), persistentStateTree[key].default);
        }
      }
    });
  };

  return {
    subscribe,
    persistentState,
    usePersistentState,
    getAtom,
    initializePersistentState,
  };
};
