import './pagination.scss';

import { FunctionComponent, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useRecoilState, useRecoilValue } from 'recoil';

import FeatureNotification from '../../../../components/FeatureNotification/FeatureNotification';
import PendingContent, {
  PendingContentStyles,
} from '../../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../../components/SpinnerLoader/SpinerLoader';
import { AppRoutes } from '../../../../config/routes';
import { DependencyContainer } from '../../../../DependencyContainer';
import {
  reportsAtom,
  reportsLoadingAtom,
  reportsSelectedUserEmailSelector,
} from '../../../../state';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';
import { Report } from '../../../reports/types';
import ReportCard from './ReportCard';
import SearchReport from './SearchReport';

const { reportsService } = DependencyContainer.getInstance();

const Reports: FunctionComponent = () => {
  const [reports, setReports] = useRecoilState(reportsAtom) || [];
  const reportsLoading = useRecoilValue(reportsLoadingAtom);
  const redirectToRoute = useRedirectToRoute();
  const token = useBearerToken();
  const reportsSelectedUserEmail = useRecoilValue(reportsSelectedUserEmailSelector);

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const reportsPerPage = 9;
  let pageCount = 1;
  if (reports) {
    pageCount = Math.ceil(reports.length / reportsPerPage || 0 / reportsPerPage);
  }
  const startIndex = currentPage * reportsPerPage;
  const endIndex = startIndex + reportsPerPage;
  const currentReports = reports?.slice(startIndex, endIndex);

  const handlePageClick = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onReportClick = (report: Report) => {
    redirectToRoute(AppRoutes.Report, {
      id: report.id,
    });
  };

  const onReportToggle = async (report: Report) => {
    if (!reportsSelectedUserEmail || !token) {
      return;
    }
    const successMessage = report.is_approved
      ? 'Report has been set to Unapproved'
      : 'Report has been approved';

    try {
      await reportsService.toggleReport(report.id, token, reportsSelectedUserEmail);
      setReports((reports) =>
        reports?.map((r: Report) => {
          if (r.id === report.id) {
            return {
              ...r,
              is_approved: !r.is_approved,
            };
          }
          return r;
        }),
      );
      setTimeout(() => {
        alert(successMessage);
      }, 300);
    } catch (error) {
      console.error('onReportToggle error:', error);
      alert('Something went wrong');
    }
  };
  const showTopBar = false;

  return (
    <div className="AccountContainer__reports">
      <FeatureNotification message="New feature added: Open a report and view the slack logs for business and incident channels." />
      {showTopBar && <SearchReport />}
      <PendingContent
        data-testid="PendingContent"
        classes={(current: PendingContentStyles) => ({
          ...current,
          root: `${current.root} AccountContainer__reports-loading`,
        })}
        loading={reportsLoading}
        hideContent
        loader={SpinnerLoader}
      >
        <div className="AccountContainer__reports-wrapper">
          {currentReports && currentReports.length === 0 && (
            <p>You don&apos;t have any reports yet.</p>
          )}
          {currentReports &&
            currentReports.length > 0 &&
            currentReports.map((report) => (
              <ReportCard
                report={report}
                onReportClick={onReportClick}
                onReportToggle={onReportToggle}
                key={report.id}
              />
            ))}
        </div>
        <div className="pagination-container">
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={({ selected }) => handlePageClick(selected)}
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </PendingContent>
    </div>
  );
};

export default Reports;
