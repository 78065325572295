import { Urls } from '../../../../../config/urls';
import { DependencyContainer } from '../../../../../DependencyContainer';
import { MqttTopics } from '../../../../common/mqtt-topics';
import { CreateLobbyPayload, ExistingLobbyPayload, Lobby, LobbyPlayer } from '../types';

export default class LobbyService {
  constructor(protected readonly factory: DependencyContainer) {}

  private hydrateLobbyWithInfo(lobby: Lobby): Lobby {
    return {
      ...lobby,
    };
  }

  async createLobby(token: string, payload: CreateLobbyPayload): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.createLobby(token, payload);
      const data = response.data;
      console.debug('createLobby data received');
      return this.hydrateLobbyWithInfo(data);
    } catch (error) {
      console.error('createLobby error: ', error);
      throw error;
    }
  }

  async generateLobbyJoinLink(token: string, lobby: Lobby): Promise<string> {
    try {
      const baseUrl = Urls.getBaseUrl(String(window.envs.REACT_APP_ENV));
      return `${baseUrl}/join/${lobby.owner}/scenario/${lobby.scenario}/level/${lobby.level}/lobby/${lobby.id}`;
    } catch (error) {
      console.error('generateLobbyJoinLink error: ', error);
      throw error;
    }
  }

  async joinLobby(token: string, payload: ExistingLobbyPayload): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.joinLobby(token, payload);
      const data = response.data;
      console.debug('joinLobby data received');
      return this.hydrateLobbyWithInfo(data);
    } catch (error) {
      console.error('joinLobby error: ', error);
      throw error;
    }
  }

  async getLobbyByEmail(token: string, player: string): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.getLobbyByEmail(token, player);
      const data = response.data;
      console.debug('getLobbyByEmail data received');
      return this.hydrateLobbyWithInfo(data);
    } catch (error) {
      console.error('getLobbyByEmail error: ', error);
      throw error;
    }
  }

  async closeLobby(token: string, payload: ExistingLobbyPayload): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.closeLobby(token, payload);
      const data = response.data;
      console.debug('closeLobby data received');
      return this.hydrateLobbyWithInfo(data);
    } catch (error) {
      console.error('closeLobby error: ', error);
      throw error;
    }
  }

  async leaveLobby(token: string, payload: ExistingLobbyPayload): Promise<Lobby> {
    try {
      const response = await this.factory.lobbyClient.leaveLobby(token, payload);
      const data = response.data;
      console.debug('leaveLobby data received');
      return this.hydrateLobbyWithInfo(data);
    } catch (error) {
      console.error('leaveLobby error: ', error);
      throw error;
    }
  }

  setPlayersinLobby(lobbyId: string, payload: LobbyPlayer[]): () => void {
    const topic = MqttTopics.getPlayersInLobbyTopic(lobbyId);
    this.factory.mqttService.publish({
      topic: topic,
      payload: payload,
    });
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }

  getPlayersInLobby(lobby_id: string, onMessage: (message: LobbyPlayer[]) => void): () => void {
    const topic = MqttTopics.getPlayersInLobbyTopic(lobby_id);
    this.factory.mqttService.subscribe(topic, onMessage);
    return () => {
      this.factory.mqttService.unsubscribe(topic);
    };
  }
}
