import { any, bool, string } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type TextLoaderProps = {
  visible: boolean;
  text?: string;
} & JSX.IntrinsicElements['div'];

type TextLoaderStyles = {
  root: string;
};

const TextLoader: AdjustableComponent<TextLoaderProps, TextLoaderStyles> = ({
  visible,
  text = 'Loading',
  ...props
}) => {
  const classes = useClasses(
    {
      root: 'TextLoader',
    },
    typeof props.classes === 'function' ? props.classes : undefined,
  );

  if (!visible) {
    return null;
  }

  return (
    <div {...props} className={classes.root} data-testid="TextLoader">
      {text}
    </div>
  );
};

TextLoader.propTypes = {
  classes: any,
  text: string,
  visible: bool.isRequired,
};
export default TextLoader;
