import { FC } from 'react';

import Button from '../../../components/Button/Button';
import { AppRoutes } from '../../../config/routes';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import PlayerHeatMap from '../PlayerHeatMap';
import { Player, StarsAndHints } from '../types';

export const DrillsOverTime: FC<{ player: Player; starsAndHints: StarsAndHints[] }> = ({
  player,
  starsAndHints,
}) => {
  const goToRoute = useRedirectToRoute();

  return (
    <div className="activity-heatmap-wrapper">
      <div className="charts__title">Drills Over Time</div>
      <span className="charts__subtitle">
        {player?.numberOfDrills} drill
        {(player?.numberOfDrills ?? 0 > 1) ? 's' : ''} played
      </span>
      {starsAndHints && <PlayerHeatMap starsAndHints={starsAndHints} />}
      {!starsAndHints && (
        <div className="reports-cta">
          <p className="reports-cta__placeholder-subtitle">
            Not enough data yet. Play a drill to get started
          </p>
          <Button
            size="small"
            variant="secondary"
            className="reports-action-button"
            onClick={() => goToRoute(AppRoutes.ScenarioLevels, { id: 'online-boutique' })}
          >
            Play a drill
          </Button>
        </div>
      )}
    </div>
  );
};
