import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { FC } from 'react';

interface SlackButtonProps {
  title: string;
  onClick?: () => void;
}

const SlackButton: FC<SlackButtonProps> = ({ title, onClick }) => {
  return (
    <Button
      variant="contained"
      href="#"
      sx={{
        backgroundColor: 'rgba(0, 0, 255, 0.1)',
        color: '#1a73e8',
        borderRadius: '20px',
        textTransform: 'none',
        fontWeight: 'bold',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 255, 0.2)',
        },
        width: '225px',
      }}
      onClick={onClick}
      startIcon={<OpenInNewIcon />}
    >
      {title}
    </Button>
  );
};

export default SlackButton;
