import { useKeycloak } from '@react-keycloak/web';
import cx from 'classnames';
import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';

import Card, { CardStyles } from '../../../components/Card/Card';
import { reportsSelectedUserEmailSelector } from '../../../state';
import formatTime from '../../common/helpers/formatTime.helper';
import { getDisplayNameFromToken } from '../../common/helpers/text.helper';
import { ClassesModifier } from '../../common/hooks/useClasses';
import { useUser } from '../../common/hooks/useUser';
import { Report } from '../types';
import ReportOverviewCard from './ReportOverviewCard';

type ReportSummaryCardProps = {
  report: Report;
  isIncomplete?: boolean;
  stylesProvider: ClassesModifier<CardStyles>;
};

const ReportSummaryCard: FunctionComponent<ReportSummaryCardProps> = ({
  report,
  isIncomplete = false,
  stylesProvider,
}) => {
  const { keycloak } = useKeycloak();
  const displayName = getDisplayNameFromToken(keycloak.tokenParsed);
  const user = useUser();
  const reportsSelectedUserEmail = useRecoilValue(reportsSelectedUserEmailSelector);

  const getPlayerName = () => {
    if (user.isAdmin && reportsSelectedUserEmail) {
      return reportsSelectedUserEmail;
    }
    return displayName;
  };

  const getResolutionTime = () => {
    return report?.session_duration || 0;
  };

  const resolvedTimeClassNames = cx('ReportContainer__resolved-time', {
    'ReportContainer__resolved-time--incomplete': isIncomplete,
  });

  return (
    <Card collapsible={false} classes={stylesProvider}>
      <div className="ReportContainer__details-card">
        <h1>{getPlayerName()}</h1>
        <div className="ReportContainer__details-row">
          <ReportOverviewCard report={report} />
          <div className={resolvedTimeClassNames}>
            {isIncomplete ? (
              <h2>Out of time</h2>
            ) : (
              <>
                <h2>{formatTime(getResolutionTime())}</h2>
                Resolution time
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ReportSummaryCard;
