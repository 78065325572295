import { useNavigate } from 'react-router';

import { AppRoutes } from '../../../config/routes';

export const useRedirectToRoute = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (routeName: AppRoutes, params?: any) => {
    const allParams = Object.keys(params || {});
    const route =
      params !== undefined
        ? allParams.reduce(
            (acc: string, param: string) => acc.replace(`:${param}`, params[param]),
            routeName,
          )
        : routeName;
    navigate(route);
  };
};
