import { AppRoutes } from '../../../config/routes';
import { useCurrentRoute } from './useCurrentRoute';

const mapRouteToContainerTitle: Record<AppRoutes, string> = {
  [AppRoutes.Scenarios]: 'Scenarios',
  [AppRoutes.Scenario]: 'Scenario Introduction',
  [AppRoutes.LearnHowToImproveYourGrade]: 'Scenarios',
  [AppRoutes.ScenarioLevel]: 'Level starting...',
  [AppRoutes.ScenarioJoin]: 'Joining...',
  [AppRoutes.Account]: 'Account',
  [AppRoutes.AccountWithTabs]: 'Account',
  [AppRoutes.Reports]: 'Reports',
  [AppRoutes.Report]: 'Report',
  [AppRoutes.Achievements]: 'Achievements',
  [AppRoutes.ScenarioLevels]: 'Select level',
  [AppRoutes.PlayScenario]: 'Play scenario',
  [AppRoutes.InactiveAccount]: 'Welcome to Uptime Labs',
  [AppRoutes.Index]: 'Loading...',
  [AppRoutes.Version]: 'Version info',
  [AppRoutes.ProxyError]: 'Proxy error',
  [AppRoutes.LearningCenter]: 'Learning Center',
  [AppRoutes.LearningCenterContent]: 'Learning Center',
  [AppRoutes.LobbyJoin]: 'Joining lobby...',
  [AppRoutes.Analytics]: 'Analytics',
};

type UseContainerTitleReturnType = () => string;

export const useContainerTitle = (): UseContainerTitleReturnType => {
  const getCurrentRoute = useCurrentRoute();
  const currentRoute = getCurrentRoute();

  return () => mapRouteToContainerTitle[currentRoute] || '';
};
