import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import clockIcon from '../../../assets/icons/icons_clock_16.svg';
import hintIcon from '../../../assets/icons/icons_hints_12.svg';
import starIcon from '../../../assets/icons/icons_stars_12.svg';
import { StarsAndHintsDetails } from '../types';

interface PlayerBarChartTooltipProps {
  sessionId: string;
  date: string;
  type: 'stars' | 'hints';
  details: StarsAndHintsDetails[];
  completionStatus?: string;
}

const PlayerBarChartTooltip: FC<PlayerBarChartTooltipProps> = ({
  sessionId,
  date,
  type,
  details,
  completionStatus,
}) => {
  return (
    <div data-testid={`tooltip-${sessionId}-${type}`}>
      <Tooltip
        id={`tooltip-${sessionId}-${type}`}
        place={type === 'stars' ? 'right' : 'left'}
        clickable
        className="performance-barchart-tooltip"
      >
        <div className="tooltip-header">
          Session {sessionId}, <br /> {date}
        </div>
        {completionStatus && (
          <div
            className={`tooltip-completion-status ${
              completionStatus === 'COMPLETED' ? 'completed' : 'out-of-time'
            }`}
          >
            <img src={clockIcon} alt="Clock" className="tooltip-icon" />
            {completionStatus === 'COMPLETED'
              ? 'Completed on time!'
              : 'Out of time!'}
          </div>
        )}
        <div className="tooltip-content">
          <strong>
            {type === 'stars'
              ? `Stars: ${details.length}`
              : `Hints: ${details.length}`}
          </strong>
          {details.map((detail, idx) => (
            <div key={idx} className="tooltip-detail">
              <img
                src={type === 'stars' ? starIcon : hintIcon}
                alt={type === 'stars' ? 'Star' : 'Hint'}
                className="tooltip-icon"
              />
              <span className="tooltip-message">{detail.message}</span>
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
};

export default PlayerBarChartTooltip;
