import { FunctionComponent, useState } from 'react';

import Badge from '../../../../components/Badge/Badge';
import Card from '../../../../components/Card/Card';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import DropdownContent from '../../../../components/Dropdown/DropdownContent';
import DropdownToggle, {
  DropdownToggleStyles,
} from '../../../../components/Dropdown/DropdownToggle';
import DropdownWindow from '../../../../components/Dropdown/DropdownWindow';
import { DotsIcon } from '../../../../components/Icons';
import SpinnerLoader, {
  SpinnerLoaderSizes,
} from '../../../../components/SpinnerLoader/SpinerLoader';
import { useFetchData } from '../../../common/hooks/useFetchData';
import { useUser } from '../../../common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../../content/types';
import { Report } from '../../../reports/types';
import { contentService } from '../../../scenarios/containers/ScenarioJoinContainer';

type ReportCardProps = {
  report: Report;
  onReportToggle?: (report: Report) => void;
  onReportClick?: (report: Report) => void;
};

const ReportCard: FunctionComponent<ReportCardProps> = ({
  report,
  onReportToggle,
  onReportClick,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const user = useUser();
  const [loading, setLoading] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((p) => !p);
  };

  const toggleReport = async (report: Report) => {
    setLoading(true);
    try {
      onReportToggle?.(report);
    } finally {
      setLoading(false);
    }
  };

  const level = report.level.id;
  const [, levelContent] = useFetchData<WordpressContent<WordpressLevel>, Error>(
    () => {
      return contentService.getLevelById(level);
    },
    undefined,
    [level],
    () => !level,
  );

  const getDrillName = () => levelContent?.title?.rendered ?? 'Loading...';

  const getDrillDifficulty = () => levelContent?.acf?.difficulty ?? 'Loading...';

  const cardTitle = user.isAdmin ? (
    <div className="AccountContainer__report-card-title">
      <Dropdown isOpen={showDropdown} toggle={toggleDropdown}>
        <DropdownToggle
          classes={(current: DropdownToggleStyles) => ({
            ...current,
            root: `${current.root} AccountContainer__report-card-toggle`,
          })}
        >
          <DotsIcon />
        </DropdownToggle>
        <DropdownWindow>
          <DropdownContent>
            <ul>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
              <li onClick={() => toggleReport(report)}>
                {report.is_approved ? 'Unapprove' : 'Approve'}
                {loading && <SpinnerLoader size={SpinnerLoaderSizes.Small} visible />}
              </li>
            </ul>
          </DropdownContent>
        </DropdownWindow>
      </Dropdown>
    </div>
  ) : undefined;

  return (
    <div className="AccountContainer__report-card">
      <Card
        classes={(current) => ({
          ...current,
          root: `${current.root} AccountContainer__report-card-card`,
        })}
        collapsible={false}
        title={cardTitle}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div onClick={() => onReportClick?.(report)}>
          <span className="AccountContainer__report-card-scenario">
            OB - {getDrillName()}
            <br /> <i>{getDrillDifficulty()} </i>
          </span>
          <div className="AccountContainer__report-card-progress"></div>
          {report.scenario.displayName}
          <div className="AccountContainer__report-card-caption">
            Role: {report.role && <Badge type="info">{report.role}</Badge>}
          </div>
          <small className="AccountContainer__report-card-date">
            {report.session_start.toLocaleString()}
          </small>
          {user.isAdmin && (
            <div className="AccountContainer__report-card-status">
              {report.is_approved ? (
                <Badge type="success">Approved</Badge>
              ) : (
                <Badge type="danger">Unapproved</Badge>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ReportCard;
