import { any } from 'prop-types';
import { ReactNode } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type TableRowStyles = {
  root: string;
};

interface TableRowProps {
  classes?: Partial<TableRowStyles>;
  children: ReactNode;
}

const TableRow: AdjustableComponent<TableRowProps, TableRowStyles> = (props) => {
  const styles = useClasses(
    {
      root: 'Table__row',
    },
    props.classes,
  );

  return (
    <tr className={styles.root} data-testid="Table__row">
      {props.children}
    </tr>
  );
};

TableRow.propTypes = {
  children: any,
  classes: any,
};

export default TableRow;
