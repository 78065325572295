import './AllDrills.scss';

import { FC } from 'react';

import { useUser } from '../../features/common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../features/content/types';
import Button from '../Button/Button';
import ComingSoonCard from '../ComingSoon/ComingSoonCard';

interface ListProps {
  levels: Array<WordpressContent<WordpressLevel>>;
  startLevel: (levelSlug: string) => void;
}

const AllDrills: FC<ListProps> = ({ levels, startLevel }) => {
  const user = useUser();

  return (
    <div>
      <hr className="carousel-divide" />
      <div className="list-title">ALL DRILLS</div>
      <div className="list-container" data-testid="List">
        {levels.map((level: WordpressContent<WordpressLevel>) => {
          if (level.acf.is_available || user.isAdmin) {
            return (
              <div className="button-text" key={level.slug}>
                <Button className="button-text" onClick={() => startLevel(level.slug)}>
                  <div className="content-card">
                    <div className="card-text">
                      <span className="level-title">{level.title.rendered}</span>
                      <span className={`level-difficulty`} data-difficulty={level.acf.difficulty}>
                        {level.acf.difficulty}
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
            );
          }
          return <ComingSoonCard key={level.slug} title={level.title.rendered} />;
        })}
      </div>
    </div>
  );
};

export default AllDrills;
