import './lobby.scss';

import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CheckIcon from '../../../../assets/icons/icons_check_16.svg';
import ClockIcon from '../../../../assets/icons/icons_clock_24.svg';
import CrossIcon from '../../../../assets/icons/icons_close_16.svg';
import GoalBronzeIcon from '../../../../assets/icons/icons_goal_bronze_24.svg';
import GoalGoldIcon from '../../../../assets/icons/icons_goal_gold_24.svg';
import GoalSilverIcon from '../../../../assets/icons/icons_goal_silver_24.svg';
import launchIcon from '../../../../assets/icons/icons_launch_24.svg';
import CopyIcon from '../../../../assets/icons/icons_report_12.svg';
import Button from '../../../../components/Button/Button';
import SpinnerLoader, {
  SpinnerLoaderSizes,
} from '../../../../components/SpinnerLoader/SpinerLoader';
import { AppRoutes } from '../../../../config/routes';
import { DependencyContainer } from '../../../../DependencyContainer';
import { GamePlay, gamePlayAtom, GameStatus, sessionStateAtom } from '../../../../state';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { useFetchData } from '../../../common/hooks/useFetchData';
import { useRedirectToRoute } from '../../../common/hooks/useRedirectToRoute';
import { User } from '../../../common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../../content/types';
import { contentService } from '../../containers/ScenarioJoinContainer';
import { Scenario } from '../../types';
import { Lobby, LobbyPlayer } from './types';

const { lobbyService, sessionService } = DependencyContainer.getInstance();
interface LobbyCardProps {
  scenario: Scenario;
  level: WordpressContent<WordpressLevel>;
  user: User;
  readyToPlay: boolean;
  isJoiningExistingLobby: boolean;
  joiningLobby: Lobby | null;
}

const LobbyCard: FunctionComponent<LobbyCardProps> = ({
  scenario,
  level,
  user,
  readyToPlay,
  isJoiningExistingLobby,
  joiningLobby,
}) => {
  const [lobby, setLobby] = useState<Lobby | null>(joiningLobby);
  const [lobbyLink, setLobbyLink] = useState<string | null>();
  const token = useBearerToken();
  const levelId = level?.acf.level_id;
  const [isLobbyActive, setIsLobbyActive] = useState(lobby?.status === 'active');
  const setGamePlay = useSetRecoilState<GamePlay>(gamePlayAtom);
  const goToRoute = useRedirectToRoute();
  const [hasJoined, setHasJoined] = useState(false);
  const [isPlayerInLobby, setIsPlayerInLobby] = useState(
    (lobby?.players || []).some(
      (player) => player?.player === user.email && player.status === 'active',
    ),
  );
  const [loading, setLoading] = useState(false);
  const [playersInLobby, setPlayersInLobby] = useState<LobbyPlayer[]>([]);
  const setSessionState = useSetRecoilState(sessionStateAtom);
  const sessionState = useRecoilValue(sessionStateAtom);
  const [, lobbyLevel] = useFetchData<WordpressContent<WordpressLevel>, Error>(
    async () => {
      const levelData = await contentService.getLevelById(lobby!.level);
      return levelData || null;
    },
    undefined,
    [level, lobby],
    () => !level,
  );

  const startSession = async () => {
    try {
      const session = await sessionService.createSession(token!, {
        lobbyId: lobby?.id ?? '',
      });
      sessionService.setSession(user.id, {
        scenario: session.scenario,
        level: session.level,
        status: 'ready',
        game_remaining_seconds: 0,
      });
      if (session) {
        readyToPlay = true;
        setSessionState(true);
      }
    } catch (error) {
      console.error('Error creating startSession: ', error);
    }
  };

  const startSoloSession = async () => {
    setLoading(true);
    try {
      const session = await sessionService.createSoloSession(token!, {
        scenario: scenario.id,
        level: levelId,
      });
      sessionService.setSession(user.id, {
        scenario: session.scenario,
        level: session.level,
        status: 'ready',
        game_remaining_seconds: 0,
      });
      if (session) {
        readyToPlay = true;
        setSessionState(true);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error creating startSoloSession: ', error);
    }
  };

  const createLobby = async () => {
    if (token) {
      const newlobby = await lobbyService.createLobby(token, {
        scenario: scenario.id,
        level: levelId,
      });
      setLobby(newlobby);
      setIsLobbyActive(true);
      setIsPlayerInLobby(true);
      lobbyService.setPlayersinLobby(newlobby.id, newlobby.players);
      if (newlobby) {
        const link = await lobbyService.generateLobbyJoinLink(token, newlobby);
        setLobbyLink(link);
      }
    }
  };

  const copyToClipboard = () => {
    if (!lobbyLink) {
      return;
    }
    navigator.clipboard.writeText(lobbyLink).then(
      () => {
        console.log('Copied to clipboard successfully!');
      },
      (err) => {
        console.error('Failed to copy: ', err);
      },
    );
  };

  const closeLobby = async () => {
    if (token && lobby) {
      await lobbyService.closeLobby(token, {
        lobbyId: lobby.id,
      });
      setIsLobbyActive(false);
      setLobbyLink(null);
      setLobby(null);
      setSessionState(false);
    }
  };

  const joinLobby = async () => {
    await lobbyService.joinLobby(token!, {
      lobbyId: joiningLobby!.id,
    });
    setIsPlayerInLobby(true);
    setIsLobbyActive(true);
    setHasJoined(true);
  };

  const leaveLobby = async () => {
    await lobbyService.leaveLobby(token!, {
      lobbyId: joiningLobby!.id,
    });
    setIsPlayerInLobby(false);
    setHasJoined(false);
  };

  const findLobby = useCallback(
    async (lobbyOwner: string) => {
      if (token) {
        const activeLobby = await lobbyService.getLobbyByEmail(token, lobbyOwner);
        if (activeLobby) {
          setLobby(activeLobby);
          setIsLobbyActive(activeLobby.status === 'active');
          lobbyService.setPlayersinLobby(activeLobby.id, activeLobby.players);
          setHasJoined(true);
          const link = await lobbyService.generateLobbyJoinLink(token, activeLobby);
          if (activeLobby?.owner === user.email) {
            setIsPlayerInLobby(true);
          }
          setLobbyLink(link);
        }
      }
    },
    [token, user.email],
  );

  useEffect(() => {
    if (!lobby) {
      return;
    }
    if (hasJoined) {
      findLobby(lobby.owner);
      lobbyService.getPlayersInLobby(lobby.id, (players) => {
        return setPlayersInLobby(players);
      });
    }
  }, [readyToPlay, findLobby, hasJoined, lobby]);

  useEffect(() => {
    const listenToSessionUpdates = sessionService.getSessionStatus(
      joiningLobby ? joiningLobby.ownerId : user.id,
      (message) => {
        if (message.status === 'ready') {
          setGamePlay({
            level: message.level.toString(),
            scenario: message.scenario,
            status: GameStatus.Ready,
            gameRemainingSeconds: 0,
          });
          setTimeout(() => {
            goToRoute(AppRoutes.PlayScenario, {
              scenarioId: message.scenario,
              levelSlug: level?.slug,
            });
          }, 400);
        }
      },
    );
    return () => {
      listenToSessionUpdates();
    };
  }, [goToRoute, joiningLobby, level?.slug, setGamePlay, user.id]);

  useEffect(() => {
    if (!joiningLobby) {
      findLobby(user.email);
    } else {
      setLobby(joiningLobby);
    }
  }, [findLobby, joiningLobby, user.email]);

  const playerList = useMemo(() => {
    if (!playersInLobby) {
      return null;
    }
    return playersInLobby.map((player: LobbyPlayer) => (
      <ul className="lobby-card__player-list" key={player.player}>
        <li>
          <div className="lobby-card__player-list__player-role-container">
            <img
              className="icon"
              src={player.status === 'active' ? CheckIcon : CrossIcon}
              alt="status icon"
            />
            <span className="lobby-card__player-list__player-role">
              {player.player === lobby?.owner ? 'Lead IM: ' : 'Secondary IM: '}
            </span>
          </div>
          <div className="lobby-card__player-list__player">{player.player}</div>
        </li>
      </ul>
    ));
  }, [lobby?.owner, playersInLobby]);

  return (
    <div className="Card lobby-card" data-testid="LobbyCard">
      <div className="lobby-card__header">
        <ul>
          <li>
            <img className="lobby-card__header-icon" src={ClockIcon} alt="Clock Icon" />
            <h2> {level?.acf.duration} Minutes</h2>
          </li>
          <li>
            <img className="lobby-card__header-icon" src={launchIcon} alt="Launch Icon" />
            <h2> {level?.acf.difficulty}</h2>
          </li>
        </ul>
        {isJoiningExistingLobby && !isPlayerInLobby && (
          <Button
            className="Button  Button--pulse-green Button--small "
            variant="superprimary"
            onClick={joinLobby}
          >
            Join lobby
          </Button>
        )}
        {isJoiningExistingLobby && isPlayerInLobby && (
          <Button
            className="Button Button--warning  Button--small"
            variant="superprimary"
            onClick={leaveLobby}
          >
            Leave lobby
          </Button>
        )}
        {!isJoiningExistingLobby && !isLobbyActive && level?.acf.is_team_drill && (
          <Button
            className="Button Button-invite-team Button--pulse-orange Button--small "
            variant="superprimary"
            onClick={createLobby}
          >
            Invite Team
          </Button>
        )}
        {level?.acf.is_solo_player_available && !isJoiningExistingLobby && !isLobbyActive && (
          <>
            {level?.acf.is_team_drill && level?.acf.is_solo_player_available && (
              <div className="lobby-card__button-seperator">or</div>
            )}
            <Button
              className="Button Button--green Button-single-player Button--small "
              variant="superprimary"
              onClick={() => {
                setLoading(lobby?.status === 'active');
                startSoloSession();
              }}
              disabled={loading}
            >
              {loading && <SpinnerLoader size={SpinnerLoaderSizes.Small} visible />}
              {!loading && 'Play Solo'}
            </Button>
          </>
        )}
        {/* button to close active lobby if players have an existing lobby */}
        {!isJoiningExistingLobby && isLobbyActive && (
          <Button
            className="Button Button--warning Button--close-lobby  Button--small"
            onClick={closeLobby}
          >
            Close {lobbyLevel?.title?.rendered}&apos;s Lobby?
          </Button>
        )}
        {/* button to go back to the game play page if they have an active session */}
        {!isJoiningExistingLobby && isLobbyActive && sessionState && (
          <Button
            className="Button Button--pulse-orange  Button--small"
            onClick={() => {
              goToRoute(AppRoutes.PlayScenario, {
                scenarioId: lobby?.scenario,
                levelSlug: level?.slug,
              });
            }}
          >
            Continue playing {lobbyLevel?.title?.rendered}
          </Button>
        )}
        {level?.acf.is_team_drill && (
          <>
            {lobbyLink && !isJoiningExistingLobby && (
              <div className="textarea-container">
                <textarea readOnly value={lobbyLink} className="textarea"></textarea>
                <button className="copy-button" onClick={copyToClipboard}>
                  <img src={CopyIcon} alt="Copy to Clipboard" />
                </button>
              </div>
            )}
            {isLobbyActive && isPlayerInLobby && <ul>{playerList}</ul>}
          </>
        )}
      </div>
      <div className="lobby-card__content">
        {/* Learning Objectives */}
        <ul className="lobby-card__content--objectives">
          <p> Learning Objectives:</p>
          {level?.acf.learning_objective_1 && <li> {level.acf.learning_objective_1}</li>}
          {level?.acf.learning_objective_2 && <li> {level.acf.learning_objective_2}</li>}
          {level?.acf.learning_objective_3 && <li> {level.acf.learning_objective_3}</li>}
        </ul>
        {/* Goals */}
        {level?.acf.gold_goal && (
          <ul className="goals">
            <p> Goals:</p>
            <li>
              <img src={GoalGoldIcon} alt="goals-icon" className="icon" />
              Under {level.acf.gold_goal} Minutes{' '}
            </li>
            <li>
              <img src={GoalSilverIcon} alt="goals-icon" className="icon" />
              Under {level.acf.silver_goal} Minutes{' '}
            </li>
            <li>
              <img src={GoalBronzeIcon} alt="goals-icon" className="icon" />
              Under {level.acf.bronze_goal} Minutes{' '}
            </li>
          </ul>
        )}
        <div>
          {lobby?.owner === user.email && playersInLobby.length >= 2 && (
            <Button
              className="Button start-drill Button--primary Button--small Button--pulse-green"
              variant="superprimary"
              onClick={() => {
                setLoading(true);
                startSession();
              }}
              disabled={loading}
            >
              {loading && <SpinnerLoader size={SpinnerLoaderSizes.Small} visible />}
              {!loading && 'Start drill'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LobbyCard;
