import cx from 'classnames';
import { any, number, oneOf, oneOfType, string } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

export type HeroStatProps = {
  value: number | string;
  variant?: 'standard' | 'danger';
  label?: string;
} & JSX.IntrinsicElements['div'];

type HeroStatStyles = {
  root: string;
  label: string;
  value: string;
};

const HeroStat: AdjustableComponent<HeroStatProps, HeroStatStyles> = ({
  classes,
  label,
  value,
  variant = 'standard',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('HeroStat', {
        [`HeroStat--${variant}`]: true,
      }),
      label: 'HeroStat__label',
      value: 'HeroStat__value',
    },
    classes,
  );
  return (
    <div className={styles.root} {...rest} data-testid="HeroStat">
      <div className={styles.value}>{value}</div>
      {label && (
        <div id="hero-label" className={styles.label}>
          {label}
        </div>
      )}
    </div>
  );
};

HeroStat.propTypes = {
  classes: any,
  label: string,
  value: oneOfType([number, string]).isRequired,
  variant: oneOf(['standard', 'danger']),
};

export default HeroStat;
