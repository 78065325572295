import cx from 'classnames';
import process from 'process';
import { any, bool } from 'prop-types';
import { createContext } from 'react';
import ReactModal from 'react-modal';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

export type ModalProps = ReactModal.Props & {
  fullWidth?: boolean;
  withSeparators?: boolean;
  transparentBackground?: boolean;
  frame?: boolean;
  isOpen: boolean;
};

export type ModalStyles = {
  root: string;
};

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement(document.createElement('div'));
}

// eslint-disable-next-line react-refresh/only-export-components
export const ModalPropsContext = createContext<Partial<ModalProps>>({
  onRequestClose: () => {},
});

if (process.env.NODE_ENV === 'test') {
  ReactModal.setAppElement(document.createElement('div'));
}

const Modal: AdjustableComponent<ModalProps, ModalStyles> = (props) => {
  const classes = useClasses<ModalStyles>(
    {
      root: cx('Modal', {
        'Modal--full-width': props.fullWidth,
        'Modal--with-separators': props.withSeparators,
        'Modal--transparent-background': props.transparentBackground,
        'Modal--frame': props.frame,
      }),
    },
    props.classes,
  );

  return props?.isOpen ? (
    <ReactModal {...props} className={classes.root} data-testid="Modal">
      <ModalPropsContext.Provider
        value={{
          ...props,
        }}
      >
        {props.children}
      </ModalPropsContext.Provider>
    </ReactModal>
  ) : null;
};

Modal.propTypes = {
  classes: any,
  children: any,
  frame: bool,
  fullWidth: bool,
  isOpen: bool.isRequired,
  transparentBackground: bool,
  withSeparators: bool,
};

export default Modal;
