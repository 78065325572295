import { useCallback, useEffect, useRef } from 'react';

type ToggleByClickOutsideParams = {
  toggler: () => void;
  state: boolean;
};

export const useToggleByClickOutside = (params: ToggleByClickOutsideParams) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { toggler, state } = params;

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (
        rootRef &&
        rootRef.current &&
        !rootRef.current.contains(event.target as Element) &&
        state
      ) {
        toggler();
      }
    },
    [state, toggler],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => document.removeEventListener('mousedown', handleDocumentClick);
  }, [state, handleDocumentClick]);

  return {
    ref: rootRef,
  };
};
