import './analytics.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { select } from 'd3-selection';
import { timeDays, timeMonths, timeWeek, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { AppRoutes } from '../../config/routes';
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import { StarsAndHints } from './types';

const formatSessionDataForHeatmap = (starsAndHints: StarsAndHints[], year: number) => {
  const sessionsByDate: Record<string, { date: string; count: number; sessionIds: string[] }> = {};

  starsAndHints
    .filter((session) => new Date(session.date).getFullYear() === year)
    .forEach((session) => {
      const date = session.date;
      if (!sessionsByDate[date]) {
        sessionsByDate[date] = { date, count: 0, sessionIds: [] };
      }
      sessionsByDate[date].count += 1;
      sessionsByDate[date].sessionIds.push(String(session.sessionId));
    });

  return Object.values(sessionsByDate);
};

const getAvailableYears = (starsAndHints: StarsAndHints[]) => {
  const years = new Set(starsAndHints.map((session) => new Date(session.date).getFullYear()));
  return Array.from(years).sort((a, b) => b - a);
};

const PlayerHeatMap: FC<{ starsAndHints: StarsAndHints[] }> = ({ starsAndHints }) => {
  const yearsPlayerHasPlayed = getAvailableYears(starsAndHints);
  const [selectedYear, setSelectedYear] = useState(yearsPlayerHasPlayed[0]);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const goToRoute = useRedirectToRoute();
  // eslint-disable-next-line sonarjs/no-unused-vars, sonarjs/no-dead-store, @typescript-eslint/no-unused-vars
  const [place, setPlace] = useState(0);

  const goToReportPage = useCallback(
    (id: number) => {
      if (!window.envs.REACT_APP_ENV) {
        return;
      }
      goToRoute(AppRoutes.Report, {
        id: id,
      });
    },
    [goToRoute],
  );

  useEffect(() => {
    const renderHeatMap = () => {
      if (!svgRef.current) return;
      const heatMapData = formatSessionDataForHeatmap(starsAndHints, selectedYear);
      const dateSet = new Set(heatMapData.map((d) => d.date));
      const containerWidth = svgRef.current?.parentElement?.offsetWidth || window.innerWidth * 0.9;
      const cellSize = Math.max(10, Math.min(containerWidth / 93 - 2, 10));
      const width = cellSize * 65;
      const height = cellSize * 7 + 50;

      const startDate = new Date(selectedYear, 0, 1);
      const endDate = new Date(selectedYear, 11, 31);
      const dateRange = timeDays(startDate, endDate);
      const months = timeMonths(startDate, endDate);

      // Set up SVG
      const svg = select(svgRef.current)
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .style('width', '100%')
        .style('height', 'auto');

      // Month Labels
      const monthLabels = svg.selectAll('.month-label').data(months);
      monthLabels
        .join(
          (enter) => enter.append('text').attr('class', 'month-label'),
          (update) => update,
          (exit) => exit.remove(),
        )
        .attr('x', (d) => timeWeek.count(timeYear(d), d) * (cellSize + 2))
        .attr('y', 20)
        .text((d) => timeFormat('%b')(d))
        .style('text-anchor', 'start')
        .style('font-size', '14px')
        .style('fill', '#000')
        .style('font-weight', '600');

      // Day Cells
      const dayCells = svg.selectAll('.day-cell').data(dateRange);
      dayCells
        .join(
          (enter) => enter.append('rect').attr('class', 'day-cell'),
          (update) => update,
          (exit) => exit.remove(),
        )
        .attr('width', cellSize)
        .attr('height', cellSize)
        .attr('x', (d: Date) => timeWeek.count(timeYear(d), d) * (cellSize + 2))
        .attr('y', (d: Date) => d.getDay() * (cellSize + 2) + 30)
        .attr('fill', (d: Date) =>
          dateSet.has(d.toISOString().slice(0, 10)) ? '#154EB3' : '#F5F7FA',
        )
        .attr('stroke', '#fff')
        .attr('rx', 4)
        .attr('cursor', 'pointer')
        .attr('data-tooltip-id', (d) => `tooltip-${d.toISOString().slice(0, 10)}`)
        .attr('data-tooltip-html', (d) => {
          const sessionData = heatMapData.find(
            // eslint-disable-next-line sonarjs/no-nested-functions
            (s) => s.date === d.toISOString().slice(0, 10),
          );
          return sessionData
            ? `
            <div class="activity-heatmap-tooltip__content">
            <div class="activity-heatmap-tooltip__title">${timeFormat('%B %d, %Y')(new Date(sessionData.date))}</div>
            <div class="activity-heatmap-tooltip__title"> Drills completed: ${sessionData.count}</div>
            ${sessionData.sessionIds
              .map(
                // eslint-disable-next-line sonarjs/no-nested-functions
                (id: string) => `
              <div class="activity-heatmap-tooltip__drill">
               <span class="activity-heatmap-tooltip__drill-id">${id}</span>
              <button
                class="tooltip__report-link"
                data-id="${id}"
              >
                See Report
              </button>
              </div>`,
              )
              .join('')}
            </div>`
            : `<div class="activity-heatmap-tooltip__content">
             <div class="activity-heatmap-tooltip__drill-date">No session played on ${timeFormat('%B %d, %Y')(d)}</div>
             </div>`;
        });
    };

    renderHeatMap();
    window.addEventListener('resize', renderHeatMap);

    return () => window.removeEventListener('resize', renderHeatMap);
  }, [selectedYear, starsAndHints]);

  useEffect(() => {
    const handleTooltipClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains('tooltip__report-link')) {
        const sessionId = target.getAttribute('data-id');
        if (sessionId) {
          goToReportPage(Number(sessionId));
        }
      }
    };

    document.addEventListener('click', handleTooltipClick);
    return () => document.removeEventListener('click', handleTooltipClick);
  }, [goToReportPage]);

  return (
    <div className="activity-heatmap__container">
      <div className="activity-heatmap__tabs">
        {yearsPlayerHasPlayed.map((year) => (
          <button
            key={year}
            className={`activity-heatmap__tab-button ${year === selectedYear ? 'active' : ''}`}
            onClick={() => setSelectedYear(year)}
          >
            {year}
          </button>
        ))}
      </div>
      <div className="activity-heatmap__chart">
        <svg ref={svgRef}></svg>
        <Tooltip
          anchorSelect=".day-cell"
          className="activity-heatmap-tooltip"
          clickable
          openOnClick
        />
      </div>
    </div>
  );
};

export default PlayerHeatMap;
