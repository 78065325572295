import { matchPath } from 'react-router';

import { AppRoutes } from '../../../config/routes';

export const getMatchedRoute = (pathname: string) => {
  const routes = Object.values(AppRoutes).reverse();
  const matchedRoute = routes.find((route) => matchPath(route, pathname));

  if (matchedRoute == null) {
    throw new Error(
      `Cannot match current route '${pathname}' to defined routes`,
    );
  }

  return matchedRoute;
};
