import { TextEvent } from '@merc/react-timeline';
import { FunctionComponent } from 'react';

import {
  BulbIcon,
  PlayCircleIcon,
  StarIcon,
  SuccessIcon,
} from '../../../components/Icons';
export type TimeLineEvent = {
  date?: string;
  text?: string;
  time?: string;
};

const GameStartedIconMarker = () => (
  <PlayCircleIcon className="ReportContainer__timeline-started" />
);

const SuccessIconMarker = () => (
  <SuccessIcon className="ReportContainer__timeline-complete" />
);

const StarRedIconMarker = () => (
  <StarIcon className="ReportContainer__timeline-star-red" />
);

const StarYellowIconMarker = () => (
  <StarIcon className="ReportContainer__timeline-star-yellow" />
);

const BulbIconMarker = () => (
  <BulbIcon className="ReportContainer__timeline-bulb" />
);

export const GameStartedEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={GameStartedIconMarker} />;
};

export const HintEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={BulbIconMarker} />;
};

export const StarEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={StarYellowIconMarker} />;
};

export const GoalEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={StarYellowIconMarker} />;
};

export const GoalResolvedEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={SuccessIconMarker} />;
};

export const OutOfTimeEvent: FunctionComponent<TimeLineEvent> = (props) => {
  return <TextEvent {...props} marker={StarRedIconMarker} />;
};
