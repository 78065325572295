import cx from 'classnames';
import { any, bool, string } from 'prop-types';
import { JSX, memo, useEffect } from 'react';

import useAudio from '../../features/common/hooks/useAudio';
import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import Button, { ButtonStyles } from '../Button/Button';
import { PauseIcon, PlayIcon, SoundOffIcon, SoundOnIcon } from '../Icons';

export type AudioPlayerProps = {
  source: string;
  audioPlaying?: boolean;
} & JSX.IntrinsicElements['div'];

export type AudioPlayerStyles = {
  root: string;
  button: string;
};

const addStylesToButtons = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} AudioPlayer__button`,
});

const AudioPlayer: AdjustableComponent<AudioPlayerProps, AudioPlayerStyles> = ({
  source,
  audioPlaying = false,
  classes,
  ...restProps
}) => {
  const styles = useClasses(
    {
      root: cx('AudioPlayer', {
        'AudioPlayer--should-play': true,
      }),
      button: 'AudioPlayer__button',
    },
    classes,
  );
  const [
    backgroundMusicPlaying,
    toggleBackgroundMusic,
    flushBackgroundMusic,
    toggleVolume,
    isMuted,
  ] = useAudio(source);

  useEffect(() => {
    if (audioPlaying && !backgroundMusicPlaying) {
      toggleBackgroundMusic();
    }
    return () => {
      flushBackgroundMusic();
    };
  }, [audioPlaying, backgroundMusicPlaying, flushBackgroundMusic, toggleBackgroundMusic]);

  return (
    <div className={styles.root} {...restProps} data-testid="AudioPlayer">
      <Button
        classes={addStylesToButtons}
        onClick={() => toggleBackgroundMusic()}
        variant="secondary"
        size="small"
        data-testid={backgroundMusicPlaying ? 'PauseIcon' : 'PlayIcon'}
      >
        {!backgroundMusicPlaying ? <PlayIcon /> : <PauseIcon />}
      </Button>
      <Button
        classes={addStylesToButtons}
        onClick={() => toggleVolume()}
        variant="secondary"
        size="small"
        data-testid={isMuted ? 'SoundOffIcon' : 'SoundOnIcon'}
      >
        {isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
      </Button>
    </div>
  );
};

AudioPlayer.propTypes = {
  audioPlaying: bool,
  classes: any,
  source: string.isRequired,
};

export default memo(AudioPlayer);
