import { scaleBand, select, Selection } from 'd3';

interface ChartData {
  sessionId: string;
  drillName: string;
  date: string;
}

export const playerBarChartXAxis = (
  data: ChartData[],
  svg: Selection<SVGSVGElement | null, unknown, null, undefined>,
  goToReportPage: { (id: number): void; (arg0: number): void },
) => {
  const containerWidth = 1000;
  const barWidth = 140;
  const width = Math.max(barWidth * data.length, containerWidth);
  const height = 400;
  const margin = { top: 40, right: 20, bottom: 90, left: 60 };

  const xScale = scaleBand()
    .domain(data.map((d) => d.sessionId))
    .range([margin.left, width - margin.right])
    .padding(0.2);

  svg
    .selectAll('.x-axis-label')
    .data(data)
    .join('text')
    .attr('class', 'x-axis-label')
    .attr('x', (d) => xScale(d.sessionId)! + xScale.bandwidth() / 2)
    .attr('y', height - margin.bottom + 20)
    .attr('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('cursor', 'pointer')
    .each(function (d) {
      const xPos = xScale(d.sessionId)! + xScale.bandwidth() / 2;
      select(this)
        .append('tspan')
        .attr('x', xPos)
        .attr('dy', '0')
        .text(d.drillName);
      select(this)
        .append('tspan')
        .attr('x', xPos)
        .attr('dy', '1.5em')
        .text(d.date);
    })
    .on('click', (_event, d) => {
      goToReportPage(Number(d.sessionId));
    });
};
