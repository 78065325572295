import './ComingSoonCard.scss';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

import Button from '../Button/Button';
import { ComingSoonCardProps } from './ComingSoonCard';

const ComingSoonDemoCard: FC<ComingSoonCardProps> = ({ key, title }) => {
  return (
    <div className="coming-soon-container-demo" key={key}>
      <Button className="coming-soon-button">
        <div className="pending-content-card">
          <span className="pending-level-title">{title}</span>
          <span className="pending-content">Coming Soon</span>
          <span className="locked-level">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </div>
      </Button>
    </div>
  );
};

export default ComingSoonDemoCard;
