import { Maybe } from '../common/types';
import { FeedbackTypes } from './types';

const mapFeedbackTypeToQuestion: Record<string, string> = {
  [FeedbackTypes.Positive]: 'What do you think you did well in this incident?',
  [FeedbackTypes.Negative]: 'What do you think you could have done to get to a resolution faster?',
  [FeedbackTypes.LearnedLastSession]: 'What did you learn last session?',
  [FeedbackTypes.LearnedToday]: 'What have you learned during this incident?',
  [FeedbackTypes.BringNextSession]:
    'What is one thing you feel is important to bring to the next incident you need to resolve?',
  [FeedbackTypes.ChallengeReason]: 'Why it was so challenging?',
  [FeedbackTypes.EnjoyReason]: 'Why did you enjoy this incident?',
  [FeedbackTypes.StressReason]: 'Why it was so stressful?',
  [FeedbackTypes.DrillProgression]: 'What do you think you did well in this incident?',
  [FeedbackTypes.LearnedTodayVol2]:
    'What have you learned during this incident and how will you bring those teachings to the next incident?',
};

// this section represents title sentences that came from UptimeLabs
const mapFeedbackTypeToSentenceFromUptimeLabs: Record<string, string> = {
  [FeedbackTypes.Positive]: 'What we think you did well in this incident',
  [FeedbackTypes.Negative]: 'What we think you could have done to get to a resolution faster',
};

export const getQuestionByType = (
  type: FeedbackTypes,
  fromUptimeLabs: boolean = false,
): Maybe<string> => {
  // eslint-disable-next-line sonarjs/no-selector-parameter
  return fromUptimeLabs
    ? mapFeedbackTypeToSentenceFromUptimeLabs[type] || undefined
    : mapFeedbackTypeToQuestion[type] || undefined;
};
