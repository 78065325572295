import { useKeycloak } from '@react-keycloak/web';
import { FC, useState } from 'react';

import Tabs, { TabsStyles } from '../../../../components/Tabs/Tabs';
import { usePersistentState } from '../../../../persistent-state/persistent-state';
import { useUser } from '../../../common/hooks/useUser';
import DashboardTabs, { DashboardTab } from './DashboardTabs';

const AdvancedDashboard: FC = () => {
  const user = useUser();
  const { keycloak } = useKeycloak();
  const [reportsSelectedUserEmail] = usePersistentState(
    'reportsSelectedUserEmail',
  );
  const tabs = DashboardTabs({
    user,
    reportsSelectedUserEmail: reportsSelectedUserEmail ?? null,
  });
  const filteredTabs = tabs.filter((tab) => tab.isVisible);
  const [activeTab, setActiveTab] = useState<DashboardTab['slug']>('profile');

  const onLogout = async () => {
    await keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  return (
    <Tabs
      classes={(current: TabsStyles) => ({
        ...current,
        content: `${current.content} AccountContainer__tab-content`,
      })}
      onSelectTab={async (option) => {
        setActiveTab(option.id as DashboardTab['slug']);
        if (option.id === 'logout') {
          await onLogout();
        }
      }}
      options={filteredTabs.map((option: DashboardTab) => ({
        id: option.slug,
        label: option.labelMessage,
        content: option.content,
      }))}
      active={filteredTabs.findIndex((option) => option.slug === activeTab)}
    />
  );
};

export default AdvancedDashboard;
