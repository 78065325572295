import { useEffect } from 'react';

import SpinnerLoader, { SpinnerLoaderSizes } from '../../../components/SpinnerLoader/SpinerLoader';
import { AppRoutes } from '../../../config/routes';
import { DependencyContainer } from '../../../DependencyContainer';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';

const { scenariosService } = DependencyContainer.getInstance();

const IndexContainer = () => {
  const redirectToRoute = useRedirectToRoute();

  useEffect(() => {
    scenariosService
      .getScenarios()
      .then((scenarios) => {
        const firstScenario = scenarios[0];
        redirectToRoute(AppRoutes.Scenario, {
          id: firstScenario.id,
        });
      })
      .catch(() => {
        redirectToRoute(AppRoutes.Account);
      });
  }, [redirectToRoute]);

  return (
    <div className="IndexContainer">
      <SpinnerLoader visible size={SpinnerLoaderSizes.Normal} data-testid="SpinnerLoader" />
    </div>
  );
};

export default IndexContainer;
