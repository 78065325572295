import { AxiosResponse } from 'axios';

import { getBlogUrl } from '../../../config/urls';
import { HttpClient } from '../../../HttpClient';
import { Post, WordpressContent } from '../../content/types';

const configOverride = {
  baseURL: getBlogUrl(),
};

export default class PostsClient extends HttpClient {
  async fetchPosts(): Promise<AxiosResponse<Post[]>> {
    try {
      const response = await this.get<Post[]>(
        configOverride.baseURL,
        configOverride,
      );
      return response;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  }

  async fetchPost(id: string): Promise<AxiosResponse<WordpressContent<Post>>> {
    try {
      const postUrl = configOverride.baseURL + `/${id}`;
      const response = await this.get<WordpressContent<Post>>(
        postUrl,
        configOverride,
      );
      return response;
    } catch (error) {
      console.error('Error fetching post:', error);
      throw error;
    }
  }
}
