import { FC, FunctionComponent, JSX } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../config/routes';
import AccountDropdown from '../../features/common/components/AccountDropdown/AccountDropdown';
import { useUser } from '../../features/common/hooks/useUser';
import logo from './../../assets/images/logo-min.png';

type TopbarProps = {
  pagetitle: string;
} & JSX.IntrinsicElements['div'];

const CtaButton: FC = () => {
  useNavigate();
  const currentPath = window.location.pathname;
  const playDrillsPath = AppRoutes.ScenarioLevels.replace(':id', 'online-boutique');

  return (
    <>
      {currentPath === playDrillsPath ? (
        <a
          className="Topbar__cta-link Topbar__cta-link--hover Topbar__cta-link--feedback"
          href="https://uptimelabs.canny.io/uptimelabs-feedback"
          target="_blank"
          rel="noopener noreferrer"
        >
          SHARE FEEDBACK
        </a>
      ) : (
        <Link
          to={playDrillsPath}
          className="Topbar__cta-link Topbar__cta-link--hover Topbar__cta-link--playDrill"
        >
          PLAY DRILLS
        </Link>
      )}
    </>
  );
};

const Topbar: FunctionComponent<TopbarProps> = ({ ...rest }) => {
  const user = useUser();
  return (
    <div className="Topbar" {...rest} data-testid="Topbar">
      <Link to={AppRoutes.Index}>
        <img className="Topbar__logo" src={logo} alt="UptimeLabs" />
      </Link>
      <ul className="Topbar__nav">
        <li>
          <Link className="Topbar__link" to={AppRoutes.Index}>
            ONLINE BOUTIQUE
          </Link>
        </li>
        <li>
          <Link className="Topbar__link" to={AppRoutes.Reports}>
            REPORTS
          </Link>
        </li>
        <li>
          <Link className="Topbar__link" to={AppRoutes.LearningCenter}>
            LEARNING
          </Link>
        </li>
        <li>
          <CtaButton data-testid="share-feedback-button" />
        </li>
      </ul>
      {user?.email && <AccountDropdown />}
    </div>
  );
};

export default Topbar;
