import { Scenario } from '../types';
import billyHillMock from './billy-hill-mock';
import bsosMock from './bsos-mock';
import minzoMock from './minzo-mock';
import onlineBoutiqueMock from './online-boutique-mock';

const mockedScenarios: Scenario[] = [
  onlineBoutiqueMock,
  bsosMock,
  billyHillMock,
  minzoMock,
];

export default mockedScenarios;
