import { TextField } from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';

import Button from '../../../../components/Button/Button';
import { usePersistentState } from '../../../../persistent-state/persistent-state';

const PlayerEmail: FC = () => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [reportsSelectedUserEmail, setReportsSelectedUserEmail] = usePersistentState(
    'reportsSelectedUserEmail',
  );
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setEmail(reportsSelectedUserEmail ?? '');
  }, [reportsSelectedUserEmail]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setReportsSelectedUserEmail(email);
    if (error) {
      setReportsSelectedUserEmail('');
      setEmail('');
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const value = event.target.value;
    setEmail(value);
    if (value.trim().length > 0 && !emailRegex.test(value)) {
      setError(true);
      setHelperText('Please enter a valid email address.');
    } else {
      setError(false);
      setHelperText('');
    }
  };

  return (
    <div className="AccountContainer__admin-bar">
      <TextField
        label="User email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
        helperText={helperText}
        error={error}
        sx={{ width: '90%' }} //
      />
      <Button size="small" type="submit" onClick={handleSubmit}>
        Save
      </Button>
    </div>
  );
};

export default PlayerEmail;
