import './analytics.scss';

import { FC } from 'react';

interface Stats {
  stars: number;
  hints: number;
  drills: number;
}

interface Player {
  numberOfDrills: number;
}

interface AnalyticsStatsCardProps {
  currentMonthStats: Stats | null;
  previousMonthStats: Stats | null;
  player: Player | null;
  starCount: number;
  hintCount: number;
}

const AnalyticsStatsCard: FC<AnalyticsStatsCardProps> = ({
  currentMonthStats,
  previousMonthStats,
  player,
  starCount,
  hintCount,
}) => {
  const calculateChange = (current: number, previous: number | null): number | null => {
    if (previous === null) return null;
    return current - previous;
  };
  const starChange = calculateChange(currentMonthStats?.stars ?? 0, previousMonthStats?.stars ?? 0);
  const hintChange = calculateChange(currentMonthStats?.hints ?? 0, previousMonthStats?.hints ?? 0);
  const drillChange = calculateChange(
    currentMonthStats?.drills ?? 0,
    previousMonthStats?.drills ?? 0,
  );

  return (
    <div className="progress-cards">
      <div className="progress-cards__drill-stats">
        <div className="stat-title">Drills Played</div>
        <div className="stat-number-and-change-container">
          <div className="stat-number">{player?.numberOfDrills ?? 0}</div>
          {drillChange !== null && (
            <div className="stat-change-details">
              <div className={`stat-change ${drillChange >= 0 ? 'positive' : 'negative'}`}>
                {drillChange > 0 ? '+' : ''}
                {drillChange}
              </div>
              <span> vs last month </span>
            </div>
          )}
        </div>
      </div>
      <div className="progress-cards__stars-stats">
        <div className="stat-title">Stars Earned</div>
        <div className="stat-number-and-change-container">
          <div className="stat-number">{starCount}</div>
          {starChange !== null && (
            <div className="stat-change-details">
              <div className={`stat-change ${starChange >= 0 ? 'positive' : 'negative'}`}>
                {starChange > 0 ? '+' : ''}
                {starChange}
              </div>
              <span> vs last month </span>
            </div>
          )}
        </div>
      </div>

      <div className="progress-cards__hints-stats">
        <div className="stat-title">Hints</div>
        <div className="stat-number-and-change-container">
          <div className="stat-number">{hintCount}</div>
          {hintChange !== null && (
            <div className="stat-change-details">
              <div className={`stat-change ${hintChange >= 0 ? 'positive' : 'negative'}`}>
                {hintChange > 0 ? '+' : ''}
                {hintChange}
              </div>
              <span> vs last month </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsStatsCard;
