import { FunctionComponent } from 'react';

import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { Scenario } from '../../types';
import ScenarioIntroductionRenderer from '../ScenarioIntroductionRenderer';

type ScenarioIntroductionModal = {
  scenario: Scenario;
  step?: number;
  isOpen: boolean;
  onRequestClose: () => void;
};
// eslint-disable-next-line no-redeclare
const ScenarioIntroductionModal: FunctionComponent<ScenarioIntroductionModal> = ({
  isOpen,
  onRequestClose,
  scenario,
  step = 1,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current) => ({
        ...current,
        root: `${current.root} ScenarioIntroductionModal`,
      })}
    >
      <ModalHeader />
      <ModalBody>
        <div className="ScenarioIntroductionModal__body">
          <ScenarioIntroductionRenderer scenario={scenario} step={step} hideControls />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ScenarioIntroductionModal;
