import { AxiosResponse, ResponseType } from 'axios';

import { ApiUrls } from '../../../config/urls';
import { DependencyContainer } from '../../../DependencyContainer';
import { HttpClient } from '../../../HttpClient';
import { Award, AwardList } from '../types';

const configOverride = {
  baseURL: window.envs.REACT_APP_API_URL,
};

export default class AwardClient extends HttpClient {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  async fetchAwards(token: string): Promise<AxiosResponse<AwardList>> {
    this.setToken(token);
    const response = await this.get<AwardList>(ApiUrls.getUrlForAwards(), configOverride);

    response.data.results = response.data.results.map((award) => {
      return {
        ...award,
        expiry_date: new Date(award.expiry_date),
        created_at: new Date(award.created_at),
      };
    });

    return response;
  }

  async fetchCertificate(
    token: string,
    certificate: Award,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<any>> {
    this.setToken(token);
    const responseType: ResponseType = 'blob';
    const config = {
      baseURL: window.envs.REACT_APP_API_URL,
      params: certificate,
      responseType: responseType,
    };

    return await this.get<AwardList>(ApiUrls.getUrlForGenerateCertificate(), config);
  }
}
