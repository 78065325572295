import './analytics.scss';

import { FC } from 'react';

import Button from '../../components/Button/Button';
import { AppRoutes } from '../../config/routes';
import { usePersistentState } from '../../persistent-state/persistent-state';
import { useBearerToken } from '../common/hooks/useBearerToken';
import useFetchPlayerData from '../common/hooks/useFetchPlayerData';
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import { useUser } from '../common/hooks/useUser';
import AnalyticsHeader from './AnalyticsHeader';
import AnalyticsStatsCard from './AnalyticsStatsCards';
import { CompetencyGrade } from './components/CompetencyGrade';
import { CoordinatesResponsesToIncidents } from './components/CoordinatesResponsesToIncidents';
import DemoPracticeButtons from './components/DemoPracticeButtons';
import { DrillsOverTime } from './components/DrillsOverTime';
import { HandlesDayToDayIssues } from './components/HandlesDaysToDayIssues';
import { LicenseToOperateTargetGrades } from './components/LicenseToOperateTargetGrades';
import { ManageComplexIncidents } from './components/ManageComplexIncidents';
import { ManageCriticalIncidents } from './components/ManageCriticalIncidents';
import { MinimumCompetency } from './components/MinimumCompetency';
import { PlayerLevel } from './components/PlayerLevel';
import PlayerBarChart from './PlayerBarChart';
import PlayerFeedbackContainer from './PlayerFeedbackContainer';
import PlayerRadarChart from './PlayerRadarChart';

const Analytics: FC = () => {
  const goToRoute = useRedirectToRoute();
  const [reportsSelectedUserEmail] = usePersistentState('reportsSelectedUserEmail');
  const token = useBearerToken();
  const user = useUser();
  const playerEmail: string =
    user?.isAdmin && reportsSelectedUserEmail ? reportsSelectedUserEmail : user?.email;
  const {
    player,
    radarChartData,
    starsAndHints,
    hintCount,
    starCount,
    previousMonthStats,
    currentMonthStats,
  } = useFetchPlayerData(playerEmail);

  return (
    <>
      <AnalyticsHeader key={playerEmail} />
      {/* Sidebar stats */}
      <div className="progress-overview-wrapper">
        <div className="progress-header">
          <div className="progress-title">Progress Overview</div>
        </div>
        <div className="stats-and-radarchart-container">
          <AnalyticsStatsCard
            currentMonthStats={currentMonthStats}
            previousMonthStats={previousMonthStats}
            player={player}
            starCount={starCount}
            hintCount={hintCount}
          />
          {/* Radar Chart */}
          <div className="skills-radarchart-wrapper">
            <div className="charts__title">Competencies Progress</div>
            <span className="charts__subtitle"> At the moment </span>
            {radarChartData && (
              <div className="charts-key">
                <div className="key-color-2"></div>{' '}
                <span className="charts-key__item"> License To Operate Target Grade</span>
                <div className="key-color-1 charts-key__item"></div>{' '}
                <span className="charts-key__item"> Player Level</span>
              </div>
            )}
            {!radarChartData && (
              <div className="charts__no-data">
                No data yet. Complete 4 drills to unlock accurate insights!
              </div>
            )}
            <div className="skills-radarchart__container">
              <div className="skills-radarchart__visual">
                <PlayerRadarChart radarChartData={radarChartData || null} />
              </div>
              <div className="skills-radarchart__legend">
                <LicenseToOperateTargetGrades />
                <MinimumCompetency />
                <HandlesDayToDayIssues />
                <CoordinatesResponsesToIncidents />
                <ManageCriticalIncidents />
                <ManageComplexIncidents />
                <PlayerLevel />
                <CompetencyGrade />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="performance-barchart-wrapper">
        <div className="charts__title">Drill Performance</div>
        <span className="charts__subtitle">Lifetime, Stars and Hints by category</span>

        <div className="charts-key">
          Performance:
          <div className="key-color-2 charts-key__item"></div>{' '}
          <span className="charts-key__item"> Stars</span>
          <div className="key-color-1 charts-key__item"></div>{' '}
          <span className="charts-key__item"> Hints</span>
        </div>
        {starsAndHints && <PlayerBarChart starsAndHints={starsAndHints} />}
        {!starsAndHints && (
          <div className="reports-cta">
            {/* <h2 className="reports-cta__placeholder-title">{title}</h2> */}
            <p className="reports-cta__placeholder-subtitle">
              Not enough data yet. Play a drill to get started
            </p>
            <Button
              size="small"
              variant="secondary"
              className="reports-action-button"
              onClick={() => goToRoute(AppRoutes.ScenarioLevels, { id: 'online-boutique' })}
            >
              Play a drill
            </Button>
          </div>
        )}
      </div>
      {/* Latest Feedback section */}
      <div className="player-feedback-wrapper">
        <div className="charts__title">Latest Feedback</div>
        {starsAndHints && (
          <PlayerFeedbackContainer allSessions={starsAndHints || null} token={token!} />
        )}

        {!starsAndHints && (
          <div className="reports-cta">
            <p className="reports-cta__placeholder-subtitle">
              No feedback yet. Play a drill to get started
            </p>
            <Button
              size="small"
              variant="secondary"
              className="reports-action-button"
              onClick={() => goToRoute(AppRoutes.ScenarioLevels, { id: 'online-boutique' })}
            >
              Play a drill
            </Button>
          </div>
        )}
      </div>
      {user.isDemo ? (
        <DemoPracticeButtons />
      ) : (
        player && <DrillsOverTime player={player} starsAndHints={starsAndHints} />
      )}
    </>
  );
};

export default Analytics;
