import { Helmet } from 'react-helmet';
import { useRecoilState } from 'recoil';

import Button, { ButtonStyles } from '../../../components/Button/Button';
import { StarIcon } from '../../../components/Icons';
import PendingContent from '../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import { TableStyles } from '../../../components/Table/Table';
import ResponsiveTable, { HeadingRow } from '../../../components/Table/TableResponsive';
import { AppRoutes } from '../../../config/routes';
import { DependencyContainer } from '../../../DependencyContainer';
import { scenariosAtom } from '../../../state';
import { repeatElement } from '../../common/helpers/jsx.helper';
import { getScenarioCompleteness } from '../../common/helpers/scenarios.helper';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useMedia } from '../../common/hooks/useMedia';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { Scenario, ScenarioStatuses } from '../types';

const { scenariosService } = DependencyContainer.getInstance();

const addStylesToTable = (current: TableStyles) => ({
  ...current,
  root: `${current.root} ScenariosContainer__table`,
});

const addStylesForButton = (additionalClasses?: string) => (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} ${additionalClasses}`,
});

type ScenariosTableItem<T> = T & {
  scenarioName: string;
  difficulty: number;
  completion: string;
};

const ScenariosContainer = () => {
  const [scenarios, setScenarios] = useRecoilState(scenariosAtom);
  const [loading, , error] = useFetchData<Scenario[], Error>(
    () => scenariosService.getScenarios(),
    (scenarios: Scenario[]) => setScenarios(scenarios),
  );
  const redirectToRoute = useRedirectToRoute();
  const media = useMedia();
  const { sizes } = media;

  const launchScenario = (scenario: Scenario) => {
    redirectToRoute(AppRoutes.Scenario, {
      id: scenario.id,
    });
  };

  const getHeadingRows = (): Array<HeadingRow<Scenario>> => [
    {
      name: 'Scenario',
      fieldName: 'scenarioName',
      render: (scenario: Scenario) => scenario.title,
    },
    {
      name: 'Difficulty',
      fieldName: 'difficulty',
      render: (scenario: Scenario) => repeatElement(<StarIcon />, scenario.difficulty),
    },
    {
      name: '% complete',
      fieldName: 'completion',
      render: (scenario: Scenario) => {
        const completeness = getScenarioCompleteness(scenario);
        return completeness > 0 ? `${completeness}%` : '';
      },
    },
    {
      name: '',
      // eslint-disable-next-line sonarjs/function-return-type
      render: (scenario: Scenario) => {
        if (scenario.status === ScenarioStatuses.Locked) {
          return '';
        }
        const additionalClassesForButton = sizes.small
          ? 'ScenariosContainer__button-full-width'
          : 'pull-right';
        return (
          <Button
            size="small"
            variant="secondary"
            classes={addStylesForButton(additionalClassesForButton)}
            onClick={() => launchScenario(scenario)}
          >
            Launch scenario
          </Button>
        );
      },
    },
  ];

  const getDataRows = (scenarios: Scenario[]): ScenariosTableItem<Scenario>[] => {
    return scenarios.map((scenario: Scenario) => ({
      ...scenario,
      scenarioName: scenario.title,
      difficulty: scenario.difficulty,
      completion: '',
    }));
  };

  return (
    <div className="ScenariosContainer" data-testid="ScenariosContainer">
      <Helmet>
        <title> Scenarios - UptimeLabs </title>
      </Helmet>
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
        data-testid="PendingContent"
      >
        {scenarios && (
          <ResponsiveTable
            headingRows={getHeadingRows()}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataRows={getDataRows(scenarios) as any}
            classes={addStylesToTable}
            data-testid="Table"
          />
        )}
      </PendingContent>
    </div>
  );
};

export default ScenariosContainer;
