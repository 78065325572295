import { any } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type TableHeadColumnStyles = {
  root: string;
};

type TableHeadColumnProps = JSX.IntrinsicElements['th'];

const TableHeadColumn: AdjustableComponent<TableHeadColumnProps, TableHeadColumnStyles> = ({
  classes,
  children,
}) => {
  const styles = useClasses(
    {
      root: 'Table__head-column',
    },
    classes,
  );
  return (
    <th className={styles.root} data-testid="TableHeadColumn">
      {children}
    </th>
  );
};

TableHeadColumn.propTypes = {
  classes: any,
  children: any,
};

export default TableHeadColumn;
