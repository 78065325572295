type OmitFunction = {
  <T extends object, K extends [...(keyof T)[]]>(
    object: T,
    ...keys: K
  ): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2];
  };
};

export const omit: OmitFunction = (object, ...keys) => {
  const result = {} as {
    [K in keyof typeof object]: (typeof object)[K];
  };
  let key: keyof typeof object;
  for (key in object) {
    if (!keys.includes(key)) {
      result[key] = object[key];
    }
  }
  return result;
};
