import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PendingContent from '../../../../components/PendingContent/PendingContent';
import SpinnerLoader from '../../../../components/SpinnerLoader/SpinerLoader';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useBearerToken } from '../../../common/hooks/useBearerToken';
import { useFetchData } from '../../../common/hooks/useFetchData';
import { useUser } from '../../../common/hooks/useUser';
import { WordpressContent, WordpressLevel } from '../../../content/types';
import { Scenario, ScenarioStatuses } from '../../types';
import LockedScenarioMessage from '../LockedScenarioMessage';
import LobbyCard from './lobbyCard';
import { Lobby } from './types';

const { contentService, scenariosService, lobbyService } = DependencyContainer.getInstance();

const JoinLobbyContainer: FunctionComponent = () => {
  const [lobby, setLobby] = useState<Lobby | null>(null);
  const { lobbyOwner, lobbyScenario, lobbyLevel } = useParams();
  const user = useUser();
  const token = useBearerToken();
  const [levelLoading, level, levelError] = useFetchData<WordpressContent<WordpressLevel>, Error>(
    () => {
      return contentService.getLevelById(Number(lobbyLevel));
    },
    undefined,
    [lobbyScenario, lobbyLevel],
    () => !lobbyLevel,
  );

  const [loading, scenario, error] = useFetchData<Scenario, Error>(() => {
    if (!lobbyScenario) {
      return Promise.reject(new Error('Lobby scenario is not defined'));
    }
    return scenariosService.getScenario(lobbyScenario);
  });

  useEffect(() => {
    const findLobby = async () => {
      const lobby = await lobbyService.getLobbyByEmail(token!, lobbyOwner!);
      setLobby(lobby);
    };
    findLobby();
  }, [lobbyOwner, token]);

  if (scenario?.status === ScenarioStatuses.Locked) {
    return <LockedScenarioMessage />;
  }
  const renderTitle = (): string => {
    return `${scenario?.title} -  ${level?.title?.rendered}`;
  };

  return (
    <div className="ScenariosContainer">
      <Helmet>
        <title> UptimeLabs </title>
      </Helmet>
      {!loading && scenario && (
        <Helmet>
          <title>{renderTitle()} - UptimeLabs</title>
        </Helmet>
      )}
      <PendingContent
        data-testid="PendingContent"
        loading={loading || levelLoading}
        isError={Boolean(error) || Boolean(levelError)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
      >
        <div className="ScenariosContainer__scenario">
          <div className="ScenariosContainer__content">
            <h1 className="ScenariosContainer__title">{renderTitle()}</h1>
            {level?.content.rendered && (
              <div className="ScenariosContainer__levelIntroduction">
                <div
                  className="ScenariosContainer__description"
                  dangerouslySetInnerHTML={{
                    __html: level.content.rendered,
                  }}
                />
                {scenario && (
                  <LobbyCard
                    scenario={scenario}
                    level={level}
                    user={user}
                    readyToPlay={false}
                    isJoiningExistingLobby={true}
                    joiningLobby={lobby}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </PendingContent>
    </div>
  );
};

export default JoinLobbyContainer;
