import { useEffect, useState } from 'react';

import { audioVolume } from '../../../config/config';

type UseAudioValues = [boolean, CallableFunction, CallableFunction, CallableFunction, boolean];

const useAudio = (url: string): UseAudioValues => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState<number>(audioVolume);

  const toggle = () => setPlaying(!playing);

  const toggleVolume = () => {
    setVolume((currentVolume) => (currentVolume ? 0 : audioVolume));
  };

  const flush = () => {
    if (playing) {
      audio.pause();
    }
  };

  useEffect(() => {
    audio.volume = volume;
  }, [audio, volume]);

  useEffect(() => {
    if (playing) {
      audio.play().catch(() => setPlaying(false));
    } else {
      audio.pause();
    }
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
      audio.pause();
    };
  }, [audio]);

  return [playing, toggle, flush, toggleVolume, volume === 0];
};

export default useAudio;
