import './LearningCenter.scss';

import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DependencyContainer } from '../../DependencyContainer';
import { useFetchData } from '../common/hooks/useFetchData';
import { Post, WordpressContent } from '../content/types';

const { postsService } = DependencyContainer.getInstance();

const PostContainer: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  console.log('id', id);

  const [loading, postContent, error, fetchPostData] = useFetchData<
    WordpressContent<Post>
  >(
    async () => {
      try {
        const post = await postsService.getPost(id!);
        return post as WordpressContent<Post>;
      } catch (error) {
        console.error('Error fetching post:', error);
        throw error;
      }
    },
    undefined,
    [id, postsService],
  );

  const backgroundImageStyle =
    postContent && postContent.featured_media
      ? { backgroundImage: `url(${postContent.featured_media})` }
      : {
          backgroundImage: `url('https://images.unsplash.com/photo-1668713960196-16c5b0689eda?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
        };

  useEffect(() => {
    if (postContent) {
      console.log('postContent', postContent);
    }
  }, [postContent]);

  console.log('loading', loading);
  console.log('error', error);
  console.log('postContent', postContent);
  console.log('fetchPostData', fetchPostData);

  const postContentRendered = postContent?.content.rendered;
  const postTitle = postContent?.title.rendered;

  return (
    <div className="post-container">
      {(() => {
        if (loading) {
          return <div>Loading...</div>;
        } else if (error) {
          return <div>Error fetching post. Please try again later.</div>;
        } else if (postContent) {
          return (
            <div>
              <h1>{postTitle}</h1>
              <div
                className="post-container--hero"
                style={backgroundImageStyle}
              ></div>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{
                  __html: postContentRendered ?? '',
                }}
              ></div>
            </div>
          );
        } else {
          return <div>No post content available.</div>;
        }
      })()}
    </div>
  );
};

export default PostContainer;
