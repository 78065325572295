import { any, bool } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import { useToggleByClickOutside } from '../../features/common/hooks/useToggleByClickOutside';
import DropdownContext from './DropdownContext';

type DropdownProps = {
  isOpen: boolean;
  toggle: () => void;
} & JSX.IntrinsicElements['div'];

export type DropdownStyles = {
  root: string;
};

const Dropdown: AdjustableComponent<DropdownProps, DropdownStyles> = ({
  isOpen,
  toggle,
  classes,
  children,
  ...props
}) => {
  const toggleByClickOutside = useToggleByClickOutside({
    toggler: toggle,
    state: isOpen,
  });

  const styles = useClasses(
    {
      root: 'Dropdown',
    },
    classes,
  );

  return (
    <DropdownContext.Provider value={{ toggle, isOpen }}>
      <div ref={toggleByClickOutside.ref} className={styles.root} {...props}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

Dropdown.propTypes = {
  children: any,
  classes: any,
  isOpen: bool.isRequired,
  toggle: any.isRequired,
};

export default Dropdown;
