import cx from 'classnames';
import { any, oneOf } from 'prop-types';
import { JSX } from 'react';

import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';

type AlertProps = {
  type?: 'warning';
} & JSX.IntrinsicElements['div'];

type AlertStyles = {
  root: string;
};

const Alert: AdjustableComponent<AlertProps, AlertStyles> = ({
  children,
  classes,
  type = 'warning',
  ...rest
}) => {
  const styles = useClasses(
    {
      root: cx('Alert', {
        [`Alert--${type}`]: true,
      }),
    },
    classes,
  );
  return (
    <div className={styles.root} {...rest} role="alert">
      {children}
    </div>
  );
};

Alert.propTypes = {
  children: any,
  classes: any,
  type: oneOf(['warning']),
};
export default Alert;
