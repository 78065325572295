import cx from 'classnames';
import { any, bool, func } from 'prop-types';
import { FunctionComponent, JSX, ReactNode } from 'react';

import { omit } from '../../features/common/helpers/objects.helper';
import { AdjustableComponent, useClasses } from '../../features/common/hooks/useClasses';
import TextLoader from '../TextLoader/TextLoader';

export type PendingContentStyles = {
  root: string;
  loader: string;
  error: string;
  ReadyToPlay: string;
  classes?: PendingContentStyles;
};

export type PendingContentProps = {
  children?: ReactNode;
  hideContent?: boolean;
  isError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader?: FunctionComponent<any>;
  loading: boolean;
  message?: ReactNode | string;
  ReadyToPlay?: boolean;
  transparent?: boolean;
};

type LoadableErrorProps = JSX.IntrinsicElements['div'] & {
  message?: ReactNode | string;
};

const PendingContent: AdjustableComponent<PendingContentProps, PendingContentStyles> = (props) => {
  const propsToPass = omit(
    props,
    'children',
    'classes',
    'loading',
    'isError',
    'hideContent',
    'loader',
    'transparent',
    'ReadyToPlay',
  );
  const styles = useClasses({
    root: cx('PendingContent', {
      'PendingContent--loading': props.loading,
      'PendingContent--hide-content': props.hideContent,
      'PendingContent--curtain': !props.transparent,
      PendingContent__ReadyToPlay: props.ReadyToPlay,
    }),
    loader: 'PendingContent__loader',
    error: 'PendingContent__error',
    ReadyToPlay: 'PendingContent__ReadyToPlay',
  });

  const message = props.message || 'Something went wrong';
  const loaderProps = {
    visible: props.loading,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    classes: (current: any) => ({
      ...current,
      root: `${current.root} ${styles.loader}`,
    }),
  };
  const Loader = props.loader || TextLoader;

  return (
    <div data-testid="PendingContent" {...propsToPass} className={styles.root}>
      <Loader {...loaderProps} />
      {props.isError ? (
        <PendingContentError
          data-testid="PendingContent__error"
          className={styles.error}
          message={message}
        />
      ) : (
        props.children
      )}
    </div>
  );
};

const PendingContentError: FunctionComponent<LoadableErrorProps> = ({ message, ...props }) => {
  return <div {...props}>{message}</div>;
};
PendingContent.propTypes = {
  children: any,
  hideContent: bool,
  isError: bool,
  loader: func,
  loading: bool.isRequired,
  message: any,
  ReadyToPlay: bool,
  transparent: bool,
};

export default PendingContent;
