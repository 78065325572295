import Keycloak, { KeycloakConfig } from 'keycloak-js';

const config: KeycloakConfig = {
  url: window.envs.REACT_APP_KEYCLOAK_URL,
  realm: String(window.envs.REACT_APP_KEYCLOAK_REALM),
  clientId: String(window.envs.REACT_APP_KEYCLOAK_CLIENT_ID),
};

const authClient: Keycloak = new Keycloak(config);

export default authClient;
